import { Injectable } from '@angular/core';
import {DsToastModel, DsToastService} from '@bmw-ds/components';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastService: DsToastService) { }

  success(message: string){
    this.toastService.pushToast({
      id: 'success-toast',
      tone: 'positive',
      toastText: message
    } as DsToastModel);
  }

  error(message: string){
    this.toastService.pushToast({
      id: 'error-toast',
      tone: 'critical',
      toastText: message
    } as DsToastModel);
  }
}
