import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AraHttpService {

  constructor(protected http: HttpClient) {
  }

  public doGet(path: string): Observable<any> {
    return this.http.get(path, {headers: this.buildHeader(), params: this.buildParams(new HttpParams())});
  }

  public doGetParams(path: string, params: HttpParams): Observable<any> {
    return this.http.get(path, {headers: this.buildHeader(), params: this.buildParams(params)});
  }

  public doPost(path: string, data: any): Observable<any> {
    return this.http.post(path, data, {headers: this.buildHeader(), params: this.buildParams(new HttpParams())});
  }

  public doPostParams(path: string, data: any, params: HttpParams): Observable<any> {
    return this.http.post(path, data, {headers: this.buildHeader(), params: params});
  }

  public doPut(path: string, data: any): Observable<any> {
    return this.http.put(path, data, {headers: this.buildHeader()});
  }

  public doPutParams(path: string, data, params: HttpParams): Observable<any> {
    return this.http.put(path, data, {headers: this.buildHeader(), params: this.buildParams(params)});
  }

  public doDelete(path: string, params = new HttpParams()): Observable<any> {
    return this.http.delete(path, {headers: this.buildHeader(), params: this.buildParams(params)});
  }

  public doDeleteWithBody(path: string, data: any): Observable<any> {
    return this.http.request('delete', path, {headers: this.buildHeader(), body: data});
  }

  // public login(username, password) {
  //
  //   let params = new HttpParams();
  //   params = params.set('username', username);
  //   params = params.set('password', password);
  //   params = params.set('grant_type', 'password');
  //
  //   const headers = new HttpHeaders({
  //     'Authorization': 'Basic ' + btoa('info_app_client_id:1nf0_app_$4cR4T')
  //   });
  //
  //   const options = {
  //     headers: headers,
  //     params: params
  //   };
  //
  //   return new Observable(observer => {
  //     this.http.post(this.getBaseUrl() + 'oauth/token', null, options)
  //       .subscribe((resp: any) => {
  //         observer.next(resp);
  //       }, error => {
  //         observer.error(error);
  //       });
  //   });
  // }

  public buildHeader(): HttpHeaders {
    return new HttpHeaders();
  }

  private buildParams(httpParams: HttpParams): HttpParams {
    // const selectedDealerNumber = sessionStorage.getItem('selectedDealerNumber');
    // if (selectedDealerNumber) {
    //   httpParams = httpParams.set('dealerNo', selectedDealerNumber.toString());
    // }
    // const selectedBrand = sessionStorage.getItem('selectedBrand');
    // if (selectedBrand) {
    //   httpParams = httpParams.set('brand', selectedBrand.toUpperCase());
    // }
    return httpParams;
  }
}
