import {Component, OnInit} from '@angular/core';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Application from 'src/app/shared/model/applications/application';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import ApplicationMinifiedProps from '../../../shared/model/applications/application-props-min';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';

interface CustomGridColum {
  field: string;
  headerName: any;
  filter?: boolean;
  enableRowGroup?: boolean;
  sortable: boolean;
  width: number;
  cellRenderer?: any;
  cellClass?: any;
}

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
  auth = true;
  showApplication = false;
  showDisabled = true;
  applications: ApplicationMinifiedProps[];
  application  = new Application();
  showUpdateApplication = new Map<number, boolean>();
  display = 'none';
  gridGroupData = [];
  selectedApplication: Application;
  columns = this.getColumns();
  updateModalIsOpen = false;
  modalIsOpen = false;
  editMode = false;
  rowSelection: 'single' | 'multiple' = 'single';
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
  gridApi!: GridApi<Application>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedApplication = event.data;
      this.editMode = true;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedApplication = event.data;
      this.editMode = true;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedApplication = null;
      this.editMode = false;
    }
  };
  constructor(private applicationHttpService: ApplicationHttpService,
              private sweetAlertService: SweetAlertService, private i18nProvider: I18nProvider) {
  }

  ngOnInit() {
    this.initApplicationsList();
  }

  private initApplicationsList() {
    this.applicationHttpService.getMasterMinifiedPropertiesApplications().subscribe(successResponse => {
      this.applications = successResponse.sort((a, b) => a.name.localeCompare(b.name));
      this.setGroupingData(successResponse);
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving applications: ' + JSON.stringify(error));
    });
  }

  setGroupingData(data){
    this.gridApi.setRowData(data);
    this.gridGroupData = data;
  }
  addApplication(application: Application) {
   // application.enabledForSingleRights = true;
    this.applicationHttpService.createApplication(application).subscribe(() => {
      this.application = new Application();
      this.modalIsOpen = false;
      this.ngOnInit();
      this.getColumns();
      this.showApplication = false;
    }, (error) => {
      this.sweetAlertService.showComError('Error creating applications: ' + JSON.stringify(error));
    });
  }
  updateApplication(application: Application) {
    this.applicationHttpService.updateApplication(application).subscribe(() => {
      this.updateModalIsOpen = false;
      this.showUpdateApplication.set(application.id, false);
      this.getColumns();
      this.gridApi.redrawRows();
    }, (error) => {
      this.sweetAlertService.showComError('Error updating applications: ' + JSON.stringify(error));
    });
  }
  clickDeleteApplication() {
    this.applicationHttpService.deleteApplication(this.selectedApplication.id).subscribe(() => {
      this.display = 'none';
      this.initApplicationsList();
    }, error => {
      this.sweetAlertService.showComError('Error deleting application: ' + JSON.stringify(error));
      // this.display = 'none';
      // this.initApplicationsList();
    });
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  getColumns(): CustomGridColum[] {
    const columns: CustomGridColum[] = [
      {
        field: 'name',
        headerName: this.i18nProvider.getDescription('application_name'),
        filter: true,
        sortable: true,
        enableRowGroup: true,
        width: 300
      },
      {field: 'active', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true, width: 200,
        cellClass: this.setActiveOrInactive,
        cellRenderer: this.setStatusLables,
      },
      {
        field: 'enabledForSingleRights',
        headerName: 'SR Status',
        filter: true,
        sortable: true,
        width: 300,
        cellRenderer: this.setEnabledForSingleRights,
      },
      {
        field: 'extendable',
        headerName: 'Extendable',
        filter: true,
        sortable: true,
        enableRowGroup: true,
        width: 200,
        cellRenderer: this.setCheckMark
      },
      {
        field: 'range',
        headerName: 'Range',
        filter: true,
        sortable: true,
        enableRowGroup: true,
        width: 200
      },


      // {
      //   field: 'region',
      //   headerName: this.i18nProvider.getDescription('regions'),
      //   filter: true,
      //   sortable: true,
      //   width: 200
      // },
      ];
    return columns;
  }

  setStatusLables(params){
    return params.value ? 'active' : 'inactive';
  }
  setActiveOrInactive(params){
    return params.value ? 'active' : 'inactive';
  }
  setCheckMark(params) {
    return params.value ? '&#10003;' : '&#88;';
  }

  setEnabledForSingleRights(params) {
    if (params.value) {
      return params.value ? '&#10003;' : '&#88;';
    }
  }
  onGridReady(params: GridReadyEvent<Application>) {
    this.gridApi = params.api;
  }
}
