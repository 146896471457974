<div class="row">
  <div class="col-12 mt-5">
    <h1 class="small-heading">
      <span class="archive-title">{{'' | i18n: 'my_team'}}</span>
    </h1>
  </div>
</div>
<div class = "row main-tab-b" *ngIf="isDeputy">
  <div class = "col-2 tab-b mt-3 p-0 mb-2">
    <button class = "green_b-tab" ds-button (click)="newTeamMemberClicked()">{{'' | i18n: 'add_teammember'}}</button>
  </div>
</div>
<div class="ag-theme-alpine row">
  <div class="col-12 mt-2 mb-0 d-flex justify-content-end">
    <span class="push-right">
      Page Size:
      <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize" id="page-size" class="pagination-size">
        <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
      </select>
    </span>
  </div>
</div>

<div class="row " style="width: 100%;margin-left: 1px;">
  <div class = "col-12 mt-3 mb-2">
    <ag-grid-angular
      style="width: 100%; height: 550px"
      class="ag-theme-alpine"
      rowHeight="56"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="paginationSize"
      (firstDataRendered)="onFirstDataRendered($event)"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      [rowData]="users"
      (gridReady)="onGridReady($event)"
      [rowSelection]="rowSelection">
    </ag-grid-angular>
  </div>
</div>

<!--new team member modal -->
<ds-modal #new_team_member [ds-modal-trigger-for]="new_team_member" [(isModalOpen)]="newTeamMemberModalOpen" size="sm"  [dimensionsConfig]="{ width: '600px' }">
  <ds-box-header>
    <span class = "modal-header-title">
      {{'' | i18n: 'add_teammember'}}
    </span>
  </ds-box-header>
  <ds-box-content style="padding: 50px">
    <div class = "row">
      <div class = "col-6">
        <ds-form-field>
          <label ds-label class = "pull-left">User ID: </label>
          <input ds-input id="userID" type="text" required="false" [(ngModel)]="newTeamMemberQNumber"/>
        </ds-form-field>
      </div>
    </div>

  </ds-box-content>
  <ds-box-footer class = "mb-3 b-position">
    <button class = "cancel_b_standard" (click)="closeNewTeamMemberModal()">{{'' | i18n: 'cancel'}}</button>
    <button class = "green_b_standard" [disabled]="!newTeamMemberQNumber" (click)="newTeamMemberSaveClicked()">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>
