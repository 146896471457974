import {Component, OnInit} from '@angular/core';
import Right from 'src/app/shared/model/rights/right';
import Application from 'src/app/shared/model/applications/application';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import {RightHttpService} from 'src/app/service/http/right-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {SelectListOption} from '@bmw-ds/components';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BusinessRuleHttpService} from '../../../service/http/business-rule-http.service';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent
} from 'ag-grid-community';
import UserDisplay from '../../../shared/model/user/user-display';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {DialogService} from '../../../service/modal/dialog.service';
import {ApplicationRightsConfigComponent} from '../../../components/popup/application-rghts-config/application-rights-config.component';
import {ToastService} from '../../../service/feedback/toast.service';
import BusinessRule from "../../../shared/model/business-rules/business-rule";

@Component({
  selector: 'app-application-rights',
  templateUrl: './application-rights.component.html',
  styleUrls: ['./application-rights.component.scss']
})
export class ApplicationRightsComponent implements OnInit {
  auth = true;
  showApplicationRight = false;
  showDisabled = false;
  newRight = new Right();
  selectedApplication: Application = new Application();
  applications: Application[];
  display = 'none';
  private rightToDelete: Right;
  applicationSelectionList: SelectListOption[];
  rightSelectionList: SelectListOption[];
  applicationId = new FormControl();
  rightId = new FormControl();
  editType: 'fullRow' = 'fullRow';
  tableData: Right[] = [];
  columns = this.getColumns();
  gridApi!: GridApi<UserDisplay>;
  rowSelection: 'single' | 'multiple' = 'single';

  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowValueChanged: (event: RowValueChangedEvent) => this.updateRuleName(event),
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateRuleStatus(event)
  }

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100},
    { id: 150},
    { id: 200},
    { id: 300},
    { id: 400},
    { id: 500},
  ];

  constructor(public applicationHttpService: ApplicationHttpService, public rightHttpService: RightHttpService,
              public businessRuleHttpService: BusinessRuleHttpService, private toastService: ToastService,
              public sweetAlertService: SweetAlertService, private i18nProvider: I18nProvider, private dialog: DialogService,) {
  }

  ngOnInit() {
    this.initRights();
    this.listenToApplicationChange();
    this.listenToRightChange();
  }

  private initRights() {
    this.applicationHttpService.getMasterApplicationsWithRights().subscribe(successResponse => {
      this.applications = successResponse.sort((a, b) => a.name.localeCompare(b.name));
      this.applications.forEach(application => {
        application.applicationRightsCategories.sort((a, b) => a.name.localeCompare(b.name));
        application.applicationRightsCategories.forEach(appRightCat => {
          appRightCat.rights.sort((a, b) => a.name.localeCompare(b.name));
        });
      });
      this.applicationSelectionList = this.applications.map(application => {
        return {id: application.id.toString(), label: application.name};
      });
      this.paginationSize = this.setPaginationSize();
      this.setTableData();
    }, (error => {
      this.sweetAlertService.showComError('Error retrieving Rights: ' + JSON.stringify(error));
    }));
  }

  getColumns(){
    return [
      {field: 'applicationName', headerName: this.i18nProvider.getDescription('application'), filter: true, sortable: true,
        width: 70, enableRowGroup: true},
      {field: 'categoryName', headerName: this.i18nProvider.getDescription('category_name'), filter: true, sortable: true,
        width: 70, enableRowGroup: true},
      {field: 'name', headerName: this.i18nProvider.getDescription('application_rights'), filter: true, sortable: true,
        editable: true, width: 70},
      {field: 'active', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true,
        width: 50, cellRenderer: (params) => {return this.setStatusCheckbox(params.data);}},
      {field: 'rightsInfo', headerName: 'Rights Info', filter: true, sortable: true,
        width: 70, editable: true}
    ];
  }

  setStatusCheckbox(data) {
    if (data && data.active) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }

  setCheckBox(data) {
    return '<input type="checkbox" checked>'
  }

  setStatusCheckbox_(params) {
    if (params && params.data && params.data.regions && params.data.regions.includes(Number(params.colDef.field)) && params.data.active) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }

  onGridReady(params: GridReadyEvent<UserDisplay>) {
    this.gridApi = params.api;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_manage_application_rights_pagination', size);
    this.paginationSize = size;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_manage_application_rights_pagination')) {
      return localStorage.getItem('ara_manage_application_rights_pagination');
    }
    return 100;
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setTableData() {
    this.tableData = [];
    if (this.applications && this.applications.length > 0) {
      this.applications.forEach(application => {
        if (application.applicationRightsCategories.length > 0) {
          application.applicationRightsCategories.forEach(rightCat => {
            if(rightCat.rights.length > 0) {
              rightCat.rights.forEach(right => {
                right.applicationName = application.name;
                right.categoryId = rightCat.id;
                right.categoryName = rightCat.name;
                this.tableData.push(right);
              });
            }
          })
        }
      });
    }
  }

  updateRuleName(rowObject: any) {
    this.rightHttpService.updateRight(rowObject.data).subscribe(() => {
      let refreshParams = {force: true, rowNodes: [rowObject.node]};
      rowObject.api.refreshCells(refreshParams);
      this.toastService.success('Application right name updated: ' + rowObject.data.name);
    }, (error) => {
      this.sweetAlertService.showComError('Error updating right: ' + JSON.stringify(error));
    });

  }

  updateRuleStatus(rowObject: any) {
    if (rowObject.column.colId == 'active') {
      if (rowObject.column.colId == 'active') {
        rowObject.data.active = !rowObject.data.active;
        this.rightHttpService.updateRight(rowObject.data).subscribe(() => {
          let refreshParams = {force: true, rowNodes: [rowObject.node]};
          rowObject.api.refreshCells(refreshParams);
          this.toastService.success('Application right status updated: ' + rowObject.data.name);
        }, (error) => {
          this.sweetAlertService.showComError('Error updating right: ' + JSON.stringify(error));
        });
      }
    }

    if (rowObject.column.colId == 'categoryName') {
      if (rowObject.data.active) {
        this.viewApplication(rowObject);
      } else if (!rowObject.data.active) {
        this.toastService.error('Application right not active: ' + rowObject.data.name);
      }
    }
  }

  addRights() {
    this.rightHttpService.createRight(this.newRight).subscribe(() => {
      this.newRight = new Right();
      this.selectedApplication = null;
      this.showApplicationRight = false;
      this.applicationId.reset();
      this.rightId.reset();
      this.ngOnInit();
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving Rights: ' + JSON.stringify(error));
    });
  }

  clickTrashCan(right: Right) {
    this.rightToDelete = right;
    this.display = 'block';

  }

  updateRight(right: Right) {
    this.display = 'display';
    right.active = !right.active;
    this.rightHttpService.updateRight(right).subscribe(() => {
      this.display = 'none';
      if (!right.active) {
        this.updateBusinessRules(right);
      }
    }, (error) => {
      this.sweetAlertService.showComError('Error updating right: ' + JSON.stringify(error));
    });
    this.display = 'none';
  }

  deleteRight() {
    this.rightHttpService.deleteRight(this.rightToDelete.id).subscribe(() => {
      this.initRights();
    }, (error) => {
      this.sweetAlertService.showComError('Error deleting right: ' + JSON.stringify(error));
    });
    this.display = 'none';
  }

  listenToApplicationChange(){
    this.applicationId.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe(applicationId => {
        if (applicationId){
          this.rightId.reset();
          this.newRight.applicationRightsCategoryId = null;
          this.newRight.name = null;
          this.selectedApplication = this.applications.find(application => application.id === +applicationId);
          this.rightSelectionList = this.selectedApplication.applicationRightsCategories.map(rightCategory => {
            return {id: rightCategory.id.toString(), label: rightCategory.name};
          });
        }
      });
  }

  listenToRightChange(){
    this.rightId.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe(rightId => {
        if (rightId){
          this.newRight.applicationRightsCategoryId = +rightId;
        }
      });
  }

  private updateBusinessRules(right: Right) {
    this.businessRuleHttpService.removeRightFromBusinessRules(right.id).subscribe(() => {
      this.display = 'none';
    }, (error) => {
      this.sweetAlertService.showComError('Error removing right from business rule: ' + JSON.stringify(error));
    });
  }

  viewApplication(rowObject: any) {
    let passedData = [];
    this.tableData.forEach(row => {
      if (row.applicationId == rowObject.data.applicationId && row.active) {
        if (row.id == rowObject.data.id) {
          row.selected = true;
        } else {
          row.selected = false;
        }
        passedData.push(row);
      }
    });

    this.dialog.open(ApplicationRightsConfigComponent, {data: passedData});
  }
}
