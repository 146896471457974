  <ds-box-header>
    <span class = "col-12 d-flex justify-content-end">
      <ds-icon icon="close" size="md" tone="action" (click)="closeModal()" class="modal-btn"></ds-icon>
    </span>
    <span class = "modal-header-title-md">
      {{'' | i18n: 'application_rights'}}
    </span>
  </ds-box-header>
  <ds-box-content class="panel">
    <div class = "row">
        <ds-tabs>
          <ds-tabs-label for="linkedRights">Linked Rights</ds-tabs-label>
          <ds-tabs-content class = "row" id="linkedRights">
            <ag-grid-angular
              style="width: 100%; height: 800px"
              class="ag-theme-alpine"
              rowHeight="35"
              [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
              [columnDefs]="columns"
              [pagination]="true"
              [suppressDragLeaveHidesColumns]="true"
              [paginationPageSize]=paginationSize
              [rowData]="tableData"
              [rowSelection]="rowSelection"
              (rowValueChanged)="rowEvents.onRowValueChanged($event)"
              (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
              [editType]="editType"
              [rowGroupPanelShow]="rowGroupPanelShow"
              [defaultColDef]="defaultColDef"
              (firstDataRendered)="onFirstDataRendered($event)"
              (gridReady)="onGridReady($event)">
            </ag-grid-angular>
          </ds-tabs-content>
          <ds-tabs-label for="info">Info</ds-tabs-label>
          <ds-tabs-content class = "row" id="info">
            <ds-switch class ="ml-3" [(ngModel)]="selectedRight.active" disabled>Status Right (active / inactive)
            </ds-switch>
            <ds-switch class ="ml-3" [(ngModel)]="selectedRight.hide" (ngModelChange)="updateRightState()">Hide Single Right (hidden / displayed)
            </ds-switch>
          </ds-tabs-content>
        </ds-tabs>
    </div>
  </ds-box-content>
