// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const araApiUrl = 'https://ara-be-i.azurewebsites.net/api/';
export const environment = {
  production: false,
  ara_login_url: araApiUrl + 'auth/loginAuthCode/',
  ara_login_post_url: araApiUrl + 'auth/login/',
  ara_logout_post_url: araApiUrl + 'auth/logout/',
  business_rule_api_url: araApiUrl + 'business-rule/',
  master_business_rule_api_url: araApiUrl + 'business-rule/master/',
  business_category_api_url: araApiUrl + 'business-category/',
  region_admin_api_url: araApiUrl + 'region-admin/',
  region_admin_link_application_api_url: araApiUrl + 'region-admin/region-application',
  business_category_with_rules_api_url: araApiUrl + 'business-category/with-rules-and-parameters/',
  minified_business_category_with_rules_api_url: araApiUrl + 'business-category/with-minified-rules-and-parameters/',
  business_category_minified_with_rules_api_url: araApiUrl + 'business-category/minified/with-rules-and-parameters/',
  business_category_with_rules_and_rights_api_url: araApiUrl + 'business-category/with-rules-and-rights/',
  application_api_url: araApiUrl + 'application/',
  allowed_logins_api_url: araApiUrl + 'departments/',
  application_min_api_url: araApiUrl + 'application/minifiedMasterApplicationsProps/',
  application_parameter_api_url: araApiUrl + 'application/parameter',
  right_api_url: araApiUrl + 'right/',
  location_api_url: araApiUrl + 'location/',
  user_api_url: araApiUrl + 'user/',
  user_grant_role_api_url: araApiUrl + 'user/grant-role/',
  user_remove_role_api_url: araApiUrl + 'user/remove-role/',
  region_api_url: araApiUrl + 'region/',
  region_api_url_min: araApiUrl + 'region/minifiedRegion',
  region_props_api_url_min: araApiUrl + 'region/minifiedRegionProps',
  region_api_url_min_for_single_rights: araApiUrl + 'region/minifiedRegionForSingleRights',
  region_location_props_api_url_min: araApiUrl + 'region/minifiedRegionLocationProps',
  request_rule_api_url: araApiUrl + 'request/rule/',
  request_right_api_url: araApiUrl + 'request/right/',
  request_api_url: araApiUrl + 'request/',
  approver_api_url: araApiUrl + 'approver/',
  rights_manager_api_url: araApiUrl + 'rights-manager/',
  compliance_api_url: araApiUrl + 'compliance/',
  compliance_manager_api_url: araApiUrl + 'compliance-manager/',
  i18n_api_url: araApiUrl + 'i18n/',
  lookup_api_url: araApiUrl + 'lookup/',

  skip_iwa: false,
  iwa_domain_url: 'https://auth-i.bmwgroup.net/auth/oauth2/realms/root/realms/intranetb2x/authorize?client_id=478343a0-0f66-4f4d-9006-26fa2291e750&scope=openid%20profile&redirect_uri=https://ara-i.bmwgroup.net&response_type=code',
  app_domain_url: 'https://localhost:4200/',
  post_logout_redirect_uri: 'https://contenthub.bmwgroup.net/web/start',
  am_base_url: 'https://auth-i.bmwgroup.net/auth',
  realm_path: 'realms/root/realms/intranetb2x',
  login_msg: null,
  username: 'QXV6311',
  uid: 'QXV6311',
  loggedInUser: null,
  clientId: 'ara_angular',
  token: 'aa',
  tokenResponse: null,

  wen_cookie: 'weni',
  lbwen_cookie: 'lbweni',
  about_url: 'https://atc.bmwgroup.net/confluence/pages/viewpage.action?pageId=1353690402'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
