import {Component, OnInit} from '@angular/core';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import BusinessRule from 'src/app/shared/model/business-rules/business-rule';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Application from 'src/app/shared/model/applications/application';
import Right from 'src/app/shared/model/rights/right';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import ApplicationRightsCategory from 'src/app/shared/model/applications/application-rights-category';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {FormControl, FormGroup} from '@angular/forms';
import {SelectListOption} from '@bmw-ds/components';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ToastService} from 'src/app/service/feedback/toast.service';
import BusinessCategoryMinified from '../../../shared/model/business-rules/business-category-min';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent
} from 'ag-grid-community';
import UserDisplay from '../../../shared/model/user/user-display';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import ApplicationOverviewMin from '../../../shared/model/applications/application-overview-min';

@Component({
  selector: 'app-business-rule-right',
  templateUrl: './business-rule-right.component.html',
  styleUrls: ['./business-rule-right.component.scss']
})
export class BusinessRuleRightComponent implements OnInit {

  allApplicationsWithRights: Application[];
  selectedBusinessRule: BusinessRule;
  selectedApplication: Application;
  selectedBusinessRuleOriginal: BusinessRule;
  private requestedRights: Right[] = [];
  private removedRights: Right[] = [];
  selectedBusinessRuleRights: any[] = [];
  selectedBusinessCategory: BusinessCategoryMinified;
  businessCategories: BusinessCategoryMinified[];
  private overviewIncludes: Map<string, boolean> = new Map<string, boolean>();
  overview: Application[] = [];


  businessCategoryList: SelectListOption[];
  businessRuleList: SelectListOption[];
  applicationList: SelectListOption[];

  selectionForm = new FormGroup({
    businessCategory: new FormControl(),
    businessRule: new FormControl(),
    application: new FormControl(),
  });

  editType: 'fullRow' = 'fullRow';
  tableData: Right[] = [];
  tableDataOverview: ApplicationOverviewMin[] = [];
  columns = this.getColumns();
  columnsOverview = this.getColumnsOverview();
  gridApi!: GridApi<UserDisplay>;
  rowSelection: 'single' | 'multiple' = 'single';
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };

  rowEvents: GridOptions = {
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateRightStatus(event)
  }

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];

  constructor(private businessRuleHttpService: BusinessRuleHttpService,
              private applicationHttpService: ApplicationHttpService,
              private businessCategoryHttpService: BusinessCategoryHttpService,
              private sweetAlertService: SweetAlertService,
              private toastService: ToastService, private i18nProvider: I18nProvider,) {
  }

  ngOnInit() {
    this.getAllBusinessCategories();
    this.selectionValueChanges();
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<UserDisplay>) {
    this.gridApi = params.api;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_manage_business_rules_right_pagination', size);
    this.paginationSize = size;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_manage_business_rules_right_pagination')) {
      return localStorage.getItem('ara_manage_business_rules_right_pagination');
    }
    return 40;
  }

  updateRightStatus(rowObject: any) {
    if (rowObject.column.colId == 'selected') {
        this.toggleRequestedRights(null, null, rowObject.data)
        rowObject.data.selected = !rowObject.data.selected;
        let refreshParams = {force: true, rowNodes: [rowObject.node]};
        rowObject.api.refreshCells(refreshParams);
     }
  }

  getColumns(){
    return [
      {field: 'categoryName', headerName: this.i18nProvider.getDescription('application_rights_category'), filter: true, sortable: true,
        width: 100, enableRowGroup: true},
      {field: 'name', headerName: this.i18nProvider.getDescription('application_rights'), filter: true, sortable: true,
        width: 100},
      {field: 'selected', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true,
        width: 50, cellRenderer: (params) => {return this.setStatusCheckbox(params.data);}}
    ];

  }

  getColumnsOverview(){
    return [
      {field: 'name', headerName: this.i18nProvider.getDescription('application'), filter: true, sortable: true,
        width: 70, enableRowGroup: true},
      {field: 'categoryName', headerName: this.i18nProvider.getDescription('category_name'), filter: true, sortable: true,
        width: 70, enableRowGroup: true},
      {field: 'rightName', headerName: this.i18nProvider.getDescription('application_rights'), filter: true, sortable: true,
        width: 70}
    ];
  }

  setTableData() {
    this.tableData = [];
    if (this.selectedApplication && this.selectedApplication.applicationRightsCategories && this.selectedApplication.applicationRightsCategories.length > 0) {
      this.selectedApplication.applicationRightsCategories.forEach(rightCat => {
        if (rightCat.rights.length > 0) {
          rightCat.rights.forEach(right => {
            right.categoryId = rightCat.id;
            right.categoryName = rightCat.name;
            right.selected = this.checkedValue(right);
            this.tableData.push(right);
          })
        }
      });
    }
  }

  setOverviewTableData() {
    this.tableDataOverview = [];
    if (this.allApplicationsWithRights && this.allApplicationsWithRights.length > 0) {
      this.allApplicationsWithRights.forEach(appRight => {
        if (appRight && appRight.applicationRightsCategories && appRight.applicationRightsCategories.length > 0) {
          appRight.applicationRightsCategories.forEach(rightCat => {
            rightCat.rights.forEach(right => {
              const selectedBusinessRuleIncludesRight = this.selectedBusinessRule.rights.filter(sBRR => sBRR.id === right.id).length > 0;
              if (selectedBusinessRuleIncludesRight) {
                let app = new ApplicationOverviewMin();
                app.name = appRight.name;
                app.categoryName = rightCat.name;
                app.rightName = right.name;
                this.tableDataOverview.push(app);
                this.requestedRights.push(right);
              }
            });
          });
        }
      });
    }
  }

  setStatusCheckbox(data) {
    if (data && data.selected) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }

  private getAllBusinessCategories() {
    this.businessCategoryHttpService.getMinfiedBusinessCategoriesWithRules().subscribe(categories => {
      this.businessCategories = categories.sort((a, b) => a.name.localeCompare(b.name));
      this.businessCategoryList = this.businessCategories.map(category => {
        return {id: category.id.toString(), label: category.name};
      });
    }, (error => {
      this.sweetAlertService.showComError('Error retrieving BusinessCategories: ' + JSON.stringify(error));
    }));
  }


  checkedValue(right: Right) {
    return !this.isNewRight(right) ||
      this.requestedRights.includes(right);
  }


  buildOriginalOverview() {
    this.overviewIncludes = new Map<string, boolean>();
    this.overview = [];
    this.setOverviewTableData();
    if (this.allApplicationsWithRights !== null) {
      this.allApplicationsWithRights.forEach(app => {
        if (app.applicationRightsCategories !== null) {
          app.applicationRightsCategories.forEach(appRightCat => {
            if (appRightCat.rights !== null) {
              appRightCat.rights.forEach(right => {
                const selectedBusinessRuleIncludesRight = this.selectedBusinessRule.rights.filter(sBRR => sBRR.id === right.id).length > 0;
                if (selectedBusinessRuleIncludesRight) {
                  this.addRightToOverview(app, appRightCat, right);
                  this.requestedRights.push(right);
                }
              });
            }
          });
        }
      });
    }
    this.selectedApplication?.applicationRightsCategories.sort((a, b) => a.name.localeCompare(b.name));
    this.selectedApplication?.applicationRightsCategories.forEach(appRightCat =>
      appRightCat.rights.sort((a, b) => a.name.localeCompare(b.name)));
  }

  private addRightToOverview(pApp: Application, pAppRightCat: ApplicationRightsCategory, pRight: Right) {
    let overviewApplication: Application;
    let overviewApplicationRightCat: ApplicationRightsCategory;

    if (this.overviewIncludes.has(pApp.name)) {
      overviewApplication = this.overview.reduce((overviewApp, currentApp) => currentApp.name === pApp.name ? currentApp : overviewApp);

    } else {
      overviewApplication = new Application();
      overviewApplication.id = pApp.id;
      overviewApplication.name = pApp.name;
      overviewApplication.applicationRightsCategories = [];
      this.overview.push(overviewApplication);
      this.overviewIncludes.set(overviewApplication.name, true);
    }

    if (this.overviewIncludes.has(pApp.name + pAppRightCat.name)) {
      overviewApplicationRightCat = overviewApplication.applicationRightsCategories.reduce((overviewAppRightCat, currentAppRightCat) =>
        currentAppRightCat.name === pAppRightCat.name ? currentAppRightCat : overviewAppRightCat);
    } else {
      overviewApplicationRightCat = new ApplicationRightsCategory();
      overviewApplicationRightCat.id = pAppRightCat.id;
      overviewApplicationRightCat.name = pAppRightCat.name;
      overviewApplicationRightCat.rights = [];
      overviewApplication.applicationRightsCategories.push(overviewApplicationRightCat);
      this.overviewIncludes.set(overviewApplication.name + overviewApplicationRightCat.name, true);
    }
    if (!this.overviewIncludes.has(pApp.name + pAppRightCat.name + pRight.name)) {
      const newOverviewRight = new Right();
      newOverviewRight.id = pRight.id;
      newOverviewRight.name = pRight.name;
      overviewApplicationRightCat.rights.push(newOverviewRight);
      this.overviewIncludes.set(overviewApplication.name + overviewApplicationRightCat.name + newOverviewRight.name, true);
    }
  }

  isNewRight(right: Right) {
    return this.requestedRights.filter(requestedRight => right.id === requestedRight.id).length === 0;
  }

  selectionValueChanges() {
    this.listenToBusinessCategorySelection();
    this.listenToBusinessRuleSelection();
    this.listenToApplicationSelection();
  }

  listenToBusinessCategorySelection() {
    this.tableData = [];
    this.selectionForm.controls.businessCategory.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe(id => {
    if (id){
      this.selectionForm.controls.businessRule.reset();
      this.selectionForm.controls.application.reset();
      this.applicationList = [];
      this.businessRuleList = [];
      this.overview = [];
      this.selectedBusinessCategory = this.businessCategories.find(bc => bc.id === +id);
      if (this.selectedBusinessCategory.businessRules.length > 0) {
        this.selectedBusinessCategory.businessRules = this.selectedBusinessCategory.businessRules.filter(br => br.active)
        this.selectedBusinessCategory.businessRules.sort((a, b) => a.name.localeCompare(b.name));
        this.businessRuleList = this.selectedBusinessCategory.businessRules.map(br => {
          return {id: br.id.toString(), label: br.name};
        });
      }
    }
    });
  }

  listenToBusinessRuleSelection(){
    this.tableData = [];
    this.selectionForm.controls.businessRule.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe(id => {
      if (id){
        this.applicationList = [];
        this.overview = [];
        this.selectionForm.controls.application.reset();
        this.selectedBusinessRule = this.selectedBusinessCategory?.businessRules.find(br => br.id === +id);
        this.getSelectedRuleRights().then(r => {
           if (r) {
             this.selectedBusinessRule.rights = r;
            }
          });
        this.selectedBusinessRule.rights = this.selectedBusinessRuleRights;
        this.selectedBusinessRuleOriginal = JSON.parse(JSON.stringify(this.selectedBusinessRule));
        this.applicationHttpService.getMinifiedMasterApplicationsWithRights().subscribe(
          appsWithRights => {
            this.allApplicationsWithRights = appsWithRights.sort((a, b) => a.name.localeCompare(b.name));
            this.allApplicationsWithRights.forEach(appWithRights => {
              if (appWithRights.applicationRightsCategories) {
                appWithRights.applicationRightsCategories.sort((a, b) => a.name.localeCompare(b.name));
                appWithRights.applicationRightsCategories.forEach(arc => {
                  arc.rights.sort((a, b) => a.name.localeCompare(b.name));
                });
              }
            });
            this.applicationList = this.allApplicationsWithRights.map(br => {
              return {id: br.id.toString(), label: br.name};
            });
            this.buildOriginalOverview();
          },
          error => {
            this.sweetAlertService.showComError('Error retrieving Applications: ' + error);
          });
      }
    });
    }

    listenToApplicationSelection(){
      this.selectionForm.controls.application.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(1000))
        .subscribe(id => {
        if (id){
          this.selectedApplication = this.allApplicationsWithRights.find(br => br.id === +id);
          this.setTableData();
        }
      });
      }

 async getSelectedRuleRights(): Promise<any[]> {
   return this.businessRuleHttpService.getBusinessRuleRights(this.selectedBusinessRule.id).then(rights => {
     return rights;
   });
 }

  refreshPage() {
    this.selectedBusinessCategory = null;
    this.selectedBusinessRule = null;
    this.selectedApplication = null;
    this.overview = [];
    this.selectionForm.reset();
    this.businessCategoryList = [];
    this.applicationList = [];
    this.businessRuleList = [];
    this.getAllBusinessCategories();
    this.selectionValueChanges();
  }

  toggleRequestedRights(pApp?: Application, pAppRightsCat?: ApplicationRightsCategory, right?: Right) {
    if (this.requestedRights.includes(right)) {
      this.requestedRights.splice(this.requestedRights.indexOf(right), 1);
      this.selectedBusinessRule.rights = this.selectedBusinessRule.rights.filter(sBRR => sBRR.id !== right.id);
      this.buildOriginalOverview();
      this.removedRights.push(right);
    } else {
      this.requestedRights.push(right);
      this.selectedBusinessRule.rights.push(right);
      this.buildOriginalOverview();
      if (this.removedRights.includes(right)) {
        this.removedRights.splice(this.removedRights.indexOf(right), 1);
      }
    }
  }

  clickSaveRights() {
    const addedRightIds = this.requestedRights.map(right => right.id);
    const removeRightIds = this.removedRights.map(right => right.id);
    if (this.selectedBusinessRule.rights.length > this.selectedBusinessRuleOriginal.rights.length) {
      this.businessRuleHttpService.addRightsToRule(this.selectedBusinessRule.id, addedRightIds)
        .then(() => {
          this.businessRuleHttpService.removeRightsFromRule(this.selectedBusinessRuleOriginal.id, removeRightIds)
            .then(() => {
              if (!(removeRightIds && removeRightIds.length > 0)) {
                this.finaliseSaveRights();
              }
            }, () => {
              this.sweetAlertService.showComError('Error removing rights from rule: ' + this.selectedBusinessRuleOriginal.name);
            });
          this.sweetAlertService.showSuccessMessage('Business Rule: ' + this.selectedBusinessRuleOriginal.name + ' updated successfully.');
          this.selectionForm.controls.businessRule.reset();
          this.selectionForm.controls.application.reset();
          this.selectionForm.controls.businessCategory.reset();
          this.tableData = [];
          this.tableDataOverview = [];
        },
        (error) => {
          this.sweetAlertService.showComError('Error adding rights to rule: ' + this.selectedBusinessRuleOriginal.name);
          console.log(error)
        });
    }
    if (this.selectedBusinessRule.rights.length < this.selectedBusinessRuleOriginal.rights.length) {
      this.businessRuleHttpService.removeRightsFromRule(this.selectedBusinessRuleOriginal.id, removeRightIds)
        .then(() => this.finaliseSaveRights(), () => {
          this.sweetAlertService.showComError('Error removing rights from rule: ' + this.selectedBusinessRuleOriginal.name);
        });
    }
  }

  private finaliseSaveRights() {
    this.requestedRights = [];
    this.refreshPage();
  }

  isApplicationCategoryRightsAvailable(applicationRightsCategory: ApplicationRightsCategory): boolean {
    return applicationRightsCategory.rights.filter(value => value.active == true).length > 0;
  }
}
