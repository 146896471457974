<div class="container-costom p-0">
  <div class="row">
    <div class="col-md-12 mt-5 p-0">
      <h1 class="h1-heading">
        <span class="blue">{{'' | i18n: 'archive'}}</span>
      </h1>
    </div>
  </div>
<div class = "row">
  <div class = "mb-2 mt-1">
    Region<br />
    <ds-form-field class = "mt-1 col-2" style="width: 92%; margin-left:-15px">
      <ds-select style="width: 92%;"
                 id="category"
                 [formControl]="region"
                 [labellingConfig]="{ placeholder: 'Select' }"
                 [options]="regionList"
                 [showClearAll]="true"
      ></ds-select>
    </ds-form-field>
  </div>
</div>
  <div class = "col-12 p-0 mt-3 mb-2" *ngIf="employees?.length > 0">
    <div class="ag-theme-alpine row">
      <span class="push-left" *ngIf="employees?.length > 0">
          {{selectedRegionName}}
        </span>
      <div class="col-12 mt-2 mb-2 d-flex justify-content-end">
    <span class="push-right">
      Page Size:
      <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize" id="page-size" class="pagination-size">
        <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
      </select>
    </span>
      </div>
    </div>
    <ag-grid-angular
      style="width: 100%; height: 1200px"
      class="ag-theme-alpine"
      rowHeight="35"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]=paginationSize
      [rowData]="employees"
      [rowSelection]="rowSelection"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      [rowGroupPanelShow]="rowGroupPanelShow"
      [defaultColDef]="defaultColDef"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>
</div>
