import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Region from 'src/app/shared/model/requests/region';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import Application from 'src/app/shared/model/applications/application';
import {RegionAdminHttpService} from 'src/app/service/http/region-administration-http.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import User from 'src/app/shared/model/user/user';
import {UserRole} from 'src/app/shared/enums/user-role';
import TokenResponse from 'src/app/shared/model/user/token-response';
import RegionMinified from '../../../shared/model/requests/region-min';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {I18nProvider} from '../../../service/translations/i18n.service';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {
  CellClickedEvent, GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';

@Component({
  selector: 'app-regions-applications',
  templateUrl: './regions-applications.component.html',
  styleUrls: ['./regions-applications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegionsApplicationsComponent implements OnInit {
  region: Region;
  allApplications: Application[];
  selectedApplication: Application;
  editMode = false;
  private loggedInUser: User;
  selectedRegion: RegionMinified;
  loggedInUserRegions: Region[];
  tokenResponse: TokenResponse;
  showApps = false;
  regionIds: number[];
  regions: RegionMinified[];
  selectedRegionId = new FormControl();
  columns =  [];
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<Application>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedApplication = event.data;
      this.editMode = true;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedApplication = event.data;
      this.editMode = true;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedApplication = null;
      this.editMode = false;
    }
  };
  constructor(private regionHttpService: RegionHttpService, private applicationHttpService: ApplicationHttpService,
              private regionAdminHttpService: RegionAdminHttpService, private sweetAlertService: SweetAlertService,
              private userHttpService: UserHttpService, private i18nProvider: I18nProvider) {
  }

  ngOnInit() {
    this.showApps = false;
    this.editMode = false;
    this.selectedRegion = null;
    this.getColumns();
    this.userHttpService.getLoggedInUser(false).subscribe(user => {
        this.applicationHttpService.getMasterApplications().subscribe(value => {
            this.allApplications = value;
            this.allApplications.sort((a, b) => a.name.localeCompare(b.name));
          },
          () => this.sweetAlertService.showComError('Error retrieving applications information'));
        this.loggedInUser = user;
        const regions: Region[] = user?.roles.filter(role => role.name === UserRole.ARA_REGION_ADMIN).map(value => value?.region);
        this.loggedInUserRegions = regions.map(region => {
          if (region?.active && region?.name){
            return region;
          }
        });
        this.regionIds = this.loggedInUserRegions.map(region => region?.id);
        this.getRegions();
      },
        error => this.sweetAlertService.showComError('Unable to retrieve logged in user: ' + error));
  }

  getRegions(){
    this.regionHttpService.getRegionsMinified().subscribe(response => {
      this.regions = response.filter(region => {
        return this.regionIds.includes(region?.id);
      });
    });
  }

  private calculateSelectedApplicationsForRegion() {
    this.allApplications.forEach(application => {
        application.enabledForRegion = false;
        application.status = this.i18nProvider.getDescription('inactive');
        this.selectedRegion.applications.forEach(regionApplication => {
          if (application.id === regionApplication.id) {
            application.enabledForRegion = true;
            application.status = this.i18nProvider.getDescription('active');
          }
        });
      });
    this.gridApi.setRowData(this.allApplications);
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }


  unlinkApplications() {
      this.regionAdminHttpService.removeLinkApplicationToRegion(this.selectedApplication.id, this.selectedRegion.id).subscribe(() => {
          this.allApplications.forEach(app => {
            if (app.id === this.selectedApplication.id){
              app.enabledForRegion = false;
              app.status = this.i18nProvider.getDescription('inactive');
            }
          });
          this.gridApi.setRowData(this.allApplications);
        },
        () => this.sweetAlertService.showComError('Error removing link for applicationId: ' +
          this.selectedApplication.id + ' with regionId: ' + this.selectedRegion.id));
  }

  linkApplications() {
      this.regionAdminHttpService.linkApplicationToRegion(this.selectedApplication.id, this.selectedRegion.id).subscribe(() => {
          this.allApplications.forEach(app => {
            if (app.id === this.selectedApplication.id){
              app.enabledForRegion = true;
              app.status = this.i18nProvider.getDescription('active');
            }
          });
          this.gridApi.setRowData(this.allApplications);
        },
        () => this.sweetAlertService.showComError('Error linking aplicationid : ' + this.selectedApplication.id +
          ' with region id :' + this.selectedRegion.id));
  }


  getColumns(){
    this.columns = [
      {
        field: 'name',
        headerName: this.i18nProvider.getDescription('application_name'),
        filter: true,
        sortable: true,
        width: 1130
      },
      {
        field: 'status',
        headerName: this.i18nProvider.getDescription('status'),
        filter: true,
        width: 198,
        sortable: true,
        cellClass: params => {
          return params.data.enabledForRegion ? 'active' : 'inactive';
        }
      }];
  }
  changeRegion() {
    this.calculateSelectedApplicationsForRegion();
    this.editMode = false;
    this.showApps = true;
  }
  onGridReady(params: GridReadyEvent<Application>) {
    this.gridApi = params.api;
  }
  onRegionSelectionChange(){
    this.selectedRegionId.valueChanges
      .pipe( distinctUntilChanged(), debounceTime(1000))
      .subscribe(regionId => {
        if (regionId){
          this.selectedRegion = this.regions.find(region => region?.id === regionId);
        }
      });
  }
}
