import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import ComplianceDto from 'src/app/shared/model/applications/compliance-dto';
import ComplianceRight from 'src/app/shared/model/rights/compliance-right';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {

  private _http: AraHttpService;


  constructor(http: AraHttpService) {
    this._http = http;
  }

  public getAllDefinitions(): Observable<ComplianceDto> {
    return this._http.doGet(environment.compliance_api_url);
  }

  public createViolationDefinition(complianceRight: ComplianceRight): Observable<ComplianceRight> {
    return this._http.doPost(environment.compliance_api_url, complianceRight);
  }

  public deleteComplianceViolationDefinition(complianceRight: ComplianceRight): Observable<ComplianceRight> {
    return this._http.doDeleteWithBody(environment.compliance_api_url, complianceRight);
  }
}
