import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';

@Component({
  selector: 'app-details-button-renderer',
  template: `
    <span :class="missionSpan">
           <img
             src="../../../../assets/quartz-icons//eye.svg"
             height="190"
             class="missionIcon"
             (click)="buttonClicked()">
        </span>
  `,
  styles: [
    'img { width: 25px; height: auto; } span {display: flex; height: 100%; justify-content: center; align-items: center} ',
  ],
})
export class DetailsButtonRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public value!: string;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams) {
    this.value = params.value ? 'tick-in-circle' : 'cross-in-circle';
    return true;
  }
  buttonClicked() {
    console.log('Details is button clicked');
    this.params.context.componentParent.showRequestDetails(this.params.data, true);
  }
}
