import {Injectable} from '@angular/core';
import {I18nProvider} from './translations/i18n.service';

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

@Injectable({
  providedIn: 'root'
})
export class ProfileItemStatusUtilsService {
  private static readonly IN_REMOVAL = 'in_removal';
  private static readonly EXPIRING_SOON = 'expired';
  private static readonly ACTIVE = 'active';
  private static readonly STYLE_IN_REMOVAL = 'in_removal';
  private static readonly STYLE_EXPIRING_SOON = 'expired';
  private static readonly STYLE_ACTIVE = 'active';

  constructor(private i18nProvider: I18nProvider) {
  }

  getStatusName(endDate: string): string {
    // const endDate = this.datePipe.transform(endDate, 'yyyy/MM/dd');

    const endDateDate = new Date(endDate);
    const nowDate = new Date();
    let compareDate = new Date(nowDate.getTime() + 7 * MILLISECONDS_PER_DAY);
    const isWithinSevenDays = compareDate.getTime() > endDateDate.getTime();
    if (isWithinSevenDays) {
      return this.i18nProvider.getDescription(ProfileItemStatusUtilsService.IN_REMOVAL);
    } else {
      compareDate = new Date(nowDate.getTime() + 28 * MILLISECONDS_PER_DAY);
      const isWithinTwentyEight = compareDate.getTime() > endDateDate.getTime();
      if (isWithinTwentyEight) {
        return this.i18nProvider.getDescription(ProfileItemStatusUtilsService.EXPIRING_SOON);
      }
    }
    return  this.i18nProvider.getDescription(ProfileItemStatusUtilsService.ACTIVE);
  }

  getStatusStyle(endDate: string): string {
    const endDateDate = new Date(endDate);
    const nowDate = new Date();
    let compareDate = new Date(nowDate.getTime() + 7 * MILLISECONDS_PER_DAY);
    const isWithinSevenDays = compareDate.getTime() > endDateDate.getTime();
    if (isWithinSevenDays) {
      return this.i18nProvider.getDescription(ProfileItemStatusUtilsService.STYLE_IN_REMOVAL);
    } else {
      compareDate = new Date(nowDate.getTime() + 28 * MILLISECONDS_PER_DAY);
      const isWithinTwentyEight = compareDate.getTime() > endDateDate.getTime();
      if (isWithinTwentyEight) {
        return this.i18nProvider.getDescription(ProfileItemStatusUtilsService.STYLE_EXPIRING_SOON);
      }
    }
    return  this.i18nProvider.getDescription(ProfileItemStatusUtilsService.STYLE_ACTIVE);
  }
}
