import {Component, OnInit} from '@angular/core';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import BusinessRule from 'src/app/shared/model/business-rules/business-rule';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {ToastService} from 'src/app/service/feedback/toast.service';
import {da, SelectListOption} from '@bmw-ds/components';
import {FormControl, FormGroup} from '@angular/forms';
import BusinessCategoryMinified from '../../../shared/model/business-rules/business-category-min';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent
} from 'ag-grid-community';
import UserDisplay from '../../../shared/model/user/user-display';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import BusinessCategoryRuleDisplayMin from '../../../shared/model/business-rules/business-category-rule-display-min';
import {RegionHttpService} from '../../../service/http/region-http.service';
import RegionPropsMinified from '../../../shared/model/requests/region-props-min';
import BusinessRuleRegion from '../../../shared/model/business-rules/business-rule-region';

@Component({
  selector: 'app-business-rules',
  templateUrl: './business-rules.component.html',
  styleUrls: ['./business-rules.component.scss']
})
export class BusinessRulesComponent implements OnInit {
  auth = true;
  showBusinessRule = false;
  showAddParam = false;
  showDisabled = true;
  newBusinessRule = new BusinessRule();
  newBusinessCategoryId: number;
  businessRules: BusinessRule[];
  showUpdateRule = new Map<number, boolean>();
  businessCategories: BusinessCategoryMinified[];
  deleteRuleVar: BusinessRule;
  display = 'none';
  regions: RegionPropsMinified[] = [];
  editType: 'fullRow' = 'fullRow';
  tableData: BusinessCategoryRuleDisplayMin[] = [];
  columns = this.getColumns();
  gridApi!: GridApi<UserDisplay>;
  rowSelection: 'single' | 'multiple' = 'single';

  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowValueChanged: (event: RowValueChangedEvent) => this.updateRuleName(event),
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateRuleStatus(event)
  }

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];

  businessCategoryList: SelectListOption[];

  businessRuleForm = new FormGroup({
    businessCategory: new FormControl(),
    businessRule: new FormControl(),
  });
  constructor(private businessRuleHttpService: BusinessRuleHttpService,
              private businessCategoryHttpService: BusinessCategoryHttpService,
              private sweetAlertService: SweetAlertService, private toastService: ToastService, private i18nProvider: I18nProvider,
              private regionService: RegionHttpService) {
  }

  ngOnInit() {
    this.getRegions();
    this.getAllBusinessCategories();
  }

  private getAllBusinessCategories() {
    this.businessCategoryHttpService.getActiveMinifiedBusinessCategoriesWithRules().subscribe(successResponse => {
      this.businessCategories = successResponse.sort((a, b) => a.name.localeCompare(b.name));
      this.businessCategories.forEach(businessCategory => businessCategory.businessRules.sort((a, b) => a.name.localeCompare(b.name)));
      this.businessCategoryList = this.businessCategories.map(category => {
        return {id: category.id.toString(), label: category.name};
      });
      this.paginationSize = this.setPaginationSize();
      this.tableData = this.setTableData();
      this.setRegionColumns();

    }, (error => {
      this.sweetAlertService.showComError('Error retrieving BusinessCategories: ' + JSON.stringify(error));
    }));
  }

  addRule() {
    this.newBusinessRule.businessCategoryId = +this.businessRuleForm.controls.businessCategory.value;
    this.newBusinessRule.name = this.businessRuleForm.controls.businessRule.value;
    this.businessRuleHttpService.createBusinessRule(this.newBusinessRule).subscribe(() => {
      this.newBusinessRule = new BusinessRule();
      /*      SweetAlertService.showComError("success: " + this.businessCategories);*/
      this.showBusinessRule = false;
      this.businessRuleForm.reset();
      this.ngOnInit();
    }, () => {
      this.sweetAlertService.showComError('Error retrieving businessRule: ');
    });
  }

  updateRuleStatus(rowObject: any) {

    if (rowObject.column.colId == 'active') {
      let businessRule = new BusinessRule();
      let data = rowObject.data;
      if (rowObject.column.colId == 'active') {
        businessRule.active = !data.active;
        rowObject.data.active = businessRule.active;
        let refreshParams = {force: true, rowNodes: [rowObject.node]};
        rowObject.api.refreshCells(refreshParams);
      }
      businessRule.id = data.business_rule_id;
      businessRule.name = data.business_rule_name;

      this.businessRuleHttpService.updateExistingBusinessRule(businessRule).subscribe(() => {
        this.showUpdateRule.set(businessRule.id, false);
      }, (error) => {
        this.sweetAlertService.showComError('Error retrieving businessRule: ' + JSON.stringify(error));
      });
    }

    if (rowObject.column.colId !== 'active' && rowObject.column.colId !== 'business_category_name' && rowObject.column.colId !== 'business_rule_name') {
      let businessRuleRegion = new BusinessRuleRegion();
      businessRuleRegion.businessRuleId = rowObject.data.business_rule_id;
      businessRuleRegion.regionId = parseInt(rowObject.column.colId);
      businessRuleRegion.active = !rowObject.data.regions.includes(parseInt(rowObject.column.colId));

      this.businessRuleHttpService.linkBusinessRuleToRegion(businessRuleRegion).subscribe(() => {
        if (businessRuleRegion.active) {
          rowObject.data.regions.push(businessRuleRegion.regionId);
        } else {
          const index = rowObject.data.regions.indexOf(businessRuleRegion.regionId, 0);
          if (index > -1) {
            rowObject.data.regions.splice(index, 1);
          }
        }

        let refreshParams = {force: true, rowNodes: [rowObject.node]};
        rowObject.api.refreshCells(refreshParams);

        this.toastService.success('Business Rule [' + rowObject.data.business_rule_name + '] linkage to Region: [' + rowObject.column.colDef.headerName + '] updated.');
      }, (error) => {
        this.sweetAlertService.showComError('Error updating Business Rule - Region linkage: ' + JSON.stringify(error));
      });

    }
  }

  updateRuleName(rowObject: any) {
    let businessRule = new BusinessRule();
    let data = rowObject.data;
    businessRule.id = data.business_rule_id;
    businessRule.name = data.business_rule_name;
    businessRule.active = data.active;

    this.businessRuleHttpService.updateExistingBusinessRule(businessRule).subscribe(() => {
      this.showUpdateRule.set(businessRule.id, false);
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessRule: ' + JSON.stringify(error));
    });

  }

  showDeleteConfirmationModal() {
    this.display = 'block';
  }

  deleteRule() {
    this.businessRuleHttpService.deleteBusinessRule(this.deleteRuleVar).subscribe(() => {
      this.getAllBusinessCategories();
    }, () => {
      this.toastService.error('Unable to delete Business Rule. Please check the Business Rule Rights.');
    });
    this.display = 'none';
  }

  clickDeleteBusinessRule(businessRule: BusinessRule) {

    this.deleteRuleVar = businessRule;
    this.showDeleteConfirmationModal();
  }

  getColumns(){
    return [
      {field: 'business_category_name', headerName: this.i18nProvider.getDescription('category'), filter: true, sortable: true,
        width: 100, enableRowGroup: true},
      {field: 'business_rule_name', headerName: this.i18nProvider.getDescription('business_rule_name'), filter: true, sortable: true,
        width: 100, editable: true, enableRowGroup: true},
      {field: 'version', headerName: 'Version', filter: true, sortable: true,
        width: 50, editable: true, enableRowGroup: true},
      {field: 'active', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true,
        width: 50, enableRowGroup: true, cellRenderer: (params) => {return this.setStatusCheckbox(params.data);}}
    ];

  }

  setStatusCheckbox(data) {
    if (data && data.active) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }

  setCheckBox(data) {
    return '<input type="checkbox" checked>'
  }

  setStatusCheckbox_(params) {
    if (params && params.data && params.data.regions && params.data.regions.includes(Number(params.colDef.field)) && params.data.active) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }

  onGridReady(params: GridReadyEvent<UserDisplay>) {
    this.gridApi = params.api;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_manage_business_rules_pagination', size);
    this.paginationSize = size;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_manage_business_rules_pagination')) {
      return localStorage.getItem('ara_manage_business_rules_pagination');
    }
    return 40;
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setTableData(): any[] {
    const dataRows: BusinessCategoryRuleDisplayMin[] = [];

    let requestValid = true;
    if (this.businessCategories) {
      this.businessCategories.forEach(businessCategory => {
        businessCategory.businessRules.forEach(businessRule => {
          //console.log('businessRule', businessRule);
          let displayBusinessRule = new BusinessCategoryRuleDisplayMin();
          displayBusinessRule.business_category_id = businessCategory.id;
          displayBusinessRule.business_category_name = businessCategory.name;
          displayBusinessRule.business_rule_id = businessRule.id;
          displayBusinessRule.business_rule_name = businessRule.name;
          displayBusinessRule.active = businessRule.active;
          displayBusinessRule.version = businessRule.version;
          displayBusinessRule.regions = businessRule.regionIds;
          dataRows.push(displayBusinessRule);
          displayBusinessRule = null;
        });
      });
    }
    return dataRows;
  }

  private setRegionColumns() {
    const colDefs = this.gridApi.getColumnDefs();
    this.regions.forEach(region => {
      colDefs.push({field: region.id.toString(), headerName: region.shortCode, filter: true, sortable: true, width: 5,
        cellRenderer: (params) => {return this.setStatusCheckbox_(params);}});
    });
    this.gridApi.setColumnDefs(colDefs);
  }

  getRegions() {
    this.regionService.getRegionsPropsMinified(true).subscribe(regions => {
      this.regions = regions.sort((a, b) => a.name.localeCompare(b.name));
    });
  }
}

