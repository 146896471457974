import Swal from 'sweetalert2';
import {Injectable} from '@angular/core';
import {I18nProvider} from '../translations/i18n.service';
import {Observable} from 'rxjs';

@Injectable({
providedIn: 'root'
})
export class SweetAlertService {

  private i18nProvider: I18nProvider;

  constructor(i18nProvider: I18nProvider) {
    this.i18nProvider = i18nProvider;
  }
  public showSuccessMessage(msg) {
    Swal.fire(this.i18nProvider.getDescription('success'), msg, 'success');
  }
  public showComError(msg: string) {
    Swal.fire(this.i18nProvider.getDescription('comm_err'), msg, 'error');
  }
  public showError(msg: string) {
    Swal.fire('Error', msg, 'error');
  }

  public confirmation(message: string): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      Swal.fire({
        title: 'Are you sure?',
        text: message + ' You won\'t be able to revert this!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#E96D0C',
        confirmButtonText: 'confirm'
      }).then((result) => {
        if (result.isConfirmed) {
         subscriber.next(result.isConfirmed);
        }
      });
    });
  }
}
