export enum UserRole {
  ARA_MASTER_ADMIN = 'ARA_MASTER_ADMIN',
  ARA_REGION_ADMIN = 'ARA_REGION_ADMIN',
  ARA_APPROVER = 'ARA_APPROVER',
  ARA_DEPUTY_APPROVER = 'ARA_DEPUTY_APPROVER',
  ARA_SPECIAL_APPROVER = 'ARA_SPECIAL_APPROVER',
  ARA_RIGHTS_ADMIN = 'ARA_RIGHTS_ADMIN',
  ARA_SPECIAL_RIGHTS_ADMIN = 'ARA_SPECIAL_RIGHTS_ADMIN',
  ARA_COMPLIANCE_MANAGER = 'ARA_COMPLIANCE_MANAGER'
}
