<div class="row">
  <div class="col-12 mt-5">
    <h1 class="small-heading">
      <span class="archive-title">Users</span>
    </h1>
  </div>
</div>
<div class="ag-theme-alpine row">
  <div class="col-12 mt-2 mb-0 d-flex justify-content-end">
    <span class="push-right">
      Page Size:
      <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize" id="page-size" class="pagination-size">
        <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
      </select>
    </span>
  </div>
</div>

<div class="row " style="width: 100%;margin-left: 1px;">
  <div class = "col-12 mt-3 mb-2">
    <ag-grid-angular
      style="width: 100%; height: 1200px"
      class="ag-theme-alpine"
      rowHeight="35"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="paginationSize"
      [rowGroupPanelShow]="rowGroupPanelShow"
      (firstDataRendered)="onFirstDataRendered($event)"
      [rowData]="users"
      [rowSelection]="rowSelection">
    </ag-grid-angular>
  </div>
</div>
