import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import Application from 'src/app/shared/model/applications/application';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {DateFormat} from 'src/app/shared/commons/dateFormat';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Request from 'src/app/shared/model/requests/request';
import {RequestStatusUtilsService} from 'src/app/service/http/request-status-utils.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import GeneratedRequest from 'src/app/shared/model/requests/generated-request.interface';
import {RequestBuilderService} from 'src/app/service/helper-services/request-builder.service';
import {DialogService} from 'src/app/service/modal/dialog.service';
import {RequestDetailsComponent} from 'src/app/components/popup/request-details/request-details.component';
import {ApplicationsService} from 'src/app/service/helper-services/applications.service';
import {
  CellClickedEvent, GetRowIdFunc, GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
import {LicenseManager} from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-050873 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( BMW AG )_is_granted_a_( Single Application )_Developer_License_for_the_application_( ARA AG Grid Lizenz f. Enterprise Version )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( ARA AG Grid Lizenz f. Enterprise Version )_need_to_be_licensed___( ARA AG Grid Lizenz f. Enterprise Version )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 December 2024 )____[v2]_MTczNDMwNzIwMDAwMA==c290d63db16791080b0fa558fea5555a')
import {SweetAlertService} from '../../../service/feedback/SweetAlertService.service';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {formatDate} from '@angular/common';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import User from '../../../shared/model/user/user';
import BmwPerson from '../../../shared/model/user/bmw-person';
import RequestStatus from '../../../shared/model/requests/request_status';
import RequestsForRightsManagerDto from '../../../shared/model/requests/requests-for-rights-manager-dto';
@Component({
  selector: 'app-rights-manager-request-details',
  templateUrl: './rights-manager-request-details.component.html',
  styleUrls: ['./rights-manager-request-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RightsManagerRequestDetailsComponent implements OnInit {
  selectedRequest: GeneratedRequest;
  requests: GeneratedRequest[] = [];
  showRequestOverview = false;
  qNumber: string;
  currentUser: User;
  columns = this.getTableColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<GeneratedRequest>;
  requester: BmwPerson;
  ctrl: string = 'ctrl';
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedRequest = event.data;
      localStorage.setItem('ara_rights_manager_selected_row_category', this.selectedRequest.category);
      localStorage.setItem('ara_rights_manager_selected_row_rule', this.selectedRequest.rule);
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedRequest = event.data;
      localStorage.setItem('ara_rights_manager_selected_row_category', this.selectedRequest.category);
      localStorage.setItem('ara_rights_manager_selected_row_rule', this.selectedRequest.rule);
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedRequest = null;
      this.showRequestOverview = false;
    }
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];
  lg: any;

  constructor(private requestService: RequestHttpService, private activatedRoute: ActivatedRoute,
              private requestHttpService: RequestHttpService, private dateFormat: DateFormat,
              private applicationHttpService: ApplicationHttpService, public router: Router,
              private lookupHttpService: LookupHttpService, private userHttpService: UserHttpService,
              private sweetAlertService: SweetAlertService, private requestBuilderService: RequestBuilderService,
              private dialog: DialogService, private applications: ApplicationsService,
              private requestStatusUtilsService: RequestStatusUtilsService, private i18nProvider: I18nProvider) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.qNumber = this.router.getCurrentNavigation().extras.state.qNumber;
      this.lookupHttpService.getUser(this.qNumber).subscribe(usr => {
        this.requester = usr;
      });
    }
  }

  ngOnInit(): void {
    if (this.qNumber) {
      this.initOpenRequests(this.qNumber);
    } else {
      this.router.navigate(['/rights-manager-current']);
    }
    this.userHttpService.getLoggedInUser(false).subscribe(user => {
      this.currentUser = user;
    });
    this.paginationSize = this.setPaginationSize();
  }

  initOpenRequests(qNumber: string) {
    this.requestHttpService.getOpenUserRequestsAwaitingRightsManager(qNumber)
      .subscribe((requests: Request[]) => {
        this.requestBuilderService.prepareRequest(requests).then((generatedRequest: GeneratedRequest[]) => {
          this.requests = generatedRequest;
          this.requests.forEach(request => {
            request.region_name = request.region.name;
            request.status = this.requestStatusUtilsService.getStatusName(request);
            request.application_names = request.applications.map(application => application.name);
            request.end_date = formatDate(request.endDate, 'yyyy/MM/dd', 'en-GB');
            request.start_date = formatDate(request.startDate, 'yyyy/MM/dd', 'en-GB');
          });
          this.requests.sort((a, b) => b.status.localeCompare(a.status));
        });
  });
  }
  getStatusStyle(openRequestReq: Request) {
    return this.requestStatusUtilsService.getStatusStyle(openRequestReq);
  }

  showRequestDetails(req: Request | GeneratedRequest | any) {
    this.dialog.open(RequestDetailsComponent, { data: req });
    this.selectedRequest = req;
    this.selectedRequest.businessRuleDTO?.rights.sort((a, b) => a.name.localeCompare(b.name));
  }

  showApplicationsView(req: Request | GeneratedRequest | any) {
    this.selectedRequest = req;
    this.showRequestOverview = true;
  }
  getTableColumns(){
    return [
      {field: 'region_name', headerName: this.i18nProvider.getDescription('region_name'), filter: true, sortable: true, width: 200},
      {field: 'category', headerName: this.i18nProvider.getDescription('category'), filter: true, sortable: true, width: 250},
      {field: 'rule', headerName: this.i18nProvider.getDescription('rules_or_rights'), filter: true, sortable: true, width: 300},
      {field: 'start_date', headerName: this.i18nProvider.getDescription('start_date'), filter: true, sortable: true, width: 150, cellRenderer: (data) =>
        {return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');}},
      {field: 'end_date', headerName: this.i18nProvider.getDescription('end_date'), filter: true, sortable: true , width: 150,
        cellClass: params => {
          const today = new Date().getTime();
          const selectedDate = new Date(params.data.endDate).getTime();
          if (today === selectedDate || today > selectedDate)
          {
            return 'changed';
          }}, cellRenderer: (data) => {return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');}
          },
      {field: 'application_names', headerName: this.i18nProvider.getDescription('application_name'),
        filter: true, sortable: true, width: 500},
      {
        field: 'status', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true, width: 210,
        cellClass: params => {
          return this.getReqStatusStyle(params.data.requestStatus.name);
        },
      },
    ];
  }
  getReqStatusStyle(status: string) {
    return this.requestStatusUtilsService.getStatusStyleByStatusName(status);
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<GeneratedRequest>) {
    this.gridApi = params.api;
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams<any>) =>
      params.data.id;
  showWarning(date): boolean{
    const today = new Date().getTime();
    const selectedDate = new Date(date).getTime();

    return today === selectedDate || today > selectedDate;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_rights_manager_request_details_pagination')) {
      return localStorage.getItem('ara_rights_manager_request_details_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_rights_manager_request_details_pagination', size);
    this.paginationSize = size;
  }

  backToDashboard() {
    this.router.navigate(['/rights-manager-current']);
  }

  isActiveRequest(selectedRequest: GeneratedRequest) {
    if (selectedRequest.category == undefined) {
      selectedRequest.category = localStorage.getItem('ara_rights_manager_selected_row_category');
    }

    if (selectedRequest.rule == undefined) {
      selectedRequest.rule = localStorage.getItem('ara_rights_manager_selected_row_rule');
    }

    const rowNode = this.gridApi.getRowNode(selectedRequest.id.toString());
    rowNode.setDataValue('status', this.requestStatusUtilsService.getStatusName(selectedRequest));
    return true;
  }

  refreshScreen() {
    this.initOpenRequests(this.qNumber);
  }
}
