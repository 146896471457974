<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class="black">{{'' | i18n: 'manage_business_rule_categories'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
        <button type="" class="btn-black-costom " [ds-modal-trigger-for]="add" [(isModalOpen)]="modalIsOpen">{{'' | i18n: 'new_category'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="container-costom">

    <div class="col-12">
      <div class="col-12 d-flex justify-content-end p-0 mb-2" *ngIf="editMode">
        <button class="green_b-tab" *ngIf="selectedBusinesscategory.active" (click)="changeStatus(false)">{{'' | i18n: 'deactivate'}}</button>
        <button class="green_b-tab" *ngIf="!selectedBusinesscategory.active" (click)="changeStatus(true)">{{'' | i18n: 'activate'}}</button>
        <button class="green_b-tab" [ds-modal-trigger-for]="update" [(isModalOpen)]="updateModalIsOpen">{{'' | i18n: 'edit'}}</button>
        <button class="green_b-tab" [ds-modal-trigger-for]="rules" >{{'' | i18n: 'business_rules'}}</button>

      </div>

      <div class="flex-container">
        <ag-grid-angular
          class="ag-theme-alpine ag-grid-full-height-width"
          rowHeight="35"
          [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
          [pagination]="true"
          [suppressDragLeaveHidesColumns]="true"
          [paginationPageSize]="15"
          [rowData]="businessCategories"
          [rowGroupPanelShow]="rowGroupPanelShow"
          (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
          (cellClicked)="rowEvents.onRowClicked($event)"
          (paginationChanged)="rowEvents.onPaginationChanged($event)"
          [columnDefs]="columns"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
<ds-modal #update size="sm">
  <ds-box-header>{{'' | i18n: 'edit'}} {{'' | i18n: 'business_category'}}</ds-box-header>
  <ds-box-content *ngIf="selectedBusinesscategory">
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_name'}}</label>
      <input ds-input type="text" [(ngModel)]="selectedBusinesscategory.name" required/>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="updateCategory(selectedBusinesscategory)" [disabled]="!selectedBusinesscategory?.name">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>

<ds-modal #add size="sm">
  <ds-box-header>{{'' | i18n: 'add'}} {{'' | i18n: 'business_category'}}</ds-box-header>
  <ds-box-content >
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_name'}}</label>
      <input ds-input type="text" [(ngModel)]="businessCategoriesName" required/>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="addCategory()" [disabled]="!businessCategoriesName">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>

<ds-modal #rules size="sm">
  <ds-box-header>{{'' | i18n: 'add'}} {{'' | i18n: 'business_rules'}}</ds-box-header>
  <ds-box-content *ngIf="selectedBusinesscategory">
    <ds-list-item *ngFor="let rule of selectedBusinesscategory?.businessRules; let i = index">{{i+1}} - {{rule.name}}</ds-list-item>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
  </ds-box-footer>
</ds-modal>
