import BusinessRule from './business-rule';
import Right from '../rights/right';

export default class BusinessCategory {
  id: number;
  name: string;
  active: boolean;
  status?: string;
  businessRules: BusinessRule[];
  rights: Right[];
  master: boolean;
}
