<div class="container-costom p-0">
<!--  <ds-banner-->
<!--    tone="positive"-->
<!--    *ngIf="hasWork"-->
<!--    [isDismissable]="false"-->
<!--    bannerTitle="{{'' | i18n: 'own_work_not_allowed'}}"-->
<!--  ></ds-banner>-->
<div class="row">
  <div class="col-12 mt-5">
    <h1 class="small-heading">
      <span class="ap-title">{{'' | i18n: 'current_requests'}}</span>
    </h1>
  </div>
</div>
<div class="row">
  <div class="ag-theme-alpine row">
    <div class="col-12 mt-2 mb-0 d-flex justify-content-end">
    <span class="push-right">
      Page Size:
      <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize" id="page-size" class="pagination-size">
        <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
      </select>
    </span>
    </div>
  </div>
  <div class = "col-12 mt-3 mb-2">
    <ag-grid-angular
      style="width: 100%; height: 550px"
      class="ag-theme-alpine"
      rowHeight="56"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]=paginationSize
      [rowData]="groupedRequests"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      [defaultColDef]="defaultColDef"
      (firstDataRendered)="onFirstDataRendered($event)"
    >
    </ag-grid-angular>
  </div>
</div>
</div>
