import {Component, Inject, OnInit} from '@angular/core';
import {DialogRef} from 'src/app/service/modal/dialog-ref';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {DsDatepickerConfiguration, minMaxDate} from '@bmw-ds/components';
import {DIALOG_DATA} from '../../../service/modal/dialog-tokens';

@Component({
  selector: 'app-give-back-user-assignments',
  templateUrl: './give-back-user-assignments.component.html',
  styleUrls: ['./give-back-user-assignments.component.css']
})
export class GiveBackUserAssignmentsComponent implements OnInit {
  assignmentsForm = new FormGroup({
    endDate: new FormControl(new Date(), Validators.required),
    comment: new FormControl('', Validators.required),
    employeeQnumber: new FormControl(this.approverEmployee.employeeQnumber),
    approverActioned: new FormControl(this.approverEmployee.isApprover),
  });
  today: number = new Date().getDate() - 1;
  dateConfig: Partial<DsDatepickerConfiguration> = {
    disabledDatesFactory: [minMaxDate({ min: new Date(new Date().setDate(this.today))})],
    allowDirectInput: false
  };
  constructor(private dialogRef: DialogRef, @Inject(DIALOG_DATA) public approverEmployee: any,
              private requestHttpService: RequestHttpService) { }

  ngOnInit(): void {
  }

  giveBackAssignments(){
    this.requestHttpService.giveBackAssignments(this.assignmentsForm.value).subscribe(e => {
      this.dialogRef.close();
    });
  }
  closeModal(){
    this.dialogRef.close();
  }
}
