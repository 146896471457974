import { Injectable } from '@angular/core';
import User from '../../shared/model/user/user';
import Region from '../../shared/model/requests/region';
import Comment from '../../shared/model/requests/comment';
import RequestStatus from '../../shared/model/requests/request_status';
import {RequestStatuses} from '../../shared/enums/request-statuses';
import BusinessCategoryAccordionDisplay from '../../shared/model/business-rules/business-category-accordion-display';
import {UserHttpService} from '../http/user-http.service';
import RegionMinified from '../../shared/model/requests/region-min';
import Right from '../../shared/model/rights/right';
import Application from '../../shared/model/applications/application';
import Request from '../../shared/model/requests/request';
import BusinessRuleMinified from '../../shared/model/business-rules/business-rule-min';
import RightMinified from '../../shared/model/rights/right-min';

@Injectable({
  providedIn: 'root'
})
export class NewRequestService {

  user: User;
  constructor(private userHttpService: UserHttpService) {
    this.userHttpService.getLoggedInUser(false).subscribe(loggedinUser => {
      this.user = loggedinUser;
    });
  }

  getRequest(form: any, selectedRegion: RegionMinified, rights: RightMinified[],
             businessRules: BusinessRuleMinified[], isApprover: boolean, userRequesting: User): Request{
    const request = new Request();
    request.startDate = form.startDate;
    request.endDate = form.endDate;
    request.requester = this.getRequester();
    request.requestedFor = userRequesting;
    rights ? request.rights = rights : request.rights = [];
    businessRules ? request.businessRules = businessRules : request.businessRules = [];
    request.region = this.getRegion(selectedRegion);
    let auditComment = isApprover ? ' (Beantragt durch Führungskraft für Benutzer '
      + userRequesting.name + ' ' + userRequesting.surname + ')': '';
    form.comment = form.comment + (auditComment);
    request.comments = this.getComment(form.comment);
    request.requestStatus = this.getStatus();
    request.capturedByApprover = isApprover;
    request.locations = selectedRegion?.locations.filter(location => location?.selected);

    return request;
  }

  getRequester(): User{
    const requester = new User();
    requester.qnumber = this.user.qnumber;
    requester.name = this.user.name;
    requester.surname = this.user.surname;
    requester.managerINumber = this.user.managerINumber;
    return requester;
  }

  getRegion(selectedRegion: RegionMinified): Region{
    const region = new Region();
    region.id = selectedRegion?.id;
    region.name = selectedRegion?.name;
    return region;
  }

  getComment(userComment: string ): Comment[]{
    const commentsList: Comment[] = [];
    const comment = new Comment();
    comment.comment = userComment;
    comment.createdBy = this.user.qnumber;
    comment.modifiedBy = this.user.qnumber;
    commentsList.push(comment);
    return commentsList;
  }

  getStatus(newStatus: string = RequestStatuses.DRAFT ): any{
    const status = new RequestStatus();
    status.name = newStatus;
    return status;
  }
}
