import { Injectable } from '@angular/core';
import { DsNavigationItem } from '@bmw-ds/components/ds-interfaces/navigation-bar.interface';
import { UserRole } from 'src/app/shared/enums/user-role';

@Injectable({
  providedIn: 'root'
})
export class MenuServiceService {

  private TOKEN_RESPONSE = 'TOKEN_RESPONSE';

  constructor() { }

  public getCurrentUserRoles() {
    const storedObject = sessionStorage.getItem(this.TOKEN_RESPONSE) !== null ? JSON.parse(sessionStorage.getItem(this.TOKEN_RESPONSE)) : null;
    if (storedObject !== null) {
      return storedObject.user.roles.map(role => {
        return role.name;
      });
    }
  }

  public getRoleBasedUserMenuItems(navigationItems: DsNavigationItem[]) {

    let menuItems: DsNavigationItem[] = navigationItems;
    const roles: string[] = this.getCurrentUserRoles();

    if (!roles.includes(UserRole.ARA_MASTER_ADMIN)) {
        menuItems = menuItems.filter(item => {
          return item.id !== 'item-2';
        });
      }

    if (!roles.includes(UserRole.ARA_REGION_ADMIN)) {
        menuItems = menuItems.filter(item => {
          return item.id !== 'item-3';
        });
      }

    if (!roles.includes(UserRole.ARA_RIGHTS_ADMIN)) {
        menuItems = menuItems.filter(item => {
          return item.id !== 'item-4';
        });
      }

    if (!roles.includes(UserRole.ARA_APPROVER) && !roles.includes(UserRole.ARA_DEPUTY_APPROVER)) {
        menuItems = menuItems.filter(item => {
          return item.id !== 'item-5';
        });
      }

    if (!roles.includes(UserRole.ARA_COMPLIANCE_MANAGER)) {
        menuItems = menuItems.filter(item => {
          return item.id !== 'item-6';
        });
      }

    if (!roles.includes(UserRole.ARA_SPECIAL_RIGHTS_ADMIN)) {
        menuItems = menuItems.filter(item => {
          return item.id !== 'item-7';
        });
      }
    return menuItems;
  }


}
