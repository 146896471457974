export enum RequestStatuses {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PENDING_SPECIAL_RIGHTS_APPROVAL = 'PENDING_SPECIAL_RIGHTS_APPROVAL',
  PENDING_COMPLIANCE_APPROVAL = 'PENDING_COMPLIANCE_APPROVAL',
  ARA_APPROVER = 'ARA_APPROVER',
  PENDING_RIGHTS_MANAGER_APPROVAL = 'PENDING_RIGHTS_MANAGER_APPROVAL',
  GRANTED = 'GRANTED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  REMOVED = 'REMOVED',
  IN_REMOVAL = 'IN_REMOVAL',
  DELETED = 'DELETED',
  CHANGES_MADE = 'CHANGES_MADE',
  EXPIRING_SOON = 'EXPIRING_SOON'
}
