<div *ngIf="selectedApplication">
  <div class = "table-scroller">
    <table class="table mb-5" >
      <thead>
      <tr>
        <th scope="col">App Category
        </th>
        <th scope="col">App Right
        </th>
        <th scope="col">{{'' | i18n: 'status'}}
        </th>
        <th scope="col">{{'' | i18n: 'start_date'}}
        </th>
        <th scope="col">{{'' | i18n: 'end_date'}}</th>
        <th scope="col">{{'' | i18n: 'type'}}</th>
        <th scope="col">{{'' | i18n: 'location'}}</th>
        <th scope="col">Done
          <ds-switch (ngModelChange)="onCompleteRights($event)" [disabled]="canRemoveApplication()">
            (Select All)
          </ds-switch></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let right of selectedApplicationRights">
        <td>{{getRightApplicationCategoryName(right)}}</td>
        <td>{{right.name}}</td>
        <td [ngClass]="right.active ? 'active' : 'inactive' ">{{getRightStatus(right.active)}}</td>
        <td>{{selectedRequest.startDate | date: 'dd/MM/yyyy'}}</td>
        <td>{{selectedRequest.endDate | date: 'dd/MM/yyyy'}}</td>
        <td>{{selectedRequest.rule}}</td>
        <td>{{setLocationsForDisplay(selectedRequest?.locations)}}</td>
        <td [ngClass]="right.active ? 'approved' : 'rejected' "> <ds-switch [(ngModel)]="right.active" [disabled]="canRemoveApplication()"></ds-switch></td>
      </tr>
      </tbody>
    </table>
  </div>
  <br/>
  <br/>
  <div class ="row mb-5">
    <div class = "col-6">
      <ds-form-field>
        <label ds-label for="comment">{{'' | i18n: 'leave_comment'}}</label>
        <textarea ds-input id="comment" ds-form-validation required [formControl]="comment" [readonly] ="canRemoveApplication()"></textarea>
      </ds-form-field>
    </div>
    <div class ="col-6 mt-4 p-0 justify-content-end a-r-c">
      <button class = "p-3" ds-button (click)="removeRights()" [disabled]="canRemoveApplication()">{{'' | i18n: 'save_application'}}</button>
      <button class = "p-3" ds-button (click)="removeApplication()" [disabled]="canRemoveApplication()">{{'' | i18n: 'complete_application'}}</button>
      <!--button class = "p-3" ds-button (click)="removeRequest()" [disabled]="canRemoveRequest()">{{'' | i18n: 'submit_request'}}</button-->
    </div>
  </div>
</div>
