import {Component, OnInit} from '@angular/core';
import Application from 'src/app/shared/model/applications/application';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import Right from 'src/app/shared/model/rights/right';
import {ComplianceService} from 'src/app/service/http/compliance.service';
import ComplianceDto from 'src/app/shared/model/applications/compliance-dto';
import ComplianceRight from 'src/app/shared/model/rights/compliance-right';

@Component({
  selector: 'app-violations',
  templateUrl: './violations.component.html',
  styleUrls: ['./violations.component.scss']
})
export class ViolationsComponent implements OnInit {
  auth = true;
  showNewComplianceDiv = false;
  showDisabled = false;
  showActive = true;
  newRight = new Right();
  applications: Application[];
  private applicationHttpService: ApplicationHttpService;
  private sweetAlertService: SweetAlertService;
  private complianceService: ComplianceService;
  display = 'none';
  showCoexistingViolation = false;
  complianceDto: ComplianceDto;
  selectedApplication: Application;
  firstSelectedRight: Right;
  rightsList: Right[] = [];
  secondSelectedRight: Right;

  constructor(applicationHttpService: ApplicationHttpService, sweetAlertService: SweetAlertService, complianceService: ComplianceService) {
    this.applicationHttpService = applicationHttpService;
    this.sweetAlertService = sweetAlertService;
    this.complianceService = complianceService;
  }


  ngOnInit() {
    this.applicationHttpService.getMasterApplicationsWithRights().subscribe(successResponse => {
      this.applications = successResponse;
    }, (error => {
      this.sweetAlertService.showComError('Error retrieving BusinessCategories: ' + JSON.stringify(error));
    }));
    this.complianceService.getAllDefinitions().subscribe(value =>
      this.complianceDto = value);
  }

  addViolationDefinition() {
    const complianceRight = new ComplianceRight();
    complianceRight.id = this.firstSelectedRight.id;
    if (!this.showCoexistingViolation) {
      const secondRight = new ComplianceRight();
      secondRight.id = this.firstSelectedRight.id;
      complianceRight.disallowedRight = secondRight;
    } else {
      const secondRight = new ComplianceRight();
      secondRight.id = this.secondSelectedRight.id;
      complianceRight.disallowedRight = secondRight;
    }
    this.complianceService.createViolationDefinition(complianceRight).subscribe(() => {

      this.refreshAllDefinitions();
    } );
  }

  addCoExistingViolationClicked() {
    this.showCoexistingViolation = true;
  }

  backToSingleViolationClicked() {
    this.showCoexistingViolation = false;
  }

  applicationChanged() {
    this.selectedApplication.applicationRightsCategories.forEach(category =>
      category.rights.forEach(right =>
        this.rightsList.push(right)));
  }

  delete(compRight: ComplianceRight) {
    this.complianceService.deleteComplianceViolationDefinition(compRight).subscribe(() => {

      this.refreshAllDefinitions();
    });
  }

  private refreshAllDefinitions() {
    this.complianceService.getAllDefinitions().subscribe(value => {
      this.firstSelectedRight = null;
      this.secondSelectedRight = null;
      this.selectedApplication = null;
      this.rightsList = [];
      this.showNewComplianceDiv = false;
      return this.complianceDto = value;

    });
  }
}
