import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {environment} from 'src/app/../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  appRoles: Array<string> = ['Admin', 'Operator', 'Viewer'];

  constructor( private cookieService: CookieService, private http: HttpClient) {
  }

  static iwaLogin() {
    window.location.href = environment.iwa_domain_url;
  }

  static isAuthenticated() {
    return environment.tokenResponse && environment.tokenResponse.accessToken && environment.tokenResponse.accessToken.access_token;
  }

  static getAccessToken() {
    return AuthenticationService.isAuthenticated() ? environment.tokenResponse.accessToken.access_token : null;
  }

  static getCurrentUser() {
    return environment.loggedInUser;
  }

  static canShowDeveloperOptions() {
    const currentUser = AuthenticationService.getCurrentUser();
    return currentUser && currentUser.username === 'QXX1920';
  }

  logout() {
    const tokenResponse = JSON.parse(sessionStorage.getItem('TOKEN_RESPONSE'));
    const oidcToken = tokenResponse.accessToken.id_token;
    return  this.http.get(`${environment.ara_logout_post_url + oidcToken}`);
  }

  clearStorages(){
    environment.tokenResponse = null;
    environment.username = null;
    environment.uid = null;
    environment.token = null;
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.cookieService.delete(environment.wen_cookie);
    this.cookieService.delete(environment.lbwen_cookie);
    this.cookieService.delete(environment.wen_cookie, null, '.bmwgroup.net');
    this.cookieService.delete(environment.lbwen_cookie, null, '.bmwgroup.net');
    environment.login_msg = 'LOG_OUT';
    window.location.href = `${environment.post_logout_redirect_uri}`;
  }

}
