import {Component, OnInit} from '@angular/core';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import User from 'src/app/shared/model/user/user';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Application from 'src/app/shared/model/applications/application';
import Right from 'src/app/shared/model/rights/right';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {UserRole} from 'src/app/shared/enums/user-role';

@Component({
  selector: 'app-special-rights-manager',
  templateUrl: './special-rights-manager.component.html',
  styleUrls: ['./special-rights-manager.component.scss']
})
export class SpecialRightsManagerComponent implements OnInit {
  auth = true;
  showNewRightManager = false;

  applications: Application[];
  applicationRights: Right[];
  private userHttpService: UserHttpService;
  private regionHttpService: RegionHttpService;
  private applicationHttpService: ApplicationHttpService;
  private sweetAlertService: SweetAlertService;
  users: User[];
  selectedApplication: Application;
  selectedRight: Right;
  newQNumber = '';
  showSearchResults = false;

  constructor(userHttpService: UserHttpService, regionHttpService: RegionHttpService,
              applicationHttpService: ApplicationHttpService,
              sweetAlertService: SweetAlertService) {
    this.userHttpService = userHttpService;
    this.regionHttpService = regionHttpService;
    this.applicationHttpService = applicationHttpService;
    this.sweetAlertService = sweetAlertService;

  }

  ngOnInit() {
    this.refreshUsers();
    this.applicationHttpService.getMasterApplicationsWithRights().subscribe(
      successResponse => this.applications = successResponse,
      error => this.sweetAlertService.showComError('Error retrieving applications. : ' + error)
    );
  }

  private refreshUsers() {
    this.userHttpService.getUsersByRoleAndRegion(UserRole.ARA_SPECIAL_RIGHTS_ADMIN, 7).subscribe(
      successResponse => this.users = successResponse,
      errorResponse => this.sweetAlertService.showComError('Error retrieving users. : ' + errorResponse)
    );
  }

  addNewRightsAdmin() {
    this.userHttpService.grantRole(1, this.newQNumber, UserRole.ARA_SPECIAL_RIGHTS_ADMIN, this.selectedRight.id)
      .subscribe(() => this.refreshUsers(),
        error => this.sweetAlertService.showComError('Error assigning right. : ' + JSON.stringify(error)));
    // this.refreshUsers();
    this.showNewRightManager = false;
    this.showSearchResults = false;
    this.newQNumber = '';
    this.selectedApplication = null;
    this.selectedRight = null;
  }

  getRightForSpecialAdminRole(user: User) {
    const role = user.roles.reduce((previousValue, currentValue) => {
      if (currentValue.name === UserRole.ARA_SPECIAL_RIGHTS_ADMIN) {
        return currentValue;
      } else {
        return previousValue;
      }
    });
    return role.right.name;
  }

  removeSpecialRightsManager(user: User) {
    this.showNewRightManager = false;
    this.userHttpService.removeRoleFromUser(user.qnumber, UserRole.ARA_SPECIAL_RIGHTS_ADMIN).subscribe(
      () => this.refreshUsers(),
      error => this.sweetAlertService.showComError('Error removing right from user, :' + error)
    );
  }

  populateRightsDropdown() {
    this.selectedApplication.applicationRightsCategories.forEach(
      rightsCategory => rightsCategory.rights.forEach(
        right => this.applicationRights.push(right)
      ));
  }
}
