import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import Region from 'src/app/shared/model/requests/region';
import {HttpParams} from '@angular/common/http';
import RegionMinified from 'src/app/shared/model/requests/region-min';
import RegionPropsMinified from '../../shared/model/requests/region-props-min';
import RegionLocationPropsMinified from '../../shared/model/requests/region-location-props-min';

@Injectable({
  providedIn: 'root'
})
export class RegionHttpService {
  private http: AraHttpService;


  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getRegions(onlyActive: boolean, shallow: boolean = false): Observable<Region[]> {
    let params = new HttpParams().set('onlyActive', onlyActive.toString());
    params = params.set('shallow', shallow.toString());
    return this.http.doGetParams(environment.region_api_url, params);
  }

  public getRegionsMinified(): Observable<RegionMinified[]> {
    return this.http.doGet(environment.region_api_url_min);
  }
  public getRegionsMinifiedForSingleRights(): Observable<RegionMinified[]> {
    return this.http.doGet(environment.region_api_url_min_for_single_rights);
  }

  public getRegionsPropsMinified(onlyActive: boolean): Observable<RegionPropsMinified[]> {
    let params = new HttpParams().set('onlyActive', onlyActive.toString());
    return this.http.doGetParams(environment.region_props_api_url_min, params);
  }

  public getRegionsLocationsPropsMinified(onlyActive: boolean): Observable<RegionLocationPropsMinified[]> {
    let params = new HttpParams().set('onlyActive', onlyActive.toString());
    return this.http.doGetParams(environment.region_location_props_api_url_min, params);
  }

  public getRegionById(regionId: string, fullApplications: boolean = false): Observable<Region> {
    const params = new HttpParams().set('fullApplications', fullApplications.toString());
    return this.http.doGetParams(environment.region_api_url + regionId + '/', params);
  }

  public updateRegion(region: Region): Observable<Region> {
    return this.http.doPut(environment.region_api_url, region);
  }

  public createRegion(region: Region): Observable<Region> {
    return this.http.doPost(environment.region_api_url, region);
  }
}
