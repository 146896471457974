import { Injectable } from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import Department from '../../shared/model/department';

@Injectable({
  providedIn: 'root'
})
export class AllowedLoginsService {
  private http: AraHttpService;


  constructor(http: AraHttpService) {
    this.http = http;
  }

  getAllDepartments(){
    return this.http.doGet(environment.allowed_logins_api_url + 'all');
  }

  saveDepartment(department: Department){
    return this.http.doPost(environment.allowed_logins_api_url, department);
  }

  updateDepartment(department: Department){
    return this.http.doPut(environment.allowed_logins_api_url, department);
  }

  deleteDepartment(id: number){
    return this.http.doDelete(environment.allowed_logins_api_url + id);
  }

}
