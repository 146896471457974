<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class="light-orange">{{'' | i18n: 'manage_application_rights_categories'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
      </div>
      <div class="form-group">
        <button class="btn-warning" [ngClass]="{'disabled':showNewCategoryBlock}" (click)="showNewCategoryBlock=true ;
         showDisabled=true" [disabled]="showNewCategoryBlock">{{'' | i18n: 'add_category'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="row table_costom">
  <div class="container-costom">
    <div class="col-12 d-flex align-items-end mt-4 mb-4" *ngIf="showNewCategoryBlock">

      <div class="col-8 d-flex justify-content-start p-0">
        <ds-form-field class="col-4 pl-0">
          <label ds-label for="application">{{'' | i18n: 'choose_application'}}:</label>
          <ds-select
            [formControl]="applicationId"
            id="application"
            [labellingConfig]="{ placeholder: '' | i18n: 'choose_application'}"
            [options]="applicationSelectionList">
          </ds-select>
        </ds-form-field>

        <ds-form-field class="col-4 pl-0">
          <label ds-label for="category">{{'' | i18n: 'category_name'}}:</label>
          <input ds-input id="category" type="text" placeholder="{{'' | i18n: 'category_name'}}"
                 [readonly]="!applicationId.value"
                 [(ngModel)]="newCategory.name"/>
        </ds-form-field>
      </div>

      <div class="col-4 d-flex justify-content-end pr-0">
        <button ds-button class = "col-4 black-button" (click)="showNewCategoryBlock=false">{{'' | i18n: 'cancel'}}</button>
        <button ds-button class="col-4" [disabled]="!newCategory.name" (click)="addCategory()">{{'' | i18n: 'add'}}</button>
      </div>


    </div>
    <div class="row " style="width: 100%;margin-left: 1px;">
      <div class = "col-12 mt-3 mb-2">
        <ag-grid-angular
          style="width: 100%; height: 550px"
          class="ag-theme-alpine"
          rowHeight="35"
          [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
          [columnDefs]="columns"
          [defaultColDef]="defaultColDef"
          [pagination]="true"
          [suppressDragLeaveHidesColumns]="true"
          [paginationPageSize]="paginationSize"
          (firstDataRendered)="onFirstDataRendered($event)"
          [editType]="editType"
          (rowValueChanged)="rowEvents.onRowValueChanged($event)"
          [rowData]="tableData"
          [rowSelection]="rowSelection"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <!--    <div class="row">-->
    <!--      <div class="col-md-6 col-lg-6 " *ngFor="let application of applications , index as i">-->
    <!--        <div id="accordion-request">-->
    <!--          <div class="panel">-->
    <!--            <div class="panel-heading">-->
    <!--              <h4 class="panel-title">-->
    <!--                <a href="#application-{{i}}" class="accordion-toggle collapsed" data-toggle="collapse"-->
    <!--                   data-parent="#accordion-request"-->
    <!--                   aria-expanded="false">-->
    <!--                  <div class="row header-background">-->
    <!--                    <div class="col-lg-12">-->
    <!--                      {{application.name}}-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </a>-->
    <!--              </h4>-->
    <!--            </div>-->
    <!--            <div id="application-{{i}}" class="panel-collapse collapse">-->
    <!--              <div class="row">-->
    <!--                <div class="col-md-12">-->
    <!--                  <table class="table ">-->
    <!--                    <tbody>-->
    <!--                    <tr>-->
    <!--                      <td>-->
    <!--                        <div  class="categoryGroup col-md-12" *ngFor="let category of application.applicationRightsCategories">-->
    <!--                          <i class="fa fa-pencil" (click)="showUpdateCategory.set(category.id, true)"-->
    <!--                             *ngIf="!showUpdateCategory.get(category.id)"></i>-->
    <!--                          <div class="col-md-6 categoryIten"-->
    <!--                               *ngIf="!showUpdateCategory.get(category.id)">{{category.name}}</div>-->

    <!--                          <i class="fa fa-times" (click)="showUpdateCategory.set(category.id, false)"-->
    <!--                             *ngIf="showUpdateCategory.get(category.id)"></i>-->

    <!--                          <div class="col-md-5">-->
    <!--                            <input *ngIf="showUpdateCategory.get(category.id)" type="text" [(ngModel)]="category.name"-->
    <!--                                   placeholder="{{'' | i18n: 'category_name'}}" required/>-->
    <!--                          </div>-->
    <!--                          <div style="display: flex" class="col-md-5">-->
    <!--                            <i class="fa fa-check" (click)="updateApplication(application, category)" *ngIf="showUpdateCategory.get(category.id)"></i>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </td>-->
    <!--                    </tr>-->
    <!--                    </tbody>-->
    <!--                  </table>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->


















    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>
