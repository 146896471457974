import {Injectable} from '@angular/core';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import BusinessCategoryMinified from '../../shared/model/business-rules/business-category-min';

@Injectable({
  providedIn: 'root'
})
export class BusinessCategoryHttpService {
  private http: AraHttpService;


  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getBusinessCategory(id: number, withRulesAndRights: boolean = false): Observable<BusinessCategory> {
    const httpParams = new HttpParams().set('withRulesAndRights', withRulesAndRights);
    return this.http.doGetParams(environment.business_category_api_url + id, httpParams);
  }

  public getActiveBusinessCategories(): Observable<BusinessCategory[]> {
    return this.http.doGet(environment.business_category_api_url + 'active');
  }

  public getBusinessCategoriesWithRules(): Observable<BusinessCategory[]> {
    return this.http.doGet(environment.business_category_with_rules_api_url);
  }

  public getMinfiedBusinessCategoriesWithRules(): Observable<BusinessCategoryMinified[]> {
    return this.http.doGet(environment.minified_business_category_with_rules_api_url);
  }

  public getActiveBusinessCategoriesWithRules(): Observable<BusinessCategory[]> {
    return this.http.doGet(environment.business_category_with_rules_api_url + 'active');
  }

  public getActiveMinifiedBusinessCategoriesWithRules(): Observable<BusinessCategoryMinified[]> {
    return this.http.doGet(environment.business_category_minified_with_rules_api_url + 'active');
  }

  public getActiveBusinessCategoriesWithRulesAndRights(): Observable<BusinessCategory[]> {
    return this.http.doGet(environment.business_category_with_rules_and_rights_api_url + 'active');
  }

  public updateBusinessCategory(businessCategory: BusinessCategory): Observable<BusinessCategory> {
    return this.http.doPut(environment.business_category_api_url, businessCategory);
  }

  public createBusinessCategory(businessCategory: BusinessCategory): Observable<BusinessCategory> {
    return this.http.doPost(environment.business_category_api_url, businessCategory);
  }
}
