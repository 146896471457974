<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class="green">{{'' | i18n: 'manage_single_rights'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="form-group c-label col-md-2 app_select">
      <label>{{'' | i18n: 'select_application'}}:</label>
      <div class = "">
        <select [(ngModel)]="selectedApplication" (change)="resetSelectedRightApplication()" style="width: 100%">
          <option disabled selected >{{'' | i18n: 'choose_application'}}:</option>
          <option *ngFor="let optionApplication of allApplications" [ngValue]="optionApplication">{{optionApplication.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group c-label col-md-3 app_select">
      <label>{{'' | i18n: 'select_category'}}:</label>
      <div class = "">
        <select [(ngModel)]="selectedApplicationRightsCategory" (change)="resetSelectedRightCategory()" (focus)="shortenOptions()" style="width: 100%" >
          <option disabled selected >Select</option>
          <option *ngFor="let appRightCat of selectedApplication?.applicationRightsCategories" [ngValue]="appRightCat">{{appRightCat.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group c-label col-md-3 app_select">
      <label>{{'' | i18n: 'select_single_right'}}:</label>
      <div class = "">
        <select [(ngModel)]="selectedRight" (change)="selectedRightChanged()" (focus)="shortenOptions()" style="width: 100%">
          <option disabled selected >Choose Right</option>
          <option *ngFor="let optionRight of selectedApplicationRightsCategory?.rights" [ngValue]="optionRight">{{optionRight.name}}</option>
        </select>

      </div>
    </div>
    <div class="form-group c-label col-md-1 app_select">
      <button type="button" class="btn-arrow-right" (click)="rightArrowClick()">
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
    <div class="col-md-2 mt-2 mb-2 push-right">
      <div class="form-group" style="margin-top: 12px;">
        <button class="btn-green-costom" (click)="clickSave()" [disabled]="!saveButtonEnabled">{{'' | i18n: 'save_single_rights'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="row table_costom">
  <div class="container-costom">
    <div class="row">
      <div class="col-md-12 col-lg-12 mb-4" >
        <div class="panel-body">
          <div class="col-lg-12 panel_heading">
            <div *ngIf="showApplicationAccordion">
              <ag-grid-angular
                style="width: 100%; height: 1200px"
                class="ag-theme-alpine"
                rowHeight="35"
                [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
                [columnDefs]="columns"
                [pagination]="true"
                [suppressDragLeaveHidesColumns]="true"
                [paginationPageSize]=paginationSize
                [rowData]="tableData"
                [rowSelection]="rowSelection"
                (rowValueChanged)="rowEvents.onRowValueChanged($event)"
                (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
                [editType]="editType"
                [rowGroupPanelShow]="rowGroupPanelShow"
                [defaultColDef]="defaultColDef"
                (firstDataRendered)="onFirstDataRendered($event)"
                (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

