import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import BmwPerson from 'src/app/shared/model/user/bmw-person';

@Injectable({
  providedIn: 'root'
})
export class LookupHttpService {
  private http: AraHttpService;

  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getUser(qNumber: string): Observable<BmwPerson> {
    return this.http.doGet(environment.lookup_api_url + encodeURIComponent(qNumber));
  }

  public getAll(qNumbers: string[]): Observable<BmwPerson[]> {
    return this.http.doPost(environment.lookup_api_url + 'all', qNumbers);
  }

  public getDeptHeadForUserQNumber(qNumber: string): Observable<BmwPerson> {
    return this.http.doGet(environment.lookup_api_url + 'dep_head/' + qNumber);
  }

  /*  public approveRequest(request: Request): Observable<void> {
      return this.http.doPost(environment.approver_api_url + 'approve', request);
    }

    public rejectRequest(request: Request): Observable<void> {
      return this.http.doPost(environment.approver_api_url + 'reject', request);
    }

   */
}
