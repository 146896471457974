import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compliance-approver',
  templateUrl: './compliance-approver.component.html',
  styleUrls: ['./compliance-approver.component.scss']
})
export class ComplianceApproverComponent implements OnInit {
  auth = true;
  constructor() { }

  ngOnInit() {
  }

}
