import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compliance-dashboard',
  templateUrl: './compliance-dashboard.component.html',
  styleUrls: ['./compliance-dashboard.component.scss']
})
export class ComplianceDashboardComponent implements OnInit {
  auth = true;
  constructor() { }

  ngOnInit(): void {
  }
  onModalClose($event: any) {

  }
}
