import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {Component, Renderer2, ElementRef} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-grant-button-renderer',
  template: `
    <span :class="missionSpan" *ngIf="!params.data.declined"   (click)="buttonClicked()" >
          <svg *ngIf="!hideGranted" [style.fill]="inconColor" height="14px" width="14px" version="1.1" [id]="grantIconId" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 490 490" xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> <polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528 ">
            </polygon> </g>
          </svg>
        </span>
  `,
  styles: [
    'img { width: 20px; height: auto; } span {display: flex; height: 100%; justify-content: center; align-items: center} ',
  ],
})
export class GrantButtonRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public inconColor: string = '#000000';
  public grantIconId: string;
  public hideGranted = false;
  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.grantIconId = `grant-icon-${params.rowIndex}`;

    if ( this.params.data.status == 'Wird entfernt' || this.params.data.status == 'In removal' || this.params.data.status == 'Granted' ||
      this.params.data.status == 'Vergeben' || this.params.data.status == 'Active' || this.params.data.status == 'Aktiv' || this.params.data.status == 'Expiring soon' || this.params.data.status == 'Läuft bald aus') {
      this.hideGranted = true;
    } else {
      this.hideGranted = false;
    }
  }
  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
  buttonClicked() {
    this.inconColor = 'green';
    const grantIcon = document.getElementById(`grant-icon-${this.params.rowIndex}`);
    if (this.params.data.granted) {
      this.renderer.setStyle(grantIcon, 'fill', '#000000');
    } else {
      this.renderer.setStyle(grantIcon, 'fill', 'green');
    }
    this.params.context.componentParent.grantSelected(this.params.data, true);
  }
}
