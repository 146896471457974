import {Component, OnInit} from '@angular/core';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import Region from 'src/app/shared/model/requests/region';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import RegionPropsMinified from '../../../shared/model/requests/region-props-min';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
import Application from '../../../shared/model/applications/application';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements OnInit {
  auth = true;
  showNewRegionDiv = false;
  showDisabled = false;
  showActive = true;
  isDisabled = true;
  newRegion = new Region();
  selectedRegion: Region;
  regions: RegionPropsMinified[];
  showUpdateRegion = new Map<number, boolean>();
  columns = [];
  updateModalIsOpen = false;
  modalIsOpen = false;
  editMode = false;
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<RegionPropsMinified>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedRegion = event.data;
      this.editMode = true;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedRegion = event.data;
      this.editMode = true;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedRegion = null;
      this.editMode = false;
    }
  };
  constructor(private regionHttpService: RegionHttpService, private sweetAlertService: SweetAlertService,
              private i18nProvider: I18nProvider) {
  }

  ngOnInit() {
    this.regionHttpService.getRegionsPropsMinified(false).subscribe(successResponse => {
        this.getColumns();
        this.regions = successResponse;
        this.regions.forEach(region =>
        {
          region.status = this.i18nProvider.getDescription('active');
          if (!region.active){
            region.status = this.i18nProvider.getDescription('inactive');
          }
        });
        if (this.regions) {
          this.regions.sort((a, b) => a.name.localeCompare(b.name));
        }
      },
      error => this.sweetAlertService.showComError('Error fetching regions: ' + JSON.stringify(error)));
  }

  addRegion() {
    this.newRegion.active = true;
    this.newRegion.status = this.i18nProvider.getDescription('active');
    this.regionHttpService.createRegion(this.newRegion).subscribe((successResponse) => {
      this.regions.push(this.newRegion);
      this.gridApi.setRowData(this.regions);
      this.newRegion = new Region();
      this.modalIsOpen = false;
      this.showNewRegionDiv = false;
      this.ngOnInit();
    });
  }
  changeStatus(value: boolean){
    this.selectedRegion.active = value;
    this.updateRegion(this.selectedRegion);
  }
  updateRegion(region: Region) {
    this.regionHttpService.updateRegion(region).subscribe(() => {
      this.showUpdateRegion.set(region.id, false);
      this.updateModalIsOpen = false;
      this.regions.filter(reg => reg.id !== region.id);
      this.gridApi.setRowData(this.regions);
    }, (error) => {
      this.sweetAlertService.showComError('Error Updating Region: ' + JSON.stringify(error));
    });
  }
  getColumns(){
    this.columns = [
      {
        field: 'name',
        headerName: this.i18nProvider.getDescription('region_name'),
        filter: true,
        sortable: true,
        width: 760
      },
      {
        field: 'shortCode',
        headerName: this.i18nProvider.getDescription('region_code'),
        filter: true,
        sortable: true,
      },
      {
        field: 'status',
        headerName: this.i18nProvider.getDescription('status'),
        filter: true,
        sortable: true,
        width: 240,
        enableRowGroup: true,
        cellClass: (params) => {
          return params.data?.active ? 'active' : 'inactive';
        }
      }];
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<RegionPropsMinified>) {
    this.gridApi = params.api;
  }
}
