import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {FooterComponent} from './components/navigation/footer/footer.component';
import {SidebarComponent} from './components/navigation/sidebar/sidebar.component';
import {LoginComponent} from './pages/login/login.component';
import {BusinessCategoryComponent} from './pages/master-admin/business-category/business-category.component';
import {BusinessRulesComponent} from './pages/master-admin/business-rules/business-rules.component';
import {ApplicationsComponent} from './pages/master-admin/applications/applications.component';
import {RegionsComponent} from './pages/master-admin/regions/regions.component';
import {RegionsAdminsComponent} from './pages/master-admin/regions-admins/regions-admins.component';
import {ApplicationRightsComponent} from './pages/master-admin/application-rights/application-rights.component';
import {ApplicationRightsCategoryComponent} from './pages/master-admin/application-rights-category/application-rights-category.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegionsApplicationsComponent} from './pages/region-admin/regions-applications/regions-applications.component';
import {SelectRegionApplicationsComponent} from './pages/region-admin/regions-applications/select-region-applications/select-region-applications.component';
import {RightsManagerComponent} from './pages/region-admin/rights-manager/rights-manager.component';
import {SpecialRightsManagerComponent} from './pages/region-admin/special-rights-manager/special-rights-manager.component';
import {SpecialRightsApproverComponent} from './pages/region-admin/special-rights-approver/special-rights-approver.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BusinessRulesFilterPipe} from './pages/master-admin/business-rules/business-rules-filter-pipe';
import {BusinessRuleRightComponent} from './pages/master-admin/business-rule-right/business-rule-right.component';
import {ComplianceManagerComponent} from './pages/master-admin/compliance-manager/compliance-manager.component';
import {ComplianceApproverComponent} from './pages/master-admin/compliance-approver/compliance-approver.component';
import {ViolationsComponent} from './pages/compliance/violations/violations.component';
import {ApproverComponent} from './pages/compliance/approver/approver.component';
import {CookieService} from 'ngx-cookie-service';
import {LocationsComponent} from './pages/region-admin/locations/locations.component';
import {JwtInterceptor} from './service/auth/jwt.interceptor';
import {ViolationsApproverInfoComponent} from './pages/compliance/violations-approver-info/violations-approver-info.component';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {SingleRightsComponent} from './pages/master-admin/single-rights/single-rights.component';
import {RegionBusinessRuleComponent} from './pages/region-admin/region-business-rule/region-business-rule.component';
import {DsAccordionModule, DsBadgeModule, DsToggleButtonModule, DsTooltipDirectiveModule, DsTreeModule} from '@bmw-ds/components';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-community';
import {I18nPipe} from './service/translations/i18n.pipe';
import {I18nProvider, i18nProviderFactory} from './service/translations/i18n.service';
import {DsButtonModule, DsIconModule, DsImprintModule, DsTableModule, DsToastModule, DsBannerModule} from '@bmw-ds/components';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/navigation/header/header.component';
import { DsRadioGroupModule } from '@bmw-ds/components';
import { DsTabsModule } from '@bmw-ds/components';
import { DsModalModule } from '@bmw-ds/components';
import { DsFormsModule } from '@bmw-ds/components';
import { DsNavigationBarModule } from '@bmw-ds/components';
import { DsHeaderModule } from '@bmw-ds/components';
import { DsBoxModule } from '@bmw-ds/components';
import { DsMenuModule } from '@bmw-ds/components';
import {LoginRedirectGuard} from './shared/guards/redirect-guard.guard';
import { ApproverCurrentRequestComponent } from './pages/approver/approver-current-request/approver-current-request.component';
import { ApproverDashboardComponent } from './pages/approver/approver-dashboard/approver-dashboard.component';
import { DsSwitchModule } from '@bmw-ds/components';
import { ApproverCompletedRequestComponent } from './pages/approver/approver-completed-request/approver-completed-request.component';
import { ApproverDeputyComponent } from './pages/approver/approver-deputy/approver-deputy.component';
import { ComplianceCurrentRequestComponent } from './pages/compliance/compliance-current-request/compliance-current-request.component';
import { ComplianceDashboardComponent } from './pages/compliance/compliance-dashboard/compliance-dashboard.component';
import { RightsManagerCurrentComponent } from './pages/rights-manager/rights-manager-current/rights-manager-current.component';
import { RightsManagerRequestDetailsComponent } from './pages/rights-manager/rights-manager-request-details/rights-manager-request-details.component';
import {DatePipe, registerLocaleData, CommonModule} from '@angular/common';
import {ApproverCompletedDashboardComponent} from './pages/approver/approver-completed-dashboard/approver-completed-dashboard.component';
import { RightsManagerCompletedComponent } from './pages/rights-manager/rights-manager-completed/rights-manager-completed.component';
import { RightsManagerCompletedDetailsComponent } from './pages/rights-manager/rights-manager-completed-details/rights-manager-completed-details.component';
import { ArchiveDashboardComponent } from './pages/archive/archive-dashboard/archive-dashboard.component';
import { ArchiveDetailsComponent } from './pages/archive/archive-details/archive-details.component';
import { RequestPipe } from './shared/filters/request.pipe';
import { RequestDetailsComponent } from './components/popup/request-details/request-details.component';
import { ApplicationsPipe } from './shared/filters/applications.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { MyArchiveComponent } from './pages/archive/my-archive/my-archive.component';
import { GiveBackUserAssignmentsComponent } from './components/popup/give-back-user-assignments/give-back-user-assignments.component';
import { RegionAdminPipe } from './shared/filters/region-admin.pipe';
import { RightsManagerPipe } from './shared/filters/rights-manager.pipe';
import { RequestInfoComponent } from './components/popup/request-info/request-info.component';
import { ConfirmationComponent } from './components/popup/confirmation/confirmation.component';
import { NewBusinessRuleComponent } from './components/requester/new-business-rule/new-business-rule.component';
import { NewSingleRightsComponent } from './components/requester/new-single-rights/new-single-rights.component';
import { EditRequestComponent } from './components/requester/edit-request/edit-request.component';
import { UpdateRightsComponent } from './components/rights-manager/update-rights/update-rights.component';
import { RemoveRightsComponent } from './components/rights-manager/remove-rights/remove-rights.component';
import { GrantRightsComponent } from './components/rights-manager/grant-rights/grant-rights.component';
import { ViewRightsComponent } from './components/rights-manager/view-rights/view-rights.component';
import { RightsRequestDisplayPipe } from './shared/filters/rights-request-display.pipe';
import {RequestDetailsNewComponent} from './components/popup/request-details-new/request-details-new.component';
import { MessageComponent } from './components/popup/message/message.component';
import { DepartmentsComponent } from './pages/master-admin/departments/departments.component';
import { SingleRightsApplicationsComponent } from './pages/master-admin/single-rights-applications/single-rights-applications.component';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {UsersComponent} from './pages/master-admin/users/users.component';
import {ApproverMyTeamComponent} from './pages/approver/approver-my-team/approver-my-team.component';
import {TranslationsComponent} from './pages/master-admin/translations/translations.component';
import {ApplicationRightsConfigComponent} from './components/popup/application-rghts-config/application-rights-config.component';
import {DeclineButtonRendererComponent} from './pages/approver/approver-dashboard/decline-button-renderer.component';
import {GrantButtonRendererComponent} from  './pages/approver/approver-dashboard/grant-button-renderer.component';
import {OrderByPipe} from './components/requester/new-single-rights/order-by.pipe';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidebarComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    BusinessCategoryComponent,
    BusinessRulesComponent,
    ApplicationsComponent,
    RegionsComponent,
    RegionsAdminsComponent,
    ApplicationRightsComponent,
    ApplicationRightsCategoryComponent,
    RegionsApplicationsComponent,
    SelectRegionApplicationsComponent,
    RightsManagerComponent,
    SpecialRightsManagerComponent,
    SpecialRightsApproverComponent,
    BusinessRulesFilterPipe,
    BusinessRuleRightComponent,
    ComplianceManagerComponent,
    ComplianceApproverComponent,
    ComplianceManagerComponent,
    ComplianceApproverComponent,
    ViolationsComponent,
    ApproverComponent,
    LocationsComponent,
    ViolationsApproverInfoComponent,
    SingleRightsComponent,
    RegionBusinessRuleComponent,
    // RegionBusinessRuleRightsComponent,
    I18nPipe,
    HeaderComponent,
    ApproverCurrentRequestComponent,
    ApproverDashboardComponent,
    ApproverCompletedRequestComponent,
    ApproverDeputyComponent,
    ApproverMyTeamComponent,
    ComplianceCurrentRequestComponent,
    ComplianceDashboardComponent,
    RightsManagerCurrentComponent,
    RightsManagerRequestDetailsComponent,
    ApproverCompletedDashboardComponent,
    RightsManagerCompletedComponent,
    RightsManagerCompletedDetailsComponent,
    ArchiveDashboardComponent,
    ArchiveDetailsComponent,
    RequestPipe,
    RequestDetailsComponent,
    ApplicationsPipe,
    MyArchiveComponent,
    GiveBackUserAssignmentsComponent,
    RegionAdminPipe,
    RightsManagerPipe,
    RequestInfoComponent,
    ConfirmationComponent,
    NewBusinessRuleComponent,
    NewSingleRightsComponent,
    EditRequestComponent,
    UpdateRightsComponent,
    RemoveRightsComponent,
    GrantRightsComponent,
    ViewRightsComponent,
    RightsRequestDisplayPipe,
    RequestDetailsNewComponent,
    MessageComponent,
    DepartmentsComponent,
    SingleRightsApplicationsComponent,
    UsersComponent,
    TranslationsComponent,
    ApplicationRightsConfigComponent,
    DeclineButtonRendererComponent,
    GrantButtonRendererComponent,
    OrderByPipe
  ],
  imports: [
    BrowserAnimationsModule,
    DsButtonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DsFormsModule,
    DsBadgeModule,
    DsRadioGroupModule,
    BrowserAnimationsModule,
    DsNavigationBarModule,
    DsBoxModule,
    DsButtonModule,
    DsTableModule,
    HttpClientModule,
    DsTabsModule,
    DsModalModule,
    DsSwitchModule,
    DsMenuModule,
    DsIconModule,
    NgHttpLoaderModule.forRoot(),
    ReactiveFormsModule,
    OverlayModule,
    DsToastModule,
    DsHeaderModule,
    DsImprintModule.forRoot({
      phone: '123498765',
      electronicContact: 'qwerty@bmwgroup.com',
    }),
    AgGridModule,
    DsAccordionModule,
    DsTreeModule,
    DsTooltipDirectiveModule,
    DsToggleButtonModule,
    CommonModule,
    DsBannerModule
  ],
  providers: [CookieService, I18nProvider, LoginRedirectGuard, DatePipe,
    {provide: APP_INITIALIZER, useFactory: i18nProviderFactory, deps: [I18nProvider], multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
