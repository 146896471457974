import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {SelectListOption} from '@bmw-ds/components';
import UserDisplay from '../../../shared/model/user/user-display';
import {UserHttpService} from '../../../service/http/user-http.service';
import User from '../../../shared/model/user/user';
import {SweetAlertService} from '../../../service/feedback/SweetAlertService.service';
import GeneratedRequest from '../../../shared/model/requests/generated-request.interface';
import RequestsForRightsManagerDto from '../../../shared/model/requests/requests-for-rights-manager-dto';
import {NavigationExtras, Router} from '@angular/router';
import {RequestHttpService} from '../../../service/http/request-http.service';

@Component({
  selector: 'app-approver-my-team',
  templateUrl: './approver-my-team.component.html',
  styleUrls: ['./approver-my-team.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ApproverMyTeamComponent implements OnInit {
  users: UserDisplay[];
  newTeamMemberQNumber: string;
  columns = this.getColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<UserDisplay>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.openTeamMemberDashboard(event.data),
  };
  newTeamMemberModalOpen: boolean;
  isDeputy = false;
  constructor(private userHttpService: UserHttpService, private userService: UserHttpService, private sweetAlertService: SweetAlertService,
              public router: Router, private i18nProvider: I18nProvider) { }

  ngOnInit(): void {
    this.paginationSize = this.setPaginationSize();
    this.getAllApproverUsers();
    if (localStorage.getItem('isDeputy') == 'null'){
     this.isDeputy =  true;
    }
  }

  getAllApproverUsers(){
    this.userHttpService.getAllApproverUsers().subscribe(value => {
        this.users = value;
      });
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent<UserDisplay>) {
    this.gridApi = params.api;
  }

  getColumns(){
    return [
        {field: 'fullName', headerName: this.i18nProvider.getDescription('surname_name'), width: 300, filter: true, sortable: true},
        {field: 'qnumber', headerName: this.i18nProvider.getDescription('q_number'), width: 200, filter: true, sortable: true},
        {field: 'departmentCode', headerName: this.i18nProvider.getDescription('department_code'), width: 200, filter: true, sortable: true},
      ];
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_users_master_admin_pagination')) {
      return localStorage.getItem('ara_users_master_admin_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_users_master_admin_pagination', size);
    this.paginationSize = size;
  }

  newTeamMemberClicked() {
    this.newTeamMemberModalOpen = true;
  }

  closeNewTeamMemberModal() {
    this.newTeamMemberModalOpen = false;
  }

  newTeamMemberSaveClicked() {
    this.userService.addNewTeamMember(this.newTeamMemberQNumber).subscribe((newTeamMember) => {
      this.users.push(newTeamMember);
      this.gridApi.setRowData(this.users);
      }, (err) => {
        this.sweetAlertService.showComError(err.error);
      }
    );
    this.newTeamMemberModalOpen = false;
  }

  openTeamMemberDashboard(userDisplay: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        qNumber: userDisplay.qnumber
      },
    };
    this.router.navigate(['/dashboard'], navigationExtras);
  }

  protected readonly localStorage = localStorage;
}
