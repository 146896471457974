import Application from '../applications/application';
import BusinessRule from '../business-rules/business-rule';
import BusinessCategoryMinified from '../business-rules/business-category-min';

export default class Region {
  id: number;
  name: string;
  active: boolean;
  shortCode: string;
  createdBy: string;
  modifiedBy: string;
  applications: Application[];
  businessRules: any[];
  businessCategories: BusinessCategoryMinified[];
  status?: string;
}
