import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import Application from 'src/app/shared/model/applications/application';
import ApplicationParameter from 'src/app/shared/model/applications/application-parameter';
import {HttpParams} from '@angular/common/http';
import ApplicationIdsDTO from 'src/app/shared/model/applications/application-ids-dto';
import Region from 'src/app/shared/model/requests/region';
import ApplicationMinifiedProps from '../../shared/model/applications/application-props-min';
import RightLinkedRights from '../../shared/model/rights/right-linked-rights';
import LinkedRight from '../../shared/model/rights/linked-right';
import ApplicationRightsCategory from '../../shared/model/applications/application-rights-category';
import ApplicationMinified from '../../shared/model/applications/application-min';

@Injectable({
  providedIn: 'root'
})
export class ApplicationHttpService {
  private http: AraHttpService;


  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getMasterApplications(): Observable<Application[]> {
    return this.http.doGet(environment.application_api_url);
  }

  public getMasterMinifiedPropertiesApplications(): Observable<ApplicationMinifiedProps[]> {
    return this.http.doGet(environment.application_min_api_url);
  }

  public getMultipleApplications(applicationIds: number[]): Observable<Application[]> {
    const applicationIdsObj = new ApplicationIdsDTO();
    applicationIdsObj.applicationIds = applicationIds;
    return this.http.doPost(environment.application_api_url + 'multiple', applicationIdsObj);
  }

  public getMasterApplicationsWithRights(): Observable<Application[]> {
    return this.http.doGet(environment.application_api_url + 'rights');
  }

  public getMinifiedMasterApplicationsWithRights(): Observable<Application[]> {
    return this.http.doGet(environment.application_api_url + 'minified-rights');
  }

  public getApplicationsPerRegion(region: Region): Observable<Application[]> {
    return this.http.doGet(environment.application_api_url + 'region/' + region.id);
  }

  public getApplicationsMinPerRegion(region: Region): Observable<ApplicationMinified[]> {
    return this.http.doGet(environment.application_api_url + 'region-minified/' + region.id);
  }

  public updateApplication(application: Application): Observable<Application> {
    return this.http.doPut(environment.application_api_url, application);
  }

  public updateApplicationCategoryName(applicationRightsCategory: ApplicationRightsCategory): Observable<void> {
    return this.http.doPut(environment.application_api_url + 'update-name', applicationRightsCategory);
  }

  public grantApplication(applications: Application): Observable<Application> {
    return this.http.doPut(environment.application_api_url + 'grant', applications);
  }

  public update(applications: Application): Observable<Application> {
    return this.http.doPut(environment.application_api_url + 'update', applications);
  }

  public removeApplication(applications: Application): Observable<Application> {
    return this.http.doPut(environment.application_api_url + 'remove', applications);
  }

  public createApplication(application: Application): Observable<Application> {
    return this.http.doPost(environment.application_api_url, application);
  }

  public deleteApplication(applicationId: number): Observable<string> {
    return this.http.doDelete(environment.application_api_url + encodeURIComponent(applicationId ) + '/');
  }

  public getApplication(applicationId: number): Observable<Application> {
    return this.http.doGet(environment.application_api_url + applicationId + '/');
  }

  public async getLinkedApplicationRights(rightId: number): Promise<RightLinkedRights[]> {
    const response = await this.http.doGet(environment.application_api_url + 'linked-application-rights/' + rightId).toPromise();
    return response as RightLinkedRights[];
  }

  public addLinkedApplicationRight(linkedRight: LinkedRight): Observable<void> {
    return this.http.doPost(environment.application_api_url + 'link-application-right/', linkedRight);
  }

  public removeLinkedApplicationRight(linkedRight: LinkedRight): Observable<void> {
    return this.http.doPut(environment.application_api_url + 'link-application-right-remove/', linkedRight);
  }
}
