<div class = "row"  *ngIf="selectedRequest">
  <div class = "col-4 mt-3 mb-2 p-0">
    <table class="table">
      <thead>
      <tr>
        <th>Anforderer
        </th>
        <th>{{'' | i18n: 'q_number'}}
        </th>
        <th>{{'' | i18n: 'default_location'}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="rights-padding">{{this.requester?.givenName}} {{this.requester?.sn}}<br>
          {{this.requester?.departmentNumber}}<br>
          {{this.requester?.mail}}
        </td>
        <td>{{this.selectedRequest?.requestedFor?.qnumber}}</td>
        <td>
          <div *ngFor="let location of selectedRequest?.requestedFor?.locations">
            <div *ngIf="location?.defaultLocation">
              <span>{{location.startDate | date : 'dd-MM-YYYY'}}</span>
              <br>
              <span>{{location?.name}}</span>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class = "col-8 mt-3 mb-2 pr-0">
    <table class="table">
      <thead>
      <tr>
        <th colspan="4">{{'' | i18n: 'applications'}}
        </th>
        <th colspan="4">
          <a class="nms-download" href="../../../../assets/email-templates/NMS.msg" type="button">
            <ds-icon icon="mail" size="md"></ds-icon>
          </a>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr>
        <td *ngFor="let application of selectedRequest.applications">
          <span class="right-check" [ngClass]="application.active ? 'approved' : 'rejected' ">{{application.name}}
            <ds-switch class ="ml-3" [(ngModel)]="application.active"  disabled>
          </ds-switch></span>
          <span *ngFor="let admin of application?.rightsManagerHistoryItem" >
          <ds-badge [tone]="getTone(admin)" label="{{admin.employeeId}} -    {{admin.date | date:'short'}}"></ds-badge>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class = "row" *ngIf="selectedRequest">
  <div class="col-12 d-flex justify-content-between mb-4">
    <ds-form-field>
      <label ds-label>{{'' | i18n: 'choose_your_application'}}</label>
      <ds-select
        id="applicationStatus"
        [labellingConfig]="{ placeholder: '' | i18n: 'select' }"
        [options]="applicationsOptions"
        [formControl]="selectedApplicationId"
        [showClearAll] = "true" required></ds-select>
    </ds-form-field>

    <button ds-button class="cstm-btn"  id="grant" (click)="submitRequest()" [hidden]="!displayGrantRights()">{{'' | i18n: 'submit_request'}}</button>
    <button ds-button class="cstm-btn" id="update" (click)="submitRequest()" [hidden]="!displayUpdateRights()">{{'' | i18n: 'submit_request'}}</button>
    <button ds-button class="cstm-btn" id="remove" (click)="submitRequest()" [hidden]="!displayRemoveRights()">{{'' | i18n: 'submit_request'}}</button>
  </div>
</div>
<div *ngIf="selectedApplicationId?.value">
  <table class="table mb-5" >
    <thead>
    <tr>
      <th scope="col">{{'' | i18n: 'location'}}
      </th>
      <th scope="col">IPF
      </th>
      <th scope="col">Plant
      </th>
      <th scope="col">BuNo
      </th>
      <th scope="col">BP-ID
      </th>
      <th scope="col">Buno/Outlet</th>
      <th scope="col">VKO</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let loc of selectedRequest?.locations">
      <td>{{loc.name}}</td>
      <td>{{loc.paramOne}}</td>
      <td>{{loc.paramTwo}}</td>
      <td>{{loc.paramThree}}</td>
      <td>{{loc.paramFour}}</td>
      <td>{{loc.paramFive}}</td>
      <td>{{loc.paramSix}}</td>
    </tr>
    </tbody>
  </table>
  <app-grant-rights [selectedApplication]="selectedApplication"
                     [selectedRequest]="selectedRequest"
                    (hideComponent)="onHideComponent($event)"
                     *ngIf="canGrantRights()"
                    (grantedRequest)="getSelectedRequest($event)" ></app-grant-rights>

  <app-update-rights [selectedApplication]="selectedApplication"
                     [selectedRequest]="selectedRequest"
                     (hideComponent)="onHideComponent($event)"
                     *ngIf="canUpdateRights()"
                     (updatedRequest)="getSelectedRequest($event)"></app-update-rights>

  <app-remove-rights [selectedApplication]="selectedApplication"
                     [selectedRequest]="selectedRequest"
                     (hideComponent)="onHideComponent($event)"
                     *ngIf="canRemoveRights()"
                     (removedRequest)="getSelectedRequest($event)"></app-remove-rights>

  <app-view-rights [selectedApplication]="selectedApplication"
                     [selectedRequest]="selectedRequest"
                     *ngIf="!showActions()"></app-view-rights>


</div>
