import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChange
} from '@angular/core';
import GeneratedRequest from '../../../shared/model/requests/generated-request.interface';
import {FormControl, Validators} from '@angular/forms';
import Application from '../../../shared/model/applications/application';
import Right from '../../../shared/model/rights/right';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestHttpService} from '../../../service/http/request-http.service';
import {DateFormat} from '../../../shared/commons/dateFormat';
import {ApplicationHttpService} from '../../../service/http/application-http.service';
import {RightsManagerHttpService} from '../../../service/http/rights-manager-http.service';
import {RightHttpService} from '../../../service/http/right-http.service';
import {LookupHttpService} from '../../../service/http/lookup-http.service';
import {UserHttpService} from '../../../service/http/user-http.service';
import {SweetAlertService} from '../../../service/feedback/SweetAlertService.service';
import {RequestBuilderService} from '../../../service/helper-services/request-builder.service';
import {ApplicationsService} from '../../../service/helper-services/applications.service';
import {DialogService} from '../../../service/modal/dialog.service';
import {RequestStatusUtilsService} from '../../../service/http/request-status-utils.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../popup/confirmation/confirmation.component';
import {RequestStatuses} from '../../../shared/enums/request-statuses';
import Location from '../../../shared/model/requests/location';
import {NewRequestService} from '../../../service/helper-services/new-request.service';
import {I18nProvider} from '../../../service/translations/i18n.service';
import Comment from '../../../shared/model/requests/comment';
import {MessageComponent} from '../../popup/message/message.component';
import {formatDate} from '@angular/common';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  IsRowSelectable,
  RowSelectedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import LocationMinified from '../../../shared/model/requests/location-min';

@Component({
  selector: 'app-remove-rights',
  templateUrl: './remove-rights.component.html',
  styleUrls: ['./remove-rights.component.css']
})
export class RemoveRightsComponent implements OnInit, OnChanges {
  @Input() selectedRequest: GeneratedRequest;
  @Input() selectedApplication: Application;
  @Output() hideComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removedRequest: EventEmitter<GeneratedRequest> = new EventEmitter<GeneratedRequest>();

  selectedApplicationRights: Right[];
  comment = new FormControl('', [Validators.required]);
  applicationMap: Map<number, Application> = new Map<number, Application>();
  qNumber: string;
  gridApi!: GridApi <Right>;

  columns = [];
  rowSelection: 'single' | 'multiple' = 'multiple';
  editMode = false;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };

  public defaultColDef: ColDef = {
    resizable: true,
    filter: true, sortable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
    const nodesToSelect: IRowNode[] = [];
    params.api.forEachNode((node) => {
      if (node.data && node.data.active) {
        node.setSelected(true, false, true, 'api');
      }
    });
  }

  isRowSelectable: IsRowSelectable = (params: IRowNode<any>) => {
    if (params.data && params.data.active) {
      params.setSelected(true, false, true, 'api');
    }
    return !!params.data && params.data.selectedApplicationActive === true;
  };

  rowEvents: GridOptions = {
    onRowSelected(event: RowSelectedEvent<any>) {
      if (event.source && event.source != 'api') {
        event.data.active = !event.data.active;
        if (event.data.active) {
          event.data.activeDisplay = 'Aktiv';
          event.columnApi.getColumn('activeDisplay').getColDef().cellClass = 'active';
        } else {
          event.data.activeDisplay = 'Inaktiv';
          event.columnApi.getColumn('activeDisplay').getColDef().cellClass = 'inactive';
        }
        let refreshParams = {force: true, rowNodes: [event.node]};
        event.api.refreshCells(refreshParams);
      }
    },
  };

  getRightStatusStyle(active: boolean): string{
    return active ? 'active' : 'inactive';
  }

  getColumns() {
    this.columns = [
      {field: 'appCategoryDisplay', headerName: 'App Category'},
      {field: 'name', headerName: 'App Right'},
      {
        field: 'activeDisplay', headerName: this.i18nProvider.getDescription('active'), filter: true, sortable: true, width: 210,
        cellClass: params => {
          return this.getRightStatusStyle(params.data.active);
        },
      },
      {field: 'req_start_date', headerName: this.i18nProvider.getDescription('start_date') , cellRenderer: (data) => {
          return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
        }},
      {field: 'req_end_date',  headerName: this.i18nProvider.getDescription('end_date'), cellRenderer: (data) => {
          return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
        }},
      {field: 'rule_name',   headerName: this.i18nProvider.getDescription('type')},
      {field: 'locations', headerName: this.i18nProvider.getDescription('location')},
      {field: 'active', headerName: 'Done (Select All)', headerCheckboxSelection: true, showDisabledCheckboxes: true, checkboxSelection: true, cellClass: 'text-hide'},
    ];
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }
  onGridReady(params: GridReadyEvent<Right>) {
    this.gridApi = params.api;
  }


  constructor(private activatedRoute: ActivatedRoute, private requestHttpService: RequestHttpService,
              private dateFormat: DateFormat, private applicationHttpService: ApplicationHttpService,
              private rightsManagerHttpService: RightsManagerHttpService, private rightServices: RightHttpService,
              public router: Router, private lookupHttpService: LookupHttpService, private userHttpService: UserHttpService,
              private sweetAlertService: SweetAlertService, private requestBuilderService: RequestBuilderService,
              private el: ElementRef, private renderer: Renderer2, private applications: ApplicationsService,
              private dialog: DialogService, private requestStatusUtilsService: RequestStatusUtilsService,
              private newRequestService: NewRequestService, private i18nProvider: I18nProvider) {
    this.qNumber = this.router?.getCurrentNavigation()?.extras?.state?.qNumber;
  }

  ngOnInit(): void {
    this.getSelectedApplicationRights();
    this.getCompletedRights();
  }

  private populateApplicationsMap(request: GeneratedRequest) {
    request?.applications.forEach((application) => {
      this.applicationMap.set(application.id, application);
    });
  }

  canRemoveApplication(): boolean{
    return !this.selectedApplication?.active;
  }

  canRemoveRequest(): boolean{
    return !!this.selectedRequest?.applications.find(application => application.active);
  }

  isRightsCompleted(): boolean{
    const completedRights: Right[] = this.selectedApplicationRights.filter(rights => rights.active);
    return  completedRights.length === this.selectedApplicationRights.length;
  }

  getRightStatus(active: boolean): string{
    return active ? this.i18nProvider.getDescription('active') : this.i18nProvider.getDescription('inactive');
  }

  getRightApplicationCategoryName(right: Right): string {
    const application = this.getRightApplication(right);
    return application?.applicationRightsCategories?.find(aRC => aRC.id === right.applicationRightsCategoryId)?.name;
  }

  getRightApplication(right: Right): Application {
    if (this.applicationMap) {
      return this.applicationMap.get(right.applicationId);
    }
    return null;
  }

  removeRequest() {
    const data: Modal = {
      action : 'confirm',
      message: 'submit_request_message'
    };
    this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
      if (results){
        this.requestBuilderService.sanitizeRequest(this.selectedRequest).then((request) => {
          request.requestStatus = this.newRequestService.getStatus(RequestStatuses.REMOVED);
          request.active = false;
          this.requestHttpService.submitRequest(request).subscribe(() => {
            this.router.navigate(['rights-manager-current']);
          }, () => {
            this.router.navigate(['rights-manager-current']);
          });
        });
      }
    });
  }

  autoRemoveRequest() {
    this.requestBuilderService.sanitizeRequest(this.selectedRequest).then((request) => {
      request.requestStatus = this.newRequestService.getStatus(RequestStatuses.REMOVED);
      request.active = false;
      this.requestHttpService.submitRequest(request).subscribe(() => {
        this.selectedRequest = request;
      });
    });
  }


  removeRights(){
    this.rightServices.updateRights(this.selectedApplicationRights).subscribe(() => {
    });
  }
  removeApplication(){
    if (this.selectedApplicationRights.length === this.getInactiveRights().length) {
      this.saveApplication();
    }
    else {
      if (this.comment.valid) {
        this.saveApplication();
      }
      else {
        const data: Modal = {
          action : 'write_a_comment',
          message: 'comment_required'
        };
        this.dialog.open(MessageComponent, {data});
      }
    }
    if (!this.canRemoveRequest()) {
      this.autoRemoveRequest();
    }
  }

  getInactiveRights(): Right[]{
    return this.selectedApplicationRights.filter(right => !right.active);
  }

  saveApplication() {
    const updatedApplication = this.getUpdateApplication(this.selectedApplication);
    this.applicationHttpService.removeApplication(updatedApplication).subscribe((resp) => {
      this.selectedRequest.applications.forEach(application => {
        if (application.id === resp.id) {
          application.rightsManagerHistoryItem = resp.rightsManagerHistoryItem;
        }
      });
      this.hideComponent.emit(true);
      if (this.canRemoveApplication()) {
        this.removedRequest.emit(this.selectedRequest);
      }
    });
  }

  getUpdateApplication(selectedApplication: Application): Application{
    const application = selectedApplication;
    application.active = false;
    application.endDate = this.selectedRequest.endDate;
    application.commentDTOS = this.getComment();
    application.rightDTOS = this.selectedApplicationRights;

    return application;
  }

  getComment(): Comment[]{
    if (this.comment.valid){
      const comment: Comment = this.newRequestService.getComment(this.comment.value)[0];
      comment.requestId = this.selectedRequest.id;
      return [comment];
    }
    else {
      return [];
    }
  }


  getCompletedRights(){
    if (this.selectedApplicationRights.length > 0) {
      this.isRightsCompleted();
      this.canRemoveRequest();
      this.canRemoveApplication();
    }
  }

  getSelectedApplicationRights(){
    this.selectedApplicationRights = this.selectedRequest?.rights.filter( right => {
      return right.applicationId === this.selectedApplication.id;
    });

    this.selectedApplicationRights.forEach(right => {
      right.startDate = this.selectedRequest.startDate;
      right.endDate = this.selectedRequest.endDate;
      right.appCategoryDisplay = this.getRightApplicationCategoryName(right);
      right.activeDisplay = this.getRightStatus(right.active);
      right.req_end_date = formatDate(this.selectedRequest.endDate, 'yyyy/MM/dd', 'en-GB');
      right.req_start_date = formatDate(this.selectedRequest.startDate, 'yyyy/MM/dd', 'en-GB');
      right.rule_name = this.selectedRequest.rule;
      right.locations = this.setLocationsForDisplay(this.selectedRequest.locations);
      right.selectedApplicationActive = this.selectedApplication.active;
    });
    this.getColumns();
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (this.selectedRequest?.id){
      this.populateApplicationsMap(this.selectedRequest);
      this.getSelectedApplicationRights();
      this.getCompletedRights();
    }
  }

  onCompleteRights(event: boolean){
    this.selectedApplicationRights.forEach(right => right.active = event);
  }

  setLocationsForDisplay(locations: Location[] | LocationMinified[] | string[]): string {
    const locationToDisplay: string[] = [];
    locations.forEach(value => {
      locationToDisplay.push(value.name);
    });
    return locationToDisplay.join(', ');
  }
}

