import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {addDays, DsDatepickerConfiguration, minMaxDate, parseISO} from '@bmw-ds/components';
import {RequestHttpService} from '../../../service/http/request-http.service';
import {NewRequestService} from '../../../service/helper-services/new-request.service';
import {SweetAlertService} from '../../../service/feedback/SweetAlertService.service';
import {RequestActions} from '../../../shared/enums/actions';
import {RequestStatuses} from '../../../shared/enums/request-statuses';
import RequestsNew from '../../../shared/model/requests/requests-new';
import Location from '../../../shared/model/requests/location';
import {formatDate} from '@angular/common';
import User from '../../../shared/model/user/user';

@Component({
  selector: 'app-edit-request',
  templateUrl: './edit-request.component.html',
  styleUrls: ['./edit-request.component.css']
})
export class EditRequestComponent implements OnInit {

  @Input()  selectedRequest: RequestsNew;
  @Input()  action: string;
  @Input()  maxDateNumber: number;
  @Input()  isApprover: boolean;
  @Input()  requestUser: User;
  @Output() hideComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newRequestAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  comment: string;
  requestEndDate: Date;
  endDate: Date;
  endDateSelected: boolean = true;
  commentEntered: boolean = true;
  today: number = new Date().getDate() - 1;
  maxDateYear: number = new Date().getFullYear();
  minDateValidation: Date;
  maxDateValidation: Date;
  dateConfig: Partial<DsDatepickerConfiguration> = {};

  constructor(private requestService: RequestHttpService,
              private newRequestService: NewRequestService,
              private sweetAlertService: SweetAlertService) {
  }

  ngOnInit(): void {
    this.setLocations();
    this.setCommentsDate();

    if (this.maxDateNumber) {
      this.maxDateYear = this.maxDateYear + this.maxDateNumber;
      this.maxDateValidation = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      let formatter = new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      formatter.format(this.minDateValidation);
      formatter.format(this.maxDateValidation);
      this.dateConfig = {
        disabledDatesFactory: [minMaxDate({
          min: this.minDateValidation,
          max: this.maxDateValidation
        })],
        allowDirectInput: false,
        selectionMode: 'range'
      };
    }
  }


  saveRequest(){
    if (!this.endDate || !this.comment) {
      this.endDateSelected = !!this.endDate;
      this.commentEntered = !!this.comment;
      return;
    }

    if (this.action === RequestActions.REMOVE_REQUEST )
    {
      this.selectedRequest.requestStatus = this.newRequestService.getStatus(RequestStatuses.IN_REMOVAL);
    }
    else
    if (this.action === RequestActions.EDIT_REQUEST){
      this.selectedRequest.requestStatus = this.newRequestService.getStatus(RequestStatuses.CHANGES_MADE);
      this.selectedRequest.approverApproved = false;
      this.selectedRequest.change = true;
      this.selectedRequest.applications.forEach(application => {
        application.active = false;
      });
    }
    this.selectedRequest.applications.forEach(application => {
      application.endDate = new Date(application.endDate); });
    let auditComment = this.isApprover ? ' (Beantragt durch Führungskraft für Benutzer '
      + this.requestUser.name + ' ' + this.requestUser.surname + ')': '';
    this.comment = this.comment + (auditComment);
    this.selectedRequest.comments.push(this.newRequestService.getComment(this.comment)[0]);
    this.selectedRequest.startDate = this.setStartDate(this.selectedRequest.startDate);
    this.selectedRequest.endDate = this.endDate;
    this.updateRequest(this.selectedRequest);
  }


  updateRequest(saveRequestDto: any) {
    this.requestService.updateSelectedRequest(saveRequestDto).subscribe(() => {
      this.newRequestAdded.emit(true);
      this.onHideComponent();
    }, error => this.sweetAlertService.showError('Error.' + error.error));
  }

  onHideComponent(){
    this.hideComponent.emit(false);
  }

  setEndDate(endDate: any) {
    endDate = formatDate(endDate, 'yyyy-MM-dd', 'en-GB');
    this.endDate = addDays(parseISO(endDate, 1), 1);
  }

  setStartDate(startDate: any): any {
    startDate = formatDate(startDate, 'yyyy-MM-dd', 'en-GB');
    return addDays(parseISO(startDate, 1), 1);
  }

  setLocations() {
    this.selectedRequest.locations_display = [];
    this.selectedRequest.locations.forEach(value => {
      const loc = new Location();
      loc.name = value;
      loc.active = true;
      this.selectedRequest.locations_display.push(loc);
    });
    this.selectedRequest.locations = this.selectedRequest.locations_display;
  }

  setCommentsDate() {
    this.selectedRequest.comments.forEach(value => {
      value.date = this.setStartDate(value.date);
    });
  }

  isBusinessRuleRequest(): boolean {
    return this.selectedRequest.type_enum === 'business_rule';
  }
}
