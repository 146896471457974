<div class="container-costom p-0">
<div class = "row">
  <div class = "col-2 mb-2 mt-2 ml-c col-width">
    <ds-list-item icon="arrow_left" [iconSize]="lg" [isHoverable]="true" (click)="backToDashboard()"></ds-list-item>
  </div>
  <div class = "col-10 mb-2 mt-2 ml-c">
    <span class = "small-heading">Current request list ({{qNumber}})</span>
  </div>
</div>


<div class = "col-12 p-0">
  <div class="col-12 d-flex justify-content-end p-0 mb-2">
    <span class="push-left">
        <ds-list-item icon="refresh" [iconSize]="lg" [isHoverable]="true" (click)="refreshScreen()"></ds-list-item>
      </span>
    <span class="ag-theme-alpine">
      Page Size:
      <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize" id="page-size" class="pagination-size">
        <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
      </select>
    </span>
    <button class="green_b-tab" (click)="showRequestDetails(selectedRequest)" *ngIf="selectedRequest">{{'' | i18n: 'details'}}</button>
    <button class="green_b-tab mr-0" (click)="showApplicationsView(selectedRequest)" *ngIf="selectedRequest && isActiveRequest(selectedRequest)">{{'' | i18n: 'overview'}}</button>
  </div>
  <ag-grid-angular
    style="width: 100%; height: 550px"
    class="ag-theme-alpine"
    rowHeight="56"
    [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
    [multiSortKey]="ctrl"
    [columnDefs]="columns"
    [pagination]="true"
    [suppressDragLeaveHidesColumns]="true"
    [paginationPageSize]=paginationSize
    [rowData]="requests"
    [rowSelection]="rowSelection"
    [enableRangeSelection]="true"
    [copyHeadersToClipboard]="true"
    (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
    (cellClicked)="rowEvents.onRowClicked($event)"
    (paginationChanged)="rowEvents.onPaginationChanged($event)"
    (gridReady)="onGridReady($event)"
    [getRowId]="getRowId">
  </ag-grid-angular>
</div>
</div>
<app-request-info [selectedRequest]="selectedRequest" [requester]="requester" *ngIf="showRequestOverview"></app-request-info>
