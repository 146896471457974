<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class = "light-orange">Select Region applications</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
        <span class="h2-header">Region 1</span>
        <button type=""  [routerLink]="['/regions-applications']" class="btn-warning">Update applications<em class="fa fa-angle-right"></em></button>
      </div>
      </div>
    </div>
  </div>
<div class="row table_costom" style="background-color: white">
  <div class="container-costom ">
    <div class="col-md-12">
      <table class="table ">
        <tbody>
        <tr>
          <td>{{'' | i18n: 'application'}} 1</td>
          <td class="select">
            <label class="container-checkbox">
              <i class="fa fa-check" ></i>
            </label>
          </td>
        </tr>
        <tr>
          <td>{{'' | i18n: 'application'}} 2</td>
          <td class="select"><label class="container-checkbox">
            <input type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label></td>
        </tr>
        <tr>
          <td>{{'' | i18n: 'application'}} 3</td>
          <td class="select"><label class="container-checkbox">
            <input type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
