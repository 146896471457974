<div class="container-costom p-0" xmlns="http://www.w3.org/1999/html">
  <div class="col-12 p-0">
    <div class="row">
      <div class="col-12 mt-5">
        <h1 class="h1-heading">
          <span class="blue">{{'' | i18n: 'manage_regional_admins'}}</span>
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0 mt-2 mb-2 push-right">
        <div class="form-group">
          <button class="btn-blue-costom " [ds-modal-trigger-for]="tpl" (modalDismiss)="refreshTable()" (click)="resetUser()" [(isModalOpen)]="modalIsOpen">{{'' | i18n: 'new_regions_admin'}}<em class="fa fa-angle-right"></em></button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end mt-2 p-0" *ngIf="selectedAdmin">
      <button class="green_b-tab p-0 mr-0" (click)="deleteAdmin()" >{{'' | i18n: 'delete'}}</button>
    </div>
    <div class = "col-12 mt-3 mb-2 p-0" >
      <ag-grid-angular
        style="width: 100%; height: 725px"
        class="ag-theme-alpine"
        rowHeight="45"
        [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
        [columnDefs]="columns"
        [pagination]="true"
        [paginationPageSize]="15"
        [rowSelection]="rowSelection"
        [rowData]="regionAdmins"
        [rowGroupPanelShow]="rowGroupPanelShow"
        (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
        (cellClicked)="rowEvents.onRowClicked($event)"
        (paginationChanged)="rowEvents.onPaginationChanged($event)"
        (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
<ds-modal #tpl size="sm">
  <ds-box-header>{{'' | i18n: 'new'}} {{'' | i18n: 'region_admin'}}</ds-box-header>
  <ds-box-content>
    <div style="display: block;">
      <input class="col-6" type="text" [(ngModel)]="newQNumber" placeholder="{{'' | i18n: 'q_number'}}"><ds-icon icon="search" (click)="searchUser()" size="lg" tone="action" class="modal-btn"></ds-icon>
    </div>
    <div class="form-group">
      <span *ngIf="user" class="text-body">{{user.name}} {{user.surname}} {{user.departmentCode}} </span>
      <br/><br/>
      <label *ngIf="user">{{'' | i18n: 'choose_region'}}:</label>
      <div class = "" *ngFor="let region of regions">
        <ds-form-field *ngIf="user">
          <ds-checkbox-group variant="stacked">
            <input ds-checkbox type="checkbox" (change)="grantUser(region)" [id]="region.id"/> <label>{{region.shortCode}} - {{region.name}}</label>
          </ds-checkbox-group>
        </ds-form-field>
      </div>
    </div>
  </ds-box-content>
<!--  <ds-box-footer>-->
<!--    <button ds-button #dsModalDismissElement  variant="outline">{{'' | i18n: 'cancel'}}</button>-->
<!--    <button ds-button  (click)="addUser()" [disabled]="!newQNumber">{{'' | i18n: 'add'}}</button>-->

<!--  </ds-box-footer>-->
</ds-modal>
