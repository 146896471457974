<div class="container-costom p-0">
  <div class="row">
    <div class="col-md-12 mt-5 p-0">
      <h1 class="h1-heading">
        <span class="green">{{'' | i18n: 'manage_locations'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class = "col-md-6 p-0">
      <div class="form-group  c-label">
        <label class = "select-region">{{'' | i18n: 'choose_region'}}:</label>
        <div class = "">
          <select [(ngModel)]="selectedRegion" (change)="changeRegion()">
            <option value="-1" selected disabled>{{'' | i18n: 'choose_region'}}</option>
            <option *ngFor="let Region of loggedInUserRegions" [ngValue]="Region">{{Region?.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end align-items-center p-0">
      <div *ngIf="selectedRegion">
        <button type="" class="btn-green-costom" [ngClass]="{'disabled':showLocation}" [ds-modal-trigger-for]="tpl" [disabled]="showLocation" [(isModalOpen)]="modalIsOpen">{{'' | i18n: 'add_location'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>


  <div class="col-12 d-flex justify-content-end p-0" *ngIf="editMode">
    <button class="green_b-tab" *ngIf="selectedLocation.active" (click)="changeStatus(false)">{{'' | i18n: 'deactivate'}}</button>
    <button class="green_b-tab" *ngIf="!selectedLocation.active" (click)="changeStatus(true)">{{'' | i18n: 'activate'}}</button>
    <button class="green_b-tab" [ds-modal-trigger-for]="update" [(isModalOpen)]="updateModalIsOpen">{{'' | i18n: 'edit'}}</button>
    <button class="green_b-tab p-0 mr-0" (click)="deleteLocation(selectedLocation.id)">{{'' | i18n: 'delete'}}</button>
  </div>
  <div class = "col-12 mt-3 mb-2 p-0" *ngIf="selectedRegion">
    <ag-grid-angular
      style="width: 100%; height: 470px"
      class="ag-theme-alpine"
      rowHeight="35"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="10"
      [rowData]="locations"
      [rowSelection]="rowSelection"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      (cellClicked)="rowEvents.onRowClicked($event)"
      (paginationChanged)="rowEvents.onPaginationChanged($event)"
      [columnDefs]="columns"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>

<ds-modal #tpl size="sm" >
  <ds-box-header>{{'' | i18n: 'add'}} {{'' | i18n: 'location'}}</ds-box-header>
  <ds-box-content>
    <ds-form-field>
      <label ds-label>{{'' | i18n: 'location_name'}}:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="newLocation.name" required/>
    </ds-form-field>
        <ds-form-field class="mt-2">
          <label ds-label>IPF-Code:</label>
          <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="newLocation.paramOne" required/>
        </ds-form-field>
        <ds-form-field class="mt-2">
          <label ds-label>NMS Werk:</label>
          <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="newLocation.paramTwo" required/>
        </ds-form-field>
        <ds-form-field class="mt-2">
          <label ds-label>BuNo:</label>
          <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="newLocation.paramThree" required/>
        </ds-form-field>
        <ds-form-field class="mt-2">
          <label ds-label>BP-ID:</label>
          <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="newLocation.paramFour" required/>
        </ds-form-field>
        <ds-form-field class="mt-2">
          <label ds-label>ISPI Outlet:</label>
          <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="newLocation.paramFive" required/>
        </ds-form-field>
        <ds-form-field class="mt-2">
          <label ds-label>VKO:</label>
          <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="newLocation.paramSix" required/>
        </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>close</button>
    <button ds-button (click)="clickAdd()">{{'' | i18n: 'add'}}</button>
  </ds-box-footer>
</ds-modal>


<ds-modal #update size="sm" >
  <ds-box-header>{{'' | i18n: 'edit'}} {{'' | i18n: 'location'}}</ds-box-header>
  <ds-box-content *ngIf="selectedLocation">
    <ds-form-field>
      <label ds-label>{{'' | i18n: 'location_name'}}:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="selectedLocation.name" required/>
    </ds-form-field>
    <ds-form-field class="mt-2">
      <label ds-label>IPF-Code:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="selectedLocation.paramOne" required/>
    </ds-form-field>
    <ds-form-field class="mt-2">
      <label ds-label>NMS Werk:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="selectedLocation.paramTwo" required/>
    </ds-form-field>
    <ds-form-field class="mt-2">
      <label ds-label>BuNo:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="selectedLocation.paramThree" required/>
    </ds-form-field>
    <ds-form-field class="mt-2">
      <label ds-label>BP-ID:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="selectedLocation.paramFour" required/>
    </ds-form-field>
    <ds-form-field class="mt-2">
      <label ds-label>ISPI Outlet:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="selectedLocation.paramFive" required/>
    </ds-form-field>
    <ds-form-field class="mt-2">
      <label ds-label>VKO:</label>
      <input ds-input type="text" placeholder="{{'' | i18n: 'type_here'}}" [(ngModel)]="selectedLocation.paramSix" required/>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="updateLocation(selectedLocation)">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>
