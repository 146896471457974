import {Component, Inject, OnInit} from '@angular/core';
import {DialogRef} from '../../../service/modal/dialog-ref';
import {DIALOG_DATA} from '../../../service/modal/dialog-tokens';
import Modal from '../../../shared/model/modal';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  constructor(private dialogRef: DialogRef,  @Inject(DIALOG_DATA) public data: Modal) { }

  ngOnInit(): void {
  }

  closeModal(confirmed = false){
    this.dialogRef.close(confirmed);
  }
}
