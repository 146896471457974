<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class = "light-orange">{{'' | i18n: 'compliance_violations'}}</span> <br />
        <span *ngIf="showNewComplianceDiv" class = "sub-title">SINGLE RIGHTS VIOLATIONS</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
        <button type="" class="btn-warning " [ngClass]="{'disabled':showNewComplianceDiv}" (click)="showNewComplianceDiv=true ;
         showDisabled=true" [disabled]="showNewComplianceDiv">{{'' | i18n: 'violation_definition'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="row table_costom">
  <div class="container-costom">
    <div style="background-color: #ffffff;padding: 10px;" *ngIf="showNewComplianceDiv">
      <div class="row">
        <div class="col-md-10" style="display:flex">
          <div class="form-group">
            <label>{{'' | i18n: 'select_application'}}:</label>
            <div class = "">
              <i class="fa fa-times" (click)="showNewComplianceDiv=false"></i>
              <select [(ngModel)]="selectedApplication" (change)="applicationChanged()">
                <option *ngFor="let application of applications"
                        [ngValue]="application">{{application.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-group c-label">
            <label *ngIf="!showCoexistingViolation">{{'' | i18n: 'select_existing_right_for_single_violation'}}:</label>
            <label *ngIf="showCoexistingViolation">{{'' | i18n: 'select_existing_right'}}:</label>
            <div class = "">
              <select [(ngModel)]="firstSelectedRight">
                <option disabled selected hidden>{{'' | i18n: 'select_existing_right'}} </option>
                <option *ngFor="let right of rightsList" [ngValue]="right">{{right.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-group c-label" *ngIf="showCoexistingViolation">
            <label>{{'' | i18n: 'select_existing_right'}}:</label>
            <div class = "">
              <select [(ngModel)]="secondSelectedRight">
                <option disabled selected hidden>{{'' | i18n: 'select_existing_right'}} </option>
                <option *ngFor="let right of rightsList" [ngValue]="right">{{right.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-group c-label">
            <label></label>
            <div class = "">
              <button name="submit" type="submit" class="btn btn-black" (click)="addCoExistingViolationClicked()" *ngIf="!showCoexistingViolation">{{'' | i18n: 'add_co_existing_violation'}}</button>
              <button name="submit" type="submit" class="btn btn-black" (click)="backToSingleViolationClicked()" *ngIf="showCoexistingViolation">{{'' | i18n: 'back_to_single_violation'}}</button>
            </div>
          </div>
          <div class="form-group c-label" style="margin-top: 31px;">
            <div class = "">
              <!--        <button type="" class="btn-black-right ">{{'' | i18n: 'add_co_existing_violation'}}</button>
                      <button type="" class="btn-black-right ">GO {{'' | i18n: 'back_to_single_violation'}}</button>-->
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <button name="submit" (click)="addViolationDefinition()" type="submit" class="btn btn-light-orange float-right">{{'' | i18n: 'add'}}</button>
        </div>
      </div>
    </div>
  </div>

  </div>
<div class = "row" style="margin-top: -18px;">
  <div class="col-md-12 container-costom col-lg-12 mt-3 mb-4" >
    <div class="panel-body">
      <div class="panel_heading mt-3">
        <div class="row">
          <div class="col-md-12 mt-4">
            <div id="accordion-request" *ngFor="let applicationDto of complianceDto.applications, index as i">
              <div class="panel">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a href="#application-{{i}}" class="accordion-toggle collapsed" data-toggle="collapse"
                       data-parent="#accordion-request"
                       aria-expanded="false">{{applicationDto.name}}</a>
                  </h4>
                </div>
                <div id="application-{{i}}" class="panel-collapse collapse">
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table ">
                        <tbody>
                        <tr *ngFor="let compRight of applicationDto.rights">
                          <td width="90% " *ngIf="compRight.id === compRight.disallowedRight.id">{{compRight.name}}</td>
                          <td *ngIf="compRight.id != compRight.disallowedRight.id">{{compRight.name}} <span><i class="fa fa-times" aria-hidden="true"></i> {{compRight.disallowedRight.name}}</span></td>
                          <td><i class="fa fa-trash" aria-hidden="true" (click)="delete(compRight)"></i></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="violation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-10">
            <div class="modal-message">Motivate your request:</div><br/>
          </div>
        </div>
        <div class="row">
        <div class="form-group col-lg-12">

          <textarea class="form-control" id="exampleFormControlTextarea1" cols="30" rows="6"></textarea>
          <label for="exampleFormControlTextarea1">{{'' | i18n: 'max_250_char'}}</label>
        </div>
        </div>
        <div class="row">
          <div class="col-lg-10 mt-2">
            <button name="submit" type="submit" data-toggle="modal" data-target="#violation" class="btn btn-light-orange">SEND</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
