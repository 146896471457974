import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from 'src/app/service/auth/authentication.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor() {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    if (AuthenticationService.isAuthenticated()) {
      // logged in so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      console.warn('AuthGaurd redirect');
      window.location.href = environment.iwa_domain_url;
      // this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }


  }

}
