<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class="green">{{'' | i18n: 'manage_business_rules'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
        <button type="" class="btn-green-costom" [ngClass]="{'disabled':showBusinessRule}" (click)="showBusinessRule=true ;
         showDisabled=true" [disabled]="showBusinessRule">{{'' | i18n: 'new_business_rule'}}<em
          class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>


<div class="row table_costom">
  <div class="container-costom">
    <div style="background-color: #ffffff;padding: 10px;" *ngIf="showBusinessRule">
      <div class="row">
        <div class="col-12 -flex">
          <form ds-form class="col-12 p-0 d-flex align-items-end" [formGroup]="businessRuleForm">
            <div class="col-8 d-flex p-0">
              <ds-form-field class="col-4">
                <label ds-label for="businessCategory">{{'' | i18n: 'select_category'}}:</label>
                <ds-select
                  id="businessCategory"
                  formControlName="businessCategory"
                  [labellingConfig]="{ placeholder: 'Select Business Category' }"
                  [options]="businessCategoryList" required="true">
                </ds-select>
              </ds-form-field>

              <ds-form-field class="col-4">
                <label ds-label for="business_rule_name">{{'' | i18n: 'business_rule_name'}}:</label>
                <input id="business_rule_name" type="text" ds-input placeholder="{{'' | i18n: 'business_rule_name'}}"
                       [readonly]="!businessRuleForm.controls.businessCategory.value" formControlName="businessRule"
                       required="true">
              </ds-form-field>

            </div>
            <div class="col-4 d-flex justify-content-end p-0">
              <button ds-button class="col-4 black-button"
                      (click)="showBusinessRule=false">{{'' | i18n: 'cancel'}}</button>
              <button class="col-4 d-flex flex-row-reverse justify-content-between" ds-button icon="arrow_right_small"
                      [disabled]="!businessRuleForm.controls.businessRule.value"
                      (click)="addRule()">
                {{'' | i18n: 'add'}}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>

    <div class="col-12 p-0 mt-3 mb-2" *ngIf="businessCategories?.length > 0">
      <div class="ag-theme-alpine row">
        <div class="col-12 mt-2 mb-2 d-flex justify-content-end">
            <span class="push-right">
              Page Size:
              <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize"
                      id="page-size" class="pagination-size">
                <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
              </select>
            </span>
        </div>
      </div>
    </div>
    <ag-grid-angular
      style="width: 100%; height: 1200px"
      class="ag-theme-alpine"
      rowHeight="35"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]=paginationSize
      [rowData]="tableData"
      [rowSelection]="rowSelection"
      (rowValueChanged)="rowEvents.onRowValueChanged($event)"
      (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
      [editType]="editType"
      [rowGroupPanelShow]="rowGroupPanelShow"
      [defaultColDef]="defaultColDef"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <!--    <div class="row">-->
  <!--      <div class="col-md-6 col-lg-6" *ngFor="let businessCategory of businessCategories , index as i">-->
  <!--        <div id="accordion-request">-->
  <!--          <div class="panel">-->
  <!--            <div class="panel-heading">-->
  <!--              <h4 class="panel-title">-->
  <!--                <a href="#application-{{i}}" class="accordion-toggle collapsed" data-toggle="collapse"-->
  <!--                   data-parent="#accordion-request"-->
  <!--                   aria-expanded="false">-->
  <!--                  <div class="row header-background">-->
  <!--                    <div class="col-lg-12">-->
  <!--                      {{businessCategory.name}}-->
  <!--                    </div>-->
  <!--                  </div>-->
  <!--                </a>-->
  <!--              </h4>-->
  <!--            </div>-->
  <!--            <div id="application-{{i}}" class="panel-collapse collapse">-->
  <!--              <div class="row">-->
  <!--                <div class="col-md-12">-->
  <!--                  <table class="table ">-->
  <!--                    <tbody>-->
  <!--                    <tr>-->
  <!--                      <td>-->
  <!--                        <div  class="categoryGroup col-md-12" *ngFor="let businessRule of businessCategory.businessRules">-->
  <!--                          <i class="fa fa-pencil" (click)="showUpdateRule.set(businessRule.id, true)"-->
  <!--                             *ngIf="!showUpdateRule.get(businessRule.id)"></i>-->
  <!--                          <div class="col-md-9 categoryIten"-->
  <!--                               *ngIf="!showUpdateRule.get(businessRule.id)">{{businessRule.name}}</div>-->
  <!--                          <div class="col-md-9"-->
  <!--                               *ngIf="!showUpdateRule.get(businessRule.id)" data-toggle="modal" data-target="#b-rule" (click)="clickDeleteBusinessRule(businessRule)"><i class="fa fa-trash-o" aria-hidden="true"></i></div>-->
  <!--                          <i class="fa fa-times" (click)="showUpdateRule.set(businessRule.id, false)"-->
  <!--                             *ngIf="showUpdateRule.get(businessRule.id)"></i>-->
  <!--                          <div class="col-md-9">-->
  <!--                            <input *ngIf="showUpdateRule.get(businessRule.id)" type="text" [(ngModel)]="businessRule.name"-->
  <!--                                   placeholder="{{'' | i18n: 'category_name'}}" required/>-->
  <!--                          </div>-->
  <!--                          <div style="display: flex" class="col-md-2">-->
  <!--                            <i class="fa fa-check" (click)="updateRule(businessRule)"-->
  <!--                               *ngIf="showUpdateRule.get(businessRule.id)"></i>-->
  <!--                          </div>-->
  <!--                        </div>-->
  <!--                      </td>-->
  <!--                    </tr>-->
  <!--                    </tbody>-->
  <!--                  </table>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    -->
  <!--          <div  class="col-md-6 col-lg-6  mt-3" *ngFor="let businessCategory of businessCategories">-->
  <!--              <div class="row header-background mb-3">-->
  <!--                <div class="col-lg-9">-->
  <!--                  {{businessCategory.name}}-->
  <!--                </div>-->
  <!--                <div class="col-lg-3">-->
  <!--                  {{'' | i18n: 'delete'}}-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="row" >-->
  <!--                <div  class="categoryGroup col-md-12" *ngFor="let businessRule of businessCategory.businessRules">-->
  <!--                  <i class="fa fa-pencil" (click)="showUpdateRule.set(businessRule.id, true)"-->
  <!--                     *ngIf="!showUpdateRule.get(businessRule.id)"></i>-->
  <!--                  <div class="col-md-9 categoryIten"-->
  <!--                       *ngIf="!showUpdateRule.get(businessRule.id)">{{businessRule.name}}</div>-->
  <!--                  <div class="col-md-9"-->
  <!--                       *ngIf="!showUpdateRule.get(businessRule.id)" data-toggle="modal" data-target="#b-rule" (click)="clickDeleteBusinessRule(businessRule)"><i class="fa fa-trash-o" aria-hidden="true"></i></div>-->
  <!--                  <i class="fa fa-times" (click)="showUpdateRule.set(businessRule.id, false)"-->
  <!--                     *ngIf="showUpdateRule.get(businessRule.id)"></i>-->
  <!--                  <div class="col-md-9">-->
  <!--                    <input *ngIf="showUpdateRule.get(businessRule.id)" type="text" [(ngModel)]="businessRule.name"-->
  <!--                           placeholder="{{'' | i18n: 'category_name'}}" required/>-->
  <!--                  </div>-->
  <!--                  <div style="display: flex" class="col-md-2">-->
  <!--                    <i class="fa fa-check" (click)="updateRule(businessRule)"-->
  <!--                       *ngIf="showUpdateRule.get(businessRule.id)"></i>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>&ndash;&gt;-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-detail">
          <strong>Services cars</strong> <br/>
          <div class="row">
            <div class="col-md-6">
              Business rule 1
            </div>
            <div class="col-md-6">
              2021 - 11- 24
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-content">
            Parameter 1 <br/>
            Parameter 2 <br/>
            Parameter 3 <br/>
            Parameter 4 <br/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="b-rule" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true" [ngStyle]="{'display':display}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="modal-message"><strong>
                Are you sure you want to
                delete this rule and all included rights?<br/>

                <span style="font-size: 17px;">This change cannot be reversed!</span>
              </strong></div>
              <br/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-10 mt-2">
              <button type="" class="btn-Cancel" data-dismiss="modal">{{'' | i18n: 'no'}}</button>
              <button type="" class="btn-confirm" (click)="deleteRule()" data-dismiss="modal">YES</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
