<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5 p-0">
      <h1 class="h1-heading">
        <span class = "orange">{{'' | i18n: 'manage_applications'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right p-0">
      <div class="form-group">
        <button type="" class="btn-orange-costom " [ngClass]="{'disabled':showApplication}"
                [ds-modal-trigger-for]="add" [(isModalOpen)]="modalIsOpen">{{'' | i18n: 'new_application'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="container-costom">
      <div  class="row"  >
        <div class = "col-12 mt-3 mb-2 p-0">
          <div class="col-12 d-flex justify-content-end mb-2 p-0" *ngIf="editMode">
            <button class="green_b-tab" [ds-modal-trigger-for]="update" [(isModalOpen)]="updateModalIsOpen">{{'' | i18n: 'edit'}}</button>
            <button class="green_b-tab p-0 mr-0" (click)="clickDeleteApplication()">{{'' | i18n: 'delete'}}</button>
          </div>
          <ag-grid-angular
            style="width: 100%; height: 710px"
            class="ag-theme-alpine"
            rowHeight="35"
            [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
            [columnDefs]="columns"
            [pagination]="true"
            [suppressDragLeaveHidesColumns]="true"
            [paginationPageSize]="15"
            [rowData]="applications"
            [rowSelection]="rowSelection"
            [rowGroupPanelShow]="rowGroupPanelShow"
            (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
            (cellClicked)="rowEvents.onRowClicked($event)"
            (paginationChanged)="rowEvents.onPaginationChanged($event)"
            (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="application" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-10">
            <div class="modal-message"><strong>
              Are you sure you want to delete this application and all included rights?<br />

              <span style="font-size: 17px;"> This change cannot be reversed!</span>
            </strong></div><br/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 mt-2">
            <button type="" class="btn-Cancel" data-dismiss="modal">{{'' | i18n: 'no'}}</button>
            <button type="" class="btn-confirm" (click)="clickDeleteApplication()" data-dismiss="modal">YES</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ds-modal #update size="sm">
  <ds-box-header>{{'' | i18n: 'edit'}} {{'' | i18n: 'application'}}</ds-box-header>
  <ds-box-content *ngIf="selectedApplication">
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'application_name'}}</label>
      <input ds-input type="text" [(ngModel)]="selectedApplication.name" required/>
      <span class="switchCheckbox">
      <ds-switch [(ngModel)]="selectedApplication.active" size="lg">Status Application (Active/Inactive)</ds-switch>
      <ds-switch [(ngModel)]="selectedApplication.extendable" size="lg">Extendable Application (Yes/No)</ds-switch>
      <ds-switch [(ngModel)]="selectedApplication.enabledForSingleRights" size="lg">Available for Single right Request (Yes/No)</ds-switch>
      </span>
      <span class="radioButton">
        <label>Type</label>
      </span>
      <span class="radioButton">
        <ds-radio-group>
        <input ds-input id="selectedApplicationLocal" type="radio" value="local" [(ngModel)]="selectedApplication.range" name="type" />
        <label ds-label for="selectedApplicationLocal">Local</label>
        <input ds-input id="selectedApplicationRegional" type="radio" value="regional" [(ngModel)]="selectedApplication.range" name="type" />
        <label ds-label for="selectedApplicationRegional">Regional</label>
       </ds-radio-group>
      </span>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="updateApplication(selectedApplication)" [disabled]="!selectedApplication?.name">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>

<ds-modal #add size="sm">
  <ds-box-header>{{'' | i18n: 'add'}} {{'' | i18n: 'application'}}</ds-box-header>
  <ds-box-content >
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'application_name'}}</label>
      <input ds-input type="text" [(ngModel)]="application.name" placeholder="{{'' | i18n: 'application_name'}}" required/>
      <span class="switchCheckbox">
      <ds-switch [(ngModel)]="application.active" size="lg">Status Application (Active/Inactive)</ds-switch>
      <ds-switch [(ngModel)]="application.extendable" size="lg">Extendable Application (Yes/No)</ds-switch>
      <ds-switch [(ngModel)]="application.enabledForSingleRights" size="lg">Available for Single right Request (Yes/No)</ds-switch>
      </span>

      <span class="radioButton">
        <label>Type</label>
      </span>
      <span class="radioButton">
        <ds-radio-group>
        <input ds-input id="local" type="radio" value="local" [(ngModel)]="application.range" name="type" />
        <label ds-label for="local">Local</label>
        <input ds-input id="regional" type="radio" value="regional" [(ngModel)]="application.range" name="type" />
        <label ds-label for="regional">Regional</label>
       </ds-radio-group>
      </span>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="addApplication(application)" [disabled]="!application?.name">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>
