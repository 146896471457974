import { Pipe, PipeTransform } from '@angular/core';
import GeneratedRights from 'src/app/components/popup/request-details/request-details.component';

@Pipe({
  name: 'rightsFilter'
})
export class ApplicationsPipe implements PipeTransform {

  transform(rights: GeneratedRights[], applicationName: string, applicationCategoryName: string, applicationRightName: string) {
  if (rights) {
      return rights.filter(right => {
        return ((applicationName ? right?.applicationName === applicationName : true) &&
        (applicationCategoryName ? right?.applicationCategory === applicationCategoryName : true) &&
        (applicationRightName ? right?.applicationRight === applicationRightName : true));
      });
    }
  return rights;
  }

}
