import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import Request from 'src/app/shared/model/requests/request';

@Injectable({
  providedIn: 'root'
})
export class ApproverHttpService {
  private _http: AraHttpService;


  constructor(http: AraHttpService) {
    this._http = http;
  }

  public approveRequest(request: Request): Observable<void> {
    return this._http.doPost(environment.approver_api_url + 'approve', request);
  }

  public rejectRequest(request: Request): Observable<void> {
    return this._http.doPost(environment.approver_api_url + 'reject', request);
  }
}
