import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {Component, Renderer2, ElementRef} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-decline-button-renderer',
  template: `
    <span :class="missionSpan" *ngIf="!params.data.granted"  (click)="buttonClicked()" >
          <svg *ngIf="!hideGranted" [style.fill]="inconColor" height="14px" width="14px" version="1.1" [id]="declineIconId" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 490 490" xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 ">
              </polygon>
             </g></svg>
        </span>
  `,
  styles: [
    'img { width: 20px; height: auto; } span {display: flex; height: 100%; justify-content: center; align-items: center} ',
  ],
})
export class DeclineButtonRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public inconColor: string = '';
  public declineIconId: string;
  public hideGranted = false;

  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.declineIconId = `decline-icon-${params.rowIndex}`;

    if ( this.params.data.status == 'Wird entfernt' || this.params.data.status == 'In removal' || this.params.data.status == 'Granted' ||
      this.params.data.status == 'Vergeben' || this.params.data.status == 'Active' || this.params.data.status == 'Aktiv' || this.params.data.status == 'Expiring soon' || this.params.data.status == 'Läuft bald aus') {
      this.hideGranted = true;
    } else {
      this.hideGranted = false;
    }
  }
  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
  buttonClicked() {
    this.inconColor = 'red';
    const declineIcon = document.getElementById(`decline-icon-${this.params.rowIndex}`);
    if (this.params.data.declined == false) {
      this.renderer.setStyle(declineIcon, 'fill', 'red');

      this.inconColor = 'red';
    }else {
      this.renderer.setStyle(declineIcon, 'fill', '#000000');
    }
    this.params.context.componentParent.declineSelected(this.params.data, true);
  }
}
