import {Component, OnInit} from '@angular/core';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import User from 'src/app/shared/model/user/user';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
import {DsTableConfig} from '@bmw-ds/components';
import {UserRole} from '../../../shared/enums/user-role';
import Role from '../../../shared/model/user/role';

@Component({
  selector: 'app-approver-deputy',
  templateUrl: './approver-deputy.component.html',
  styleUrls: ['./approver-deputy.component.scss']
})
export class ApproverDeputyComponent implements OnInit {
  auth = true;
  gbsModalIsOpen = false;
  currentUser: User;
  deputyQNumber: string;
  currentDeputyApprover: BmwPerson;

  private TOKEN_RESPONSE = 'TOKEN_RESPONSE';
  newDeputyModalOpen = false;
  newDeputyButtonDisabled = false;
  deleteDeputyModalIsOpen = false;
  config: Partial<DsTableConfig> = {
    hasStickyHeader: true,
  };
  constructor(private userService: UserHttpService, private lookupHttpService: LookupHttpService,
              private sweetAlertService: SweetAlertService, private dialog: DialogService) { }

  ngOnInit(): void {
    this.newDeputyButtonDisabled = false;
    this.userService.getLoggedInUser(false).subscribe(user => {
        this.deputyQNumber = '';
        this.currentDeputyApprover = null;
        this.currentUser = user;
        const tokenResponse = sessionStorage.getItem(this.TOKEN_RESPONSE);
        const tokenResponseJson = JSON.parse(tokenResponse);
        tokenResponseJson.user = user;
        sessionStorage.setItem(this.TOKEN_RESPONSE, JSON.stringify(tokenResponseJson));
        if (this.currentUser.standInUsers.length >= 5) {
          this.newDeputyButtonDisabled = true;
        }
      },
      () => this.sweetAlertService.showComError('Error retrieving logged in user.'));
  }

  newDeputyClicked() {
    this.newDeputyModalOpen = true;
  }

  closeNewDeputyModal() {
    this.newDeputyModalOpen = false;
  }

  newDeputySaveClicked() {
    this.userService.assignDeputy(this.deputyQNumber).subscribe((deputy) => {
      let newDeputy = new User();
      newDeputy.qnumber = deputy.qnumber;
      newDeputy.name = deputy.name;
      newDeputy.surname = deputy.surname;
      newDeputy.email = deputy.email;
      newDeputy.approverDeputyId = deputy.approverDeputyId;
      this.currentUser.standInUsers.push(newDeputy);
      if (this.currentUser.standInUsers.length >= 5) {
        this.newDeputyButtonDisabled = true;
      }
      }, (e) => {
        this.sweetAlertService.showComError(e.error);
      }
    );
    this.newDeputyModalOpen = false;
  }

  deleteDeputy(approverDeputyId: any) {
    const data: Modal = {
      action: 'remove_deputy',
      message: 'are_you_sure_remove_deputy'
    };
    this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
      if (results) {
        this.userService.deleteDeputy(approverDeputyId).subscribe(() => {
          this.currentUser.standInUsers = this.currentUser.standInUsers.filter(deputy => deputy.approverDeputyId !== approverDeputyId)
           this.newDeputyButtonDisabled = false;
        }, () => {

          this.sweetAlertService.showComError('Error deleting deputy with q-number: ' + this.currentUser.standInUser.qnumber + '.');
        });
      }
    });
  }

  isApprover(): boolean {
    let approver = false;
    if (this.currentUser) {
      this.currentUser.roles.forEach(value => {
        if (value.name === UserRole.ARA_APPROVER) {
          approver = true;
          return;
        }
      });
    }
    return approver;
  }
}
