import {Component, Inject, OnInit} from '@angular/core';
import {SelectListOption} from '@bmw-ds/components';
import {DateFormat} from '../../../shared/commons/dateFormat';
import {DialogRef} from 'src/app/service/modal/dialog-ref';
import {DIALOG_DATA} from 'src/app/service/modal/dialog-tokens';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {LookupHttpService} from '../../../service/http/lookup-http.service';
import {ApplicationHttpService} from '../../../service/http/application-http.service';
import Right from '../../../shared/model/rights/right';
import {CellDoubleClickedEvent, ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import RightLinkedRights from '../../../shared/model/rights/right-linked-rights';
import {ToastService} from '../../../service/feedback/toast.service';
import {SweetAlertService} from '../../../service/feedback/SweetAlertService.service';
import LinkedRight from '../../../shared/model/rights/linked-right';
import {RightHttpService} from '../../../service/http/right-http.service';

export default class  GeneratedRights {
  applicationName?: string;
  applicationCategory?: string;
  applicationRight?: string;
}

@Component({
  selector: 'app-application-rights-config',
  templateUrl: './application-rights-config.component.html',
  styleUrls: ['./application-rights-config.component.css']
})
export class ApplicationRightsConfigComponent implements OnInit {

  linkedRights: RightLinkedRights[] = [];

  editType: 'fullRow' = 'fullRow';
  tableData: Right[] = [];
  columns = this.getColumns();
  gridApi!: GridApi<Right>;
  rowSelection: 'single' | 'multiple' = 'single';
  selectedRightId: number;
  selectedRight: Right;

  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };


  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateLinkedRightStatus(event)
  }

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100},
    { id: 150},
    { id: 200},
    { id: 300},
    { id: 400},
    { id: 500},
  ];

  constructor(   private dialogRef: DialogRef,
                 @Inject(DIALOG_DATA) public tableData_: Right[],
                 private dateFormat: DateFormat, private i18nProvider: I18nProvider, private rightServices: RightHttpService,
                 public lookupHttpService: LookupHttpService, public applicationHttpService: ApplicationHttpService, private toastService: ToastService,
                 public sweetAlertService: SweetAlertService) { }

  async ngOnInit() {
    this.tableData_.forEach(value => {
      if (value.selected) {
        this.selectedRightId = value.id;
        this.selectedRight = value;
      }
    });

    this.linkedRights = await this.applicationHttpService.getLinkedApplicationRights(this.selectedRightId);

    this.setTableData();
    this.setApplicationData();
  }

  getColumns(){
    return [
      {field: 'applicationName', headerName: this.i18nProvider.getDescription('application'), filter: true, sortable: true,
        width: 70, enableRowGroup: true, cellClass: (params) => {return this.highlightRow(params.data);}},
      {field: 'categoryName', headerName: this.i18nProvider.getDescription('category_name'), filter: true, sortable: true,
        width: 70, enableRowGroup: true, cellClass: (params) => {return this.highlightRow(params.data);}},
      {field: 'name', headerName: this.i18nProvider.getDescription('application_rights'), filter: true, sortable: true,
        width: 70, cellClass: (params) => {return this.highlightRow(params.data);}},
      {field: 'linked', headerName: 'Linked', filter: true, sortable: true,
        width: 50, cellRenderer: (params) => {return this.setStatusCheckbox(params.data);}, cellClass: (params) => {return this.highlightRow(params.data);}}
    ];
  }

  setTableData() {
    this.tableData = this.tableData_;

    this.tableData.forEach(right => {
      this.linkedRights.forEach(linkedRight => {
         if (right.id == linkedRight.linkedRightId) {
           right.linked = true;
         }
      });
    });
  }

  setStatusCheckbox(data) {
    if (data && data.selected) {
      return;
    }

    if (data && data.linked) {
      return '&#10003';
    } else {
      return '&#88;';
    }
  }

  highlightRow(data) {
    if (data && data.selected) {
      return 'selected-row';
    }
  }

  onGridReady(params: GridReadyEvent<Right>) {
    this.gridApi = params.api;

  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_manage_application_rights_config_pagination', size);
    this.paginationSize = size;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_manage_application_rights_config_pagination')) {
      return localStorage.getItem('ara_manage_application_rights_config_pagination');
    }
    return 100;
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setApplicationData() {
    const applicationNames: Set<{ id: string, label: string }> = new Set<{ id: string, label: string }>();
    let isFound: boolean;
    this.tableData.forEach(right => {
      if (!applicationNames.has({id: right.applicationId.toString(), label: right.applicationName})) {
        applicationNames.add({id: right.applicationId.toString(), label: right.applicationName});
      }
    });
  }

  updateLinkedRightStatus(rowObject: any) {
    if (rowObject.column.colId == 'linked') {
      rowObject.data.linked = !rowObject.data.linked;
      console.log('linked', rowObject.data.linked);

      let linkedRight = new LinkedRight;
      linkedRight.rightId = this.selectedRightId;
      linkedRight.linkedRightId = rowObject.data.id;

      //link
      if (rowObject.data.linked) {
        this.applicationHttpService.addLinkedApplicationRight(linkedRight).subscribe(() => {
          let refreshParams = {force: true, rowNodes: [rowObject.node]};
          rowObject.api.refreshCells(refreshParams);
          this.toastService.success('Application right link status updated: ' + rowObject.data.name);
        }, (error) => {
          this.sweetAlertService.showComError('Error updating right: ' + JSON.stringify(error));
        });
      }

      //unlink
      if (!rowObject.data.linked) {
        this.applicationHttpService.removeLinkedApplicationRight(linkedRight).subscribe(() => {
          let refreshParams = {force: true, rowNodes: [rowObject.node]};
          rowObject.api.refreshCells(refreshParams);
          this.toastService.success('Application right link status updated: ' + rowObject.data.name);
        }, (error) => {
          this.sweetAlertService.showComError('Error updating right: ' + JSON.stringify(error));
        });
      }

    }

  }

  updateRightState() {
    this.rightServices.updateRightState(this.selectedRight).subscribe(() => {
      this.toastService.success('Application right status updated: ' +this.selectedRight.name);
    }, (error) => {
      this.sweetAlertService.showComError('Error updating right: ' + JSON.stringify(error));
    });
  }

  closeModal(confirmed = false){
    this.dialogRef.close(confirmed);
  }

}
