import {Component, OnInit} from '@angular/core';
import Request from 'src/app/shared/model/requests/request';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import User from 'src/app/shared/model/user/user';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';

@Component({
  selector: 'app-approver',
  templateUrl: './approver.component.html',
  styleUrls: ['./approver.component.scss']
})
export class ApproverComponent implements OnInit {
  auth = true;
  // Approver methods
  openRequestsForApproval: Request[];
  private userHttpService: UserHttpService;
  private user: User;
  private requestService: RequestHttpService;
  private sweetAlertService: SweetAlertService;
  private lookupHttpService: LookupHttpService;
  bmwPeopleMap: Map<string, BmwPerson> = new Map<string, BmwPerson>();
  compRequests: Request[];

  constructor(requestService: RequestHttpService, sweetAlertService: SweetAlertService,
              userHttpService: UserHttpService, lookupHttpService: LookupHttpService) {
    this.requestService = requestService;
    this.sweetAlertService = sweetAlertService;
    this.userHttpService = userHttpService;
    this.lookupHttpService = lookupHttpService;
  }

  ngOnInit() {
    this.initOpenRequests();
  }

  initOpenRequests() {
    this.bmwPeopleMap.clear();
    this.userHttpService.getLoggedInUser().subscribe(user => {
      this.user = user;
      this.requestService.getOpenRequestsAwaitingComplianceManager()
        .subscribe(value => {
          this.openRequestsForApproval = value;
          this.openRequestsForApproval.sort((a, b) => a.id - b.id);
          this.searchMultipleQnumbers(this.openRequestsForApproval.map(
            value1 => value1.requestedFor.qnumber));
        });
    });
    this.requestService.getCompletedRequestsForComplianceApprover()
      .subscribe(completedRequests => {
        this.searchMultipleQnumbers(completedRequests.map(
          value1 => value1.requestedFor.qnumber));
        this.compRequests = completedRequests;
        this.compRequests.sort((a, b) => a.id - b.id);
      });
  }

  searchMultipleQnumbers(qNumbers: string[]) {
    this.lookupHttpService.getAll(qNumbers.filter(qNumber => !this.bmwPeopleMap.has(qNumber))).subscribe(bmwPeople => {
      bmwPeople.forEach(bmwPerson => {
        this.bmwPeopleMap.set(bmwPerson.uid, bmwPerson);
      });
    });
  }

  isGranted(app: Request) {
    return app.requestStatus.name && app.requestStatus.name.toUpperCase() === 'GRANTED';
  }

  isDeclined(app: Request) {
    return app.requestStatus.name && app.requestStatus.name.toUpperCase() === 'DECLINED';
  }
}
