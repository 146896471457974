import {Component, OnInit, AfterViewInit} from '@angular/core';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Application from 'src/app/shared/model/applications/application';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import Right from 'src/app/shared/model/rights/right';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import RulesRight from 'src/app/shared/model/rights/rules-right';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import BusinessRule from 'src/app/shared/model/business-rules/business-rule';
import ApplicationRightsCategory from 'src/app/shared/model/applications/application-rights-category';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent
} from 'ag-grid-community';
import BusinessCategoryRuleDisplayMin from '../../../shared/model/business-rules/business-category-rule-display-min';
import UserDisplay from '../../../shared/model/user/user-display';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
@Component({
  selector: 'app-single-rights',
  templateUrl: './single-rights.component.html',
  styleUrls: ['./single-rights.component.scss']
})
export class SingleRightsComponent implements OnInit, AfterViewInit {
  auth = true;
  private applicationHttpService: ApplicationHttpService;
  private businessCategoryService: BusinessCategoryHttpService;
  private sweetAlertService: SweetAlertService;
  showDisabled = false;
  saveButtonEnabled = false;
  display: any;
  allApplications: Application[];
  selectedApplication: Application;
  selectedApplicationRightsCategory: ApplicationRightsCategory;
  selectedRight: Right = null;
  showApplicationAccordion: boolean;
  allBusinessCategories: BusinessCategory[];
  private businessRuleService: BusinessRuleHttpService;
  editType: 'fullRow' = 'fullRow';
  tableData: BusinessCategoryRuleDisplayMin[] = [];
  columns = this.getColumns();
  gridApi!: GridApi<UserDisplay>;
  showUpdateRule = new Map<number, boolean>();
  rowSelection: 'single' | 'multiple' = 'single';

  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateRuleStatus(event)
  };

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];

  constructor(applicationHttpService: ApplicationHttpService, sweetAlertService: SweetAlertService,
              businessCategoryService: BusinessCategoryHttpService, businessRuleService: BusinessRuleHttpService,
              private i18nProvider: I18nProvider) {
    this.applicationHttpService = applicationHttpService;
    this.sweetAlertService = sweetAlertService;
    this.businessCategoryService = businessCategoryService;
    this.businessRuleService = businessRuleService;
  }

  ngOnInit() {
    this.applicationHttpService.getMasterApplicationsWithRights().subscribe(applications => {
        this.allApplications = applications.sort((a, b) => a.name.localeCompare(b.name));
        this.allApplications.forEach(application => {
          application.applicationRightsCategories.sort((a, b) => a.name.localeCompare(b.name));
          application.applicationRightsCategories.forEach(appRightCat => appRightCat.rights.sort((a, b) => a.name.localeCompare(b.name)));
        });
        this.businessCategoryService.getActiveBusinessCategoriesWithRules().subscribe(businessCategories => {
          this.allBusinessCategories = businessCategories;

          this.tableData = this.setTableData();
        });
      },
      error => this.sweetAlertService.showComError('Error retrieving applications: ' + error));

  }
  ngAfterViewInit() {
    this.shortenOptions();
  }

  shortenOptions(){
    const  options = document.querySelectorAll('option');
    options.forEach(option => {
      let optionText = option.textContent;
      if (optionText && optionText.length > 38) {
        let newOption = optionText.substring(0, 38);
        option.textContent = newOption + '...';
      }
      option.style.fontSize = '13px';
    });
  }

  selectedRightChanged() {
    this.initSelectedBusinessRules();
  }

  private initSelectedBusinessRules() {
    this.allBusinessCategories.forEach(businessCategory => {
      businessCategory.businessRules.forEach(businessRule => {
        if (this.selectedRight) {
          const selected = this.selectedRight.businessRuleIds.includes(businessRule.id);
          businessRule.selected = selected;
          businessRule.canDelete = selected;
        } else {
          businessRule.selected = false;
          businessRule.canDelete = false;
        }
      });
    });
  }

  resetSelectedRightCategory() {
    this.selectedRight = null;
    this.initSelectedBusinessRules();
  }

  resetSelectedRightApplication() {
    this.selectedApplicationRightsCategory = null;
    this.selectedRight = null;
    this.initSelectedBusinessRules();
  }

  rightArrowClick() {
    this.showApplicationAccordion = true;
    this.saveButtonEnabled = true;
  }

  clickSave() {
    const ruleRights = new RulesRight();
    const deleteRuleRights = new RulesRight();
    this.allBusinessCategories.forEach(businessCategory => {
      businessCategory.businessRules.forEach(businessRule => {
        if (businessRule.selected) {
          ruleRights.ruleIds.push(businessRule.id);
        }
        if (businessRule.delete) {
          deleteRuleRights.ruleIds.push(businessRule.id);
        }
      });
    });
    ruleRights.rightId = this.selectedRight.id;
    deleteRuleRights.rightId = this.selectedRight.id;
    if (ruleRights.ruleIds.length > 0) {
      this.businessRuleService.addRightToRules(ruleRights).subscribe(() => {
          this.deleteRuleRightsInSave(deleteRuleRights);
        },
        error => {this.sweetAlertService.showComError('Error saving selection: ' + error); });
    } else {
      this.deleteRuleRightsInSave(deleteRuleRights);
    }

  }

  private deleteRuleRightsInSave(deleteRuleRights: RulesRight) {
    if (deleteRuleRights.ruleIds.length > 0) {
      this.businessRuleService.removeRightFromRules(deleteRuleRights).subscribe(() => {
        this.reloadPage();
      }, error => {
        this.sweetAlertService.showComError('Error saving selection: ' + error);
      });
    } else {
      this.reloadPage();
    }
  }

  private reloadPage() {
    this.ngOnInit();
    this.selectedApplication = null;
    this.selectedRight = null;
    this.showApplicationAccordion = false;
    this.saveButtonEnabled = false;
  }

  businessRuleCheckBoxClicked(accordionBusinessRule: BusinessRule) {
    if (accordionBusinessRule.canDelete) {
      accordionBusinessRule.delete = !accordionBusinessRule.selected;
    }
  }

  /////////////////////////

  updateRuleStatus(rowObject: any) {

    if (rowObject.column.colId == 'active') {
      let singleRights = new BusinessRule();
      let data = rowObject.data;
      if (rowObject.column.colId == 'active') {
        singleRights.active = !data.active;
        rowObject.data.active = singleRights.active;
        let refreshParams = {force: true, rowNodes: [rowObject.node]};
        rowObject.api.refreshCells(refreshParams);
      }
      singleRights.id = data.business_rule_id;
      singleRights.name = data.business_rule_name;

      this.businessRuleService.updateExistingBusinessRule(singleRights).subscribe(() => {
        this.showUpdateRule.set(singleRights.id, false);
      }, (error) => {
        this.sweetAlertService.showComError('Error retrieving businessRule: ' + JSON.stringify(error));
      });
    }
  }
  setTableData(): any[] {
    const dataRows: BusinessCategoryRuleDisplayMin[] = [];
    if (this.allApplications) {
      this.allBusinessCategories.forEach(businessCategory => {
        businessCategory.businessRules.forEach(businessRule => {
          let displayBusinessRule = new BusinessCategoryRuleDisplayMin();
          displayBusinessRule.business_category_id = businessCategory.id;
          displayBusinessRule.business_category_name = businessCategory.name;
          displayBusinessRule.business_rule_id = businessRule.id;
          displayBusinessRule.business_rule_name = businessRule.name;
          displayBusinessRule.active = businessRule.active;
          // displayBusinessRule.regions = businessRule.regionIds;
          dataRows.push(displayBusinessRule);
          displayBusinessRule = null;
        });
      });
    }
    return dataRows;
  }
  getColumns(){
    return [
      {field: 'business_category_name', headerName: this.i18nProvider.getDescription('category'), filter: true, sortable: true,
        width: 100, enableRowGroup: true, rowGroup: true, hide: true},
      {field: 'business_rule_name', headerName: this.i18nProvider.getDescription('business_rule_name'), filter: true, sortable: true,
        width: 100, enableRowGroup: true},
      {field: 'active', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true,
        width: 50, enableRowGroup: true, cellRenderer: (params) => { return this.setStatusCheckbox(params.data);}}
    ];

  }

  setStatusCheckbox(data) {
    if (data && data.active) {
      return '&#10003;';
    }
  }

  onGridReady(params: GridReadyEvent<UserDisplay>) {
    this.gridApi = params.api;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_manage_business_rules_pagination', size);
    this.paginationSize = size;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_manage_business_rules_pagination')) {
      return localStorage.getItem('ara_manage_business_rules_pagination');
    }
    return 40;
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }
}
