import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SelectListOption, th} from '@bmw-ds/components';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import RequestsForRightsManagerDto from '../../../shared/model/requests/requests-for-rights-manager-dto';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
import {Router} from '@angular/router';
import {I18nProvider} from '../../../service/translations/i18n.service';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import RegionPropsMinified from '../../../shared/model/requests/region-props-min';
import UserDisplay from '../../../shared/model/user/user-display';

@Component({
  selector: 'app-archive-dashboard',
  templateUrl: './archive-dashboard.component.html',
  styleUrls: ['./archive-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ArchiveDashboardComponent implements OnInit {

  region = new FormControl('');
  groupedRequests: RequestsForRightsManagerDto[];
  columns = this.getColumns();
  regions: RegionPropsMinified[] = [];
  employees: UserDisplay[];
  selectedRegion: any;
  selectedRegionId: any;
  selectedRegionName: string;
  requests: Map<string, RequestsForRightsManagerDto> = new Map<string, RequestsForRightsManagerDto>();
  gridApi!: GridApi<UserDisplay>;
  rowSelection: 'single' | 'multiple' = 'single';
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.router.navigate(['/archive-details', this.selectedRegionId, event.data.qnumber])
  };
  regionList: SelectListOption[] = [];
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";

  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  constructor(private regionService: RegionHttpService, private requestService: RequestHttpService,
              private lookupHttpService: LookupHttpService, public router: Router, private i18nProvider: I18nProvider) {
  }

  ngOnInit(): void {
    this.getRegions();
    this.getArchivedRegionData();
    this.getSelectedRegion();
    this.getArchivedInitRegionData();
    this.paginationSize = this.setPaginationSize();
  }

  getRegions() {
    this.regionService.getRegionsPropsMinified(true).subscribe(regions => {
      this.regions = regions.sort((a, b) => a.name.localeCompare(b.name));

      this.regionList = this.regions.map(region => {
        return {id: region.id.toString(), label: region.name};
      });
    });
  }

  getArchivedRegionData() {
    this.region.valueChanges.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(value => {
        this.groupedRequests = [];
        if (value) {
          const regionId: number = Number(value);
          this.selectedRegionId = regionId;
          this.requestService.getArchivedRequestsForRegion(regionId, true)
            .subscribe(users => {
              const selectedRegion = this.regions.find(region => region.id === regionId);
              this.selectedRegionName = selectedRegion.name;
              localStorage.setItem('ara-archive-dashboard-region', JSON.stringify(selectedRegion));
              const dataRows: UserDisplay[] = [];

              users.forEach(user => {
                user.region = selectedRegion.name;
                dataRows.push(user);
              });

              this.employees = dataRows;
            });
        }
      });
  }

  getArchivedInitRegionData() {
    if (this.selectedRegion) {
      this.requestService.getArchivedRequestsForRegion(this.selectedRegion.id, false)
        .subscribe(users => {
          const dataRows: UserDisplay[] = [];

          users.forEach(user => {
            user.region = this.selectedRegion.name;
            dataRows.push(user);
          });

          this.employees = dataRows;
        });
    }
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<UserDisplay>) {
    this.gridApi = params.api;
  }


  getColumns(){
    return [
      {field: 'fullName', headerName: this.i18nProvider.getDescription('surname_name'), filter: true, sortable: true, width: 250},
      {field: 'qnumber', headerName: this.i18nProvider.getDescription('q_number'), filter: true, sortable: true, width: 150},
      {field: 'departmentCode', headerName: this.i18nProvider.getDescription('department_code'), filter: true, sortable: true,
        width: 180, enableRowGroup: true},
      {field: 'email', headerName: this.i18nProvider.getDescription('email'), filter: true, sortable: true, width: 350},
      {field: 'region', headerName: this.i18nProvider.getDescription('region_name'), filter: true, sortable: true, width: 250}];
  }


  setPaginationSize() {
    if (localStorage.getItem('ara_archive_dashboard_pagination')) {
      return localStorage.getItem('ara_archive_dashboard_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_archive_dashboard_pagination', size);
    this.paginationSize = size;
  }

  getSelectedRegion(): any {
    if (localStorage.getItem('ara-archive-dashboard-region')) {
      this.selectedRegion = JSON.parse(localStorage.getItem('ara-archive-dashboard-region'));
      this.selectedRegionId = this.selectedRegion.id;
      this.selectedRegionName = this.selectedRegion.name;
    }
  }
}
