import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import Application from 'src/app/shared/model/applications/application';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {DateFormat} from 'src/app/shared/commons/dateFormat';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Request from 'src/app/shared/model/requests/request';
import {RequestStatusUtilsService} from 'src/app/service/http/request-status-utils.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import GeneratedRequest from 'src/app/shared/model/requests/generated-request.interface';
import {RequestBuilderService} from 'src/app/service/helper-services/request-builder.service';
import {DialogService} from 'src/app/service/modal/dialog.service';
import {RequestDetailsComponent} from 'src/app/components/popup/request-details/request-details.component';
import {ApplicationsService} from 'src/app/service/helper-services/applications.service';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
import {SweetAlertService} from '../../../service/feedback/SweetAlertService.service';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {formatDate} from '@angular/common';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import User from '../../../shared/model/user/user';
import BmwPerson from '../../../shared/model/user/bmw-person';

@Component({
  selector: 'app-rights-manager-completed-details',
  templateUrl: './rights-manager-completed-details.component.html',
  styleUrls: ['./rights-manager-completed-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RightsManagerCompletedDetailsComponent implements OnInit {
  selectedRequest: GeneratedRequest;
  requests: GeneratedRequest[] = [];
  showRequestOverview = false;
  qNumber: string;
  currentUser: User;
  requester: BmwPerson;
  columns = this.getTableColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<Application>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedRequest = event.data;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedRequest = event.data;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedRequest = null;
      this.showRequestOverview = false;
    }
  };

  constructor(private requestService: RequestHttpService, private activatedRoute: ActivatedRoute,
              private requestHttpService: RequestHttpService, private dateFormat: DateFormat,
              private applicationHttpService: ApplicationHttpService, public router: Router,
              private lookupHttpService: LookupHttpService, private userHttpService: UserHttpService,
              private sweetAlertService: SweetAlertService, private requestBuilderService: RequestBuilderService,
              private dialog: DialogService, private applications: ApplicationsService,
              private requestStatusUtilsService: RequestStatusUtilsService, private i18nProvider: I18nProvider) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.qNumber = this.router.getCurrentNavigation().extras.state.qNumber;
      this.lookupHttpService.getUser(this.qNumber).subscribe(usr => {
        this.requester = usr;
      });
    }
  }

  ngOnInit(): void {
    if (this.qNumber) {
      this.initOpenRequests(this.qNumber);
    } else {
      this.router.navigate(['/rights-manager-completed']);
    }
    this.userHttpService.getLoggedInUser(false).subscribe(user => {
      this.currentUser = user;
    });
  }

  initOpenRequests(qNumber: string) {

    this.requestHttpService.getCompletedUserRequestsForRightsAdmin(qNumber)
      .subscribe((requests: Request[]) => {
        this.requestBuilderService.prepareRequest(requests).then((generatedRequest: GeneratedRequest[]) => {
          this.requests = generatedRequest;
          this.requests.forEach(request => {
            request.region_name = request.region.name;
            request.status = this.requestStatusUtilsService.getStatusName(request);
            request.application_names = request.applications.map(application => application.name);
            request.end_date = formatDate(request.endDate, 'yyyy/MM/dd', 'en-GB');
            request.start_date = formatDate(request.startDate, 'yyyy/MM/dd', 'en-GB');
          });
        });
      });
  }

  getStatusStyle(openRequestReq: Request) {
    return this.requestStatusUtilsService.getStatusStyle(openRequestReq);
  }

  showRequestDetails(req: Request | GeneratedRequest | any) {
    this.dialog.open(RequestDetailsComponent, { data: req });
    this.selectedRequest = req;
    this.selectedRequest.businessRuleDTO?.rights.sort((a, b) => a.name.localeCompare(b.name));
  }

  showApplicationsView(req: Request | GeneratedRequest | any) {
    this.selectedRequest = req;
    this.showRequestOverview = true;
  }
  getTableColumns(){
    return [
      {field: 'region_name', headerName: this.i18nProvider.getDescription('region_name'), filter: true, sortable: true, width: 150},
      {field: 'category', headerName: this.i18nProvider.getDescription('category'), filter: true, sortable: true, width: 150},
      {field: 'rule', headerName: this.i18nProvider.getDescription('rules_or_rights'), filter: true, sortable: true, width: 300},
      {field: 'start_date', headerName: this.i18nProvider.getDescription('start_date'), filter: true, sortable: true, width: 120},
      {field: 'end_date', headerName: this.i18nProvider.getDescription('end_date'), filter: true, sortable: true , width: 120,
        cellClass: params => {
          const today = new Date().getTime();
          const selectedDate = new Date(params.data.endDate).getTime();
          if (today === selectedDate || today > selectedDate)
          {
            return 'changed';
          }}
      },
      {field: 'application_names', headerName: this.i18nProvider.getDescription('application_name'),
        filter: true, sortable: true, width: 346},
      {
        field: 'status', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true, width: 200,
        cellClass: params => {
          return this.getReqStatusStyle(params.data.requestStatus.name);
        },
      },
    ];
  }
  getReqStatusStyle(status: string) {
    return this.requestStatusUtilsService.getStatusStyleByStatusName(status);
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<Application>) {
    this.gridApi = params.api;
  }
}
