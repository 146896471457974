import ApplicationParameter from './application-parameter';
import Region from '../requests/region';
import ApplicationRightsCategory from './application-rights-category';
import RightsManagerHistoryItem from '../user/rights-manager-history-item';
import Right from '../rights/right';
import Comment from '../requests/comment';

export default class Application {
  constructor(id?: number) {
    this.id = id;
  }

  id: number;
  name: string;
  applicationRightsCategories: ApplicationRightsCategory[];
  parameters: ApplicationParameter[];
  regions: Region[];
  regionIds: number[];
  enabledForRegion: boolean;
  enabledForSingleRights: boolean;
  active: boolean;
  endDate: Date;
  rightsManagerHistoryItem: RightsManagerHistoryItem[];
  rightDTOS: Right[] = [];
  commentDTOS: Comment[] = [];
  status: string;
  range: string;
  extendable: boolean;
}
