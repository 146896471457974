import {Component, OnInit} from '@angular/core';
import Region from 'src/app/shared/model/requests/region';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {LocationHttpService} from 'src/app/service/http/location-http.service';
import Location from 'src/app/shared/model/requests/location';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
import Application from '../../../shared/model/applications/application';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  showLocation = false;
  locations: Location[];
  selectedRegion: Region;
  loggedInUserRegions: Region[];
  selectedLocation: Location;
  newLocation = new Location();
  showUpdateLocations = new Map<number, boolean>();
  locationSwitchDisabled = new Map<number, boolean>();
  columns = [];
  updateModalIsOpen = false;
  modalIsOpen = false;
  editMode = false;
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<Application>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedLocation = event.data;
      this.editMode = true;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedLocation = event.data;
      this.editMode = true;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedLocation = null;
      this.editMode = false;
    }
  };
  constructor(private userHttpService: UserHttpService, private sweetAlertService: SweetAlertService,
              private locationHttpService: LocationHttpService, private i18nProvider: I18nProvider, private dialog: DialogService) {
  }

  ngOnInit() {
    this.userHttpService.getCurrentUserRegions().subscribe(regions => {
      this.loggedInUserRegions = regions;
    });
  }

  deleteLocation(id: number) {
    const data: Modal = {
      action : 'delete',
      message: 'delete_item'
    };
    this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
      if (results){
    this.locationHttpService.deleteLocation(id).subscribe(() => this.changeRegion(),
        () => this.sweetAlertService.showComError('Error deleting Location.'));
      } });
  }

  changeRegion() {
    this.locationHttpService.getLocationsForRegion(this.selectedRegion, false)
      .subscribe(locationsForRegion => {
        locationsForRegion.forEach(location => {
          if (location.active){
            location.status = this.i18nProvider.getDescription('active');
          }
          else {
            location.status = this.i18nProvider.getDescription('inactive');
          }
        });
        this.getColumns();
        this.locations = locationsForRegion;
        this.locations.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  clickAdd() {
    this.newLocation.region = this.selectedRegion;
    this.locationHttpService.createLocation(this.newLocation).subscribe(() => {
      this.modalIsOpen = false;
      this.showLocation = false;
      this.newLocation = new Location();
      this.changeRegion();
    });
  }
  changeStatus(value: boolean){
    this.selectedLocation.active = value;
    this.updateLocation(this.selectedLocation);
  }

  updateLocation(currentLocation: Location) {
    this.locationHttpService.updateLocation(currentLocation).subscribe(() => {
      this.showUpdateLocations.set(currentLocation.id, false);
      this.locationSwitchDisabled.set(currentLocation.id, true);
      this.updateModalIsOpen = false;
      this.changeRegion();
    });
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<Application>) {
    this.gridApi = params.api;
  }

  getColumns(){
    this.columns = [
      {
        field: 'name',
        headerName: this.i18nProvider.getDescription('location_name'),
        filter: true,
        sortable: true,
        width: 200
      },
      {
        field: 'paramOne',
        headerName: 'IPF-Code',
        filter: true,
        sortable: true,
        width: 200
      },
      {
        field: 'paramTwo',
        headerName: 'NMS Werk',
        filter: true,
        sortable: true,
        width: 200
      },
      {
        field: 'paramThree',
        headerName: 'BuNo',
        filter: true,
        sortable: true,
        width: 150
      },
      {
        field: 'paramFour',
        headerName: 'BP-ID',
        filter: true,
        sortable: true,
        width: 150
      },
      {
        field: 'paramFive',
        headerName: 'ISPI Outlet',
        filter: true,
        sortable: true,
        width: 160
      },
      {
        field: 'paramSix',
        headerName: 'VKO',
        filter: true,
        sortable: true,
        width: 150
      },
      {
        field: 'status',
        headerName: this.i18nProvider.getDescription('status'),
        filter: true,
        sortable: true,
        width: 176,
        cellClass: params => {
          return params.data.active ? 'active' : 'inactive';
        }
      }];
  }
}
