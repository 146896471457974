<div class = "row">
  <div class = "col-12 mb-2 mt-1">
    <span class = "small-heading">Current request list</span>
  </div>
</div>
<div class = "row">
  <div class = "col-12 mt-3 mb-2">
    <table class="table">
      <thead>
      <tr>
        <th width="15%">{{'' | i18n: 'surname_name'}}
        </th>
        <th width="15%">User-ID
        </th>
        <th width="15%">Workflow Date
        </th>
        <th width="15%">{{'' | i18n: 'start_date'}}
        <th width="40%">
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Denise Bekker</td>
        <td>QXS8790</td>
        <td>24.05.2022</td>
        <td>24.07.2022</td>
        <td style="text-align: right;"><a href="#" [routerLink]="['/compliance-dashboard']">
          <button ds-button>{{'' | i18n: 'details'}}</button>
        </a>
        </td>
      </tr>
      <tr>
        <td>Denise Bekker</td>
        <td>QXS8790</td>
        <td>24.05.2022</td>
        <td>24.07.2022</td>
        <td style="text-align: right;" [routerLink]="['/compliance-dashboard']">
          <button ds-button>{{'' | i18n: 'details'}}</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
