<div class = "row">
  <div class = "col-12 mb-2 mt-1">
    <span class = "small-heading">USER PROFILE - </span>Q222222 - Default Location: Offakamp PKW
  </div>
</div>
<table class="table mb-2">
  <thead>
  <tr>
    <th scope="col">Region
    </th>
    <th scope="col">Category<br />
      <ds-form-field class = "mt-2" style="width: 92%;">
        <ds-select style="width: 92%;"
                   id="category-approver"
                   [labellingConfig]="{ placeholder: 'Select' }"
                   [options]="[
            { id: 'Service PKW', label: 'Service PKW' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">Type<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="type-approver"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Business rule', label: 'Business rule' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th width="15%">Rule/Right<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="rule_Rights-approver"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Serviceabrechung ', label: 'Serviceabrechung' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">Request date</th>
    <th scope="col">{{'' | i18n: 'start_date'}}</th>
    <th scope="col">{{'' | i18n: 'end_date'}}<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="end-date-approver"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: '31.03.2022 ', label: '31.03.2022' }
          ]"
        ></ds-select>
      </ds-form-field></th>
    <th width="15%">Remarks
    </th>
    <th scope="col">{{'' | i18n: 'status'}}<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="status-approver"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Active ', label: 'Active' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">{{'' | i18n: 'details'}}</th>
    <th scope="col">{{'' | i18n: 'compliance'}}<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="com"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Yes ', label: 'YES' }
          ]"
        ></ds-select>
      </ds-form-field></th>
    <th scope="col">Special Rights</th>
    <th scope="col">Select</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>Region</td>
    <td>{{'' | i18n: 'business_category_name'}}.</td>
    <td>Single Right</td>
    <td>userRight</td>
    <td>24.05.2022</td>
    <td>24.05.2022</td>
    <td>24.07.2022</td>
    <td>
      <input ds-input id="appRemarks" type="text" requred = "false" placeholder="Approver remarks" />
    </td>
    <td class = "status-new">New</td>
    <td [ds-modal-trigger-for]="details" (modalDismiss)="onModalClose($event)"><img class = "ml-3" src="../../../../assets/images/icons/details.png"/></td>
    <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/com-tab.png"/>--></td>
    <td></td>
    <td>
      <ds-switch></ds-switch>
    </td>
  </tr>
  <tr>
    <td>region</td>
    <td>businessCategoryId</td>
    <td>{{'' | i18n: 'business_rule'}}</td>
    <td>userRule</td>
    <td>24.05.2022</td>
    <td>24.05.2022</td>
    <td>24.07.2022</td>
    <td>
      <input ds-input id="username" type="text" requred = "false" placeholder="Approver remarks" />
    </td>
    <td class = "status-active">Active</td>
    <td [ds-modal-trigger-for]="details" (modalDismiss)="onModalClose($event)"><img class = "ml-3" src="../../../../assets/images/icons/details.png"/></td>
    <td></td>
    <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/account.png"/>--></td>
    <td>
      <ds-switch></ds-switch>
    </td>
  </tr>
  </tbody>
</table>
<!--Comments and approve section -->
<div class = "row mb-2 mt-4 justify-content-end">
  <div class = "col-md-1 col-sm-2">
    <span class = "approve-button"><button ds-button data-toggle="modal" data-target="#accept">Accept</button></span>
  </div>
</div>
<!--Compliance violation table -->
<div class = "row">
  <div class = "col-12 mb-2 mt-5">
    <span class = "small-heading">{{'' | i18n: 'compliance_violations'}}</span>
  </div>
</div>
<table class="table mb-2">
  <thead>
  <tr>
    <th scope="col">Region
    </th>
    <th scope="col">Category<br />
      <ds-form-field class = "mt-2" style="width: 92%;">
        <ds-select style="width: 92%;"
                   id="category-com"
                   [labellingConfig]="{ placeholder: 'Select' }"
                   [options]="[
            { id: 'Service PKW', label: 'Service PKW' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">Type<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="type-com"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Business rule', label: 'Business rule' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th width="30%">Rule/Right<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="rule_Rights-com"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Serviceabrechung ', label: 'Serviceabrechung' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">{{'' | i18n: 'start_date'}}</th>
    <th scope="col">{{'' | i18n: 'end_date'}}<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="end-date-com"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: '31.03.2022 ', label: '31.03.2022' }
          ]"
        ></ds-select>
      </ds-form-field></th>
    <th scope="col">{{'' | i18n: 'status'}}<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="status-com"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Active ', label: 'Active' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">{{'' | i18n: 'details'}}</th>
    <th width="7%">Compliance
    </th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>Region</td>
    <td>{{'' | i18n: 'business_category_name'}}.</td>
    <td>Single Right</td>
    <td>userRight</td>
    <td>24.05.2022</td>
    <td>24.07.2022</td>
    <td class = "status-new">New</td>
    <td [ds-modal-trigger-for]="details" (modalDismiss)="onModalClose($event)"><img class = "ml-3" src="../../../../assets/images/icons/details.png"/></td>
    <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/com-tab.png"/>--></td>

  </tr>
  </tbody>
</table>
<!--Special right table -->
<div class = "row">
  <div class = "col-12 mb-2 mt-5">
    <span class = "small-heading">SPECIAL RIGHT</span>
  </div>
</div>
<table class="table mb-2">
  <thead>
  <tr>
    <th scope="col">Region
    </th>
    <th scope="col">Category<br />
      <ds-form-field class = "mt-2" style="width: 92%;">
        <ds-select style="width: 92%;"
                   id="category"
                   [labellingConfig]="{ placeholder: 'Select' }"
                   [options]="[
            { id: 'Service PKW', label: 'Service PKW' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">Type<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="type"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Business rule', label: 'Business rule' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th width="30%">Rule/Right<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="rule_Rights"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Serviceabrechung ', label: 'Serviceabrechung' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">{{'' | i18n: 'start_date'}}</th>
    <th scope="col">{{'' | i18n: 'end_date'}}<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="end-date"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: '31.03.2022 ', label: '31.03.2022' }
          ]"
        ></ds-select>
      </ds-form-field></th>
    <th scope="col">{{'' | i18n: 'status'}}<br />
      <ds-form-field class = "mt-2">
        <ds-select
          id="status"
          [labellingConfig]="{ placeholder: 'Select' }"
          [options]="[
            { id: 'Active ', label: 'Active' }
          ]"
        ></ds-select>
      </ds-form-field>
    </th>
    <th scope="col">{{'' | i18n: 'details'}}</th>
    <th width="7%">Special Right
    </th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>Region</td>
    <td>{{'' | i18n: 'business_category_name'}}.</td>
    <td>Single Right</td>
    <td>userRight</td>
    <td>24.05.2022</td>
    <td>24.07.2022</td>
    <td class = "status-new">New</td>
    <td [ds-modal-trigger-for]="details" (modalDismiss)="onModalClose($event)"><img class = "ml-3" src="../../../../assets/images/icons/details.png"/></td>
    <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/account.png"/>--></td>

  </tr>
  </tbody>
</table>
<!--User details modal -->
<ds-modal #details size="md">
  <ds-box-header>
    <span class = "modal-header-title-md">
      request details
    </span>
  </ds-box-header>
  <ds-box-content>
    <div class = "row">
      <div class = "col-4">
        <ds-form-field>
          <label ds-label for="Type">Type:</label>
          <input ds-input id="type-request" readonly="true" type="text" required="false"/>
        </ds-form-field>
      </div>
      <div class = "col-4">
        <ds-form-field>
          <label ds-label>Region:</label>
          <input ds-input id="region-request" readonly="true" type="text" required="false"/>
        </ds-form-field>
      </div>
      <div class = "col-4">
        <ds-form-field>
          <label ds-label for="status">{{'' | i18n: 'status'}}:</label>
          <input ds-input id="status-request" readonly="true" type="text" required="false"/>
        </ds-form-field>
      </div>
    </div>
    <div class = "row">
      <div class = "col-4 mt-3">
        <ds-form-field>
          <label ds-label for="category">Category:</label>
          <input ds-input id="category-r" readonly="true" required = "false" type="text" />
        </ds-form-field>
      </div>
      <div class = "col-4 mt-3">
        <ds-form-field>
          <label ds-label>{{'' | i18n: 'start_date'}}:</label>
          <input ds-input id="startDate-r" readonly="true" required = "false" type="text" />
        </ds-form-field>
      </div>
      <div class = "col-4 mt-3">
        <ds-form-field>
          <label ds-label>{{'' | i18n: 'end_date'}}:</label>
          <input ds-input id="endDate-r" readonly="true" required = "false" type="text" />
        </ds-form-field>
      </div>
    </div>
    <div class = "row">
      <div class = "col-4 mt-3">
        <ds-form-field>
          <label ds-label for="rule-r">{{'' | i18n: 'rule'}}:</label>
          <input ds-input id="rule-l" readonly="true" required = "false" type="text" />
        </ds-form-field>
      </div>
      <div class = "col-4 mt-3">
        <ds-form-field>
          <label ds-label for="Locations-r">Remarks: </label>
          <input ds-input id="rule-r" readonly="true" required = "false" type="text" value = "Requester remarks"/>
        </ds-form-field>
      </div>
      <div class = "col-4 mt-3">
        <ds-form-field>
          <label ds-label for="version-r">Version:</label>
          <input ds-input id="version-r" readonly="true" required = "false" type="text" />
        </ds-form-field>
      </div>
    </div>
    <div class = "row">
      <div class = "col-4 mt-3">
        <ds-form-field>
          <label ds-label for="Locations-r">{{'' | i18n: 'locations'}}: </label>
          <textarea ds-input id="locations-r" readonly="true" required = "false" rows="5">
            Brsbuttel
            City Sud
            Elbvororte
            Offakam,
            Offakam Motorrad
          </textarea>
        </ds-form-field>
      </div>
    </div>
    <div class = "row">
      <div class = "col-12 mt-3 mb-2">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Application<br />
              <select class="form-select select-c mt-1" aria-label="Default select example">
                <option selected>Select</option>
                <option value="1">ADGR ISPI<</option>
              </select>
            </th>
            <th scope="col">Application Category<br />
              <select class="form-select select-c mt-1" aria-label="Default select example">
                <option selected>Select</option>
                <option value="1">ADGR ISPI</option>
              </select>
            </th>
            <th scope="col">{{'' | i18n: 'right'}}<br />
              <select class="form-select select-c mt-1" aria-label="Default select example">
                <option selected>Select</option>
                <option value="1">Right</option>
              </select>
            </th>
            <th scope="col">Compliance<br />
              <select class="form-select select-c mt-1" aria-label="Default select example">
                <option selected>Select</option>
                <option value="1">Compliance</option>
              </select>
            <th scope="col">Special Rights<br />
              <select class="form-select select-c mt-1" aria-label="Default select example">
                <option selected>Select</option>
                <option value="1">Special Rights</option>
              </select>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>ADGR ISPI</td>
            <td>User</td>
            <td>APPL-ISPI-USER</td>
            <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/com-tab.png"/>--></td>
            <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/account.png"/>--></td>
          </tr>
          <tr>
            <td>ADGR ISPI</td>
            <td>User</td>
            <td>APPL-ISPI-USER</td>
            <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/com-tab.png"/>--></td>
            <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/account.png"/>--></td>
          </tr>
          <tr>
            <td>ADGR ISPI</td>
            <td>User</td>
            <td>APPL-ISPI-USER</td>
            <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/com-tab.png"/>--></td>
            <td><!--<img class = "ml-3 cursor-none" src="../../../../assets/images/icons/account.png"/>--></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ds-box-content>
</ds-modal>
<!--accept modal -->
<div class="modal fade" id="accept" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div style = "margin-left: -16px" class="modal-message"><strong>ACCEPTED.</strong></div><br/>
          </div>
          <h5 class="modal-title mt-5">Workflow was Processed.</h5>
        </div>
        <div class="row">
          <div class="col-lg-12 mt-5">
            <button ds-button data-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
