 <ds-box-header>
   <span class = "col-12 d-flex justify-content-end">
      <ds-icon icon="close" size="md" tone="action" (click)="closeModal()" class="modal-btn"></ds-icon>
    </span>
   <span class = "modal-header-title-md">
      {{'' | i18n: 'give_back_all_assignments'}}
    </span>
  </ds-box-header>
  <ds-box-content class="panel">
    <form ds-form [formGroup]="assignmentsForm">
      <div class = "col-12 mt-4">
        <ds-form-field>
          <label ds-label >{{'' | i18n: 'end_date'}}: </label>
          <ds-datepicker [(config)]="dateConfig">
            <input ds-date formControlName="endDate" required/>
          </ds-datepicker>
        </ds-form-field>
      </div>
      <div class = "col-12 mt-4">
        <ds-form-field>
          <label ds-label for="remarks">{{'' | i18n: 'write_a_comment'}}: </label>
          <textarea required="false" id="remarks" rows = "6" ds-input placeholder="Type remarks here..."  formControlName="comment">
          </textarea>
        </ds-form-field>
      </div>
    </form>
  </ds-box-content>
  <ds-box-footer>
   <div class="col-12 d-flex justify-content-end mb-5">
     <button ds-button class="blk" (click)="closeModal()">{{'' | i18n: 'cancel'}}</button>
     <button ds-button (click)="giveBackAssignments()" [disabled]="!assignmentsForm.valid">{{'' | i18n: 'save'}}</button>
   </div>
  </ds-box-footer>

