<div class="row">
  <div class="col-12 mt-5">
    <h1 class="small-heading">
      <span class="archive-title">{{'' | i18n : 'departments'}}</span>
    </h1>
  </div>
</div>
<div class="col-12 d-flex flex-row justify-content-end mt-1 mb-3">
  <button class="btn-orange-costom" (click)="openSaveModal()">{{'' | i18n: 'add'}}</button>
</div>

<div class="row " style="width: 100%;margin-left: 1px;">
  <div class="col-12 d-flex justify-content-end" *ngIf="selectedDepartment">
    <button class="green_b-tab" (click)="updateStatus(true)" *ngIf="!selectedDepartment.active">{{'' | i18n: 'activate'}}</button>
    <button class="green_b-tab" (click)="updateStatus(false)" *ngIf="selectedDepartment.active">{{'' | i18n: 'deactivate'}}</button>
    <button class="green_b-tab" [ds-modal-trigger-for]="update" >{{'' | i18n: 'edit'}}</button>
    <button class="green_b-tab mr-0" (click)="deleteDepartment(selectedDepartment.id)" >{{'' | i18n: 'delete'}}</button>
  </div>
  <div class = "col-12 mt-3 mb-2">
    <ag-grid-angular
      style="width: 100%; height: 425px"
      class="ag-theme-alpine"
      rowHeight="56"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="10"
      [rowData]="departments"
      [rowSelection]="rowSelection"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      (cellClicked)="rowEvents.onRowClicked($event)"
      (paginationChanged)="rowEvents.onPaginationChanged($event)"
      [columnDefs]="columns"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
<ds-modal #new_department [ds-modal-trigger-for]="new_department" [(isModalOpen)]="newDepartmentModalOpen" size="sm"  [dimensionsConfig]="{ width: '600px' }">
  <ds-box-header>
    <span class = "modal-header-title">
      {{'' | i18n : 'new_department'}}
    </span>
  </ds-box-header>
  <ds-box-content class="mt-5 mb-5">
    <div class = "row">
      <div class = "col-12 d-flex justify-content-center">
        <ds-form-field class="col-8">
          <label ds-label class = "pull-left">{{'' | i18n : 'department_code'}} : </label>
          <input ds-input  class="col-12" id="userID" type="text" required="false" [(ngModel)]="departmentCode"/>
        </ds-form-field>
      </div>
    </div>

  </ds-box-content>
  <ds-box-footer class = "mb-3 d-flex flex-row justify-content-center ">
    <button class = "cancel_b_standard col-2" (click)="closeSaveModal()">{{'' | i18n: 'cancel'}}</button>
    <button class = "green_b_standard col-2" (click)="saveDepartment()" [disabled]="!departmentCode">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>

<ds-modal #update [ds-modal-trigger-for]="update" [(isModalOpen)]="updateModalOpen" size="sm"  [dimensionsConfig]="{ width: '600px' }">
  <ds-box-header>
    <span class = "modal-header-title">
      {{'' | i18n : 'new_department'}}
    </span>
  </ds-box-header>
  <ds-box-content class="mt-5 mb-5" *ngIf="selectedDepartment">
    <div class = "row">
      <div class = "col-12 d-flex justify-content-center">
        <ds-form-field class="col-8">
          <label ds-label class = "pull-left">{{'' | i18n : 'department_code'}} : </label>
          <input ds-input  class="col-12" id="user" type="text" required="false" [(ngModel)]="selectedDepartment.departmentCode"/>
        </ds-form-field>
      </div>
    </div>
  </ds-box-content>
  <ds-box-footer class = "mb-3 d-flex flex-row justify-content-center ">
    <button class = "cancel_b_standard col-2" (click)="closeSaveModal()">{{'' | i18n: 'cancel'}}</button>
    <button class = "green_b_standard col-2" (click)="updateDepartment(selectedDepartment)" [disabled]="!selectedDepartment?.departmentCode">{{'' | i18n: 'edit'}}</button>
  </ds-box-footer>
</ds-modal>
