import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AraHttpService} from './ara-http.service';
import {Observable} from 'rxjs';
import BusinessRule from 'src/app/shared/model/business-rules/business-rule';
import {HttpParams} from '@angular/common/http';
import {SweetAlertService} from '../feedback/SweetAlertService.service';
import RulesRight from 'src/app/shared/model/rights/rules-right';
import RuleRights from 'src/app/shared/model/rights/rule-rights';
import RightMinified from '../../shared/model/rights/right-min';
import BusinessRuleRegion from '../../shared/model/business-rules/business-rule-region';

@Injectable({
  providedIn: 'root'
})
export class BusinessRuleHttpService {
  private http: AraHttpService;
  private sweetAlertService: SweetAlertService;


  constructor(http: AraHttpService, sweetAlertService: SweetAlertService) {
    this.http = http;
    this.sweetAlertService = sweetAlertService;
  }

  public getMasterBusinessRules(withRights: boolean = false): Observable<BusinessRule[]> {
    let httpParams = new HttpParams();
    if (withRights) {
      httpParams = httpParams.append('withRights', withRights.toString());
    }
    return this.http.doGetParams(environment.master_business_rule_api_url, httpParams);
  }

  public getBusinessRules(withRights: boolean = false): Observable<BusinessRule[]> {
    let httpParams = new HttpParams();
    if (withRights) {
      httpParams = httpParams.append('withRights', withRights.toString());
    }
    return this.http.doGetParams(environment.business_rule_api_url, httpParams);
  }

  getBusinessRule(masterId: number) {
    return this.http.doGet(environment.business_rule_api_url + `${masterId}` );
  }

  public updateBusinessRule(businessRule: BusinessRule): Observable<BusinessRule> {
    return this.http.doPut(environment.business_rule_api_url, businessRule);
  }

  public updateExistingBusinessRule(businessRule: BusinessRule): Observable<BusinessRule> {
    return this.http.doPut(environment.business_rule_api_url + 'update', businessRule);
  }

  public linkBusinessRuleToRegion(businessRuleRegion: BusinessRuleRegion): Observable<BusinessRule> {
    return this.http.doPut(environment.business_rule_api_url + 'link-rules-region', businessRuleRegion);
  }

  public createBusinessRule(businessRule: BusinessRule): Observable<BusinessRule> {
    return this.http.doPost(environment.business_rule_api_url, businessRule);
  }

  public deleteBusinessRule(businessRule: BusinessRule): Observable<string> {
    return this.http.doDelete(environment.business_rule_api_url + businessRule.id + '/');
  }

  /**
   * Get Single BusinessRule with its rights.
   */
  public getBusinessRuleWithRights(ruleId: number): Observable<BusinessRule> {
    return this.http.doGet(environment.business_rule_api_url + ruleId + '/rights');
  }

  public addRightsToRule(ruleId: number, rightsIds: number[]): Promise<BusinessRule> {
    const ruleRights = this.buildRuleRights(rightsIds, ruleId);
    return this.http.doPut(environment.business_rule_api_url + 'rights', ruleRights).toPromise();
  }

  private buildRuleRights(rightsIds: number[], ruleId: number) {
    const ruleRights = new RuleRights();
    ruleRights.rightIds = rightsIds;
    ruleRights.ruleId = ruleId;
    return ruleRights;
  }

  public removeRightsFromRule(ruleId: number, rightsIds: number[]): Promise<BusinessRule> {
    const ruleRights = this.buildRuleRights(rightsIds, ruleId);
    return this.http.doPut(environment.business_rule_api_url + 'remove_rule_rights', ruleRights).toPromise();
  }

  public removeRightFromBusinessRules(rightId: number) {
    return this.http.doPut(environment.business_rule_api_url + 'remove_right_from_business_rules', rightId);
  }

  public addRightToRules(ruleRights: RulesRight): Observable<string> {
    return this.http.doPut(environment.business_rule_api_url + 'rules-right', ruleRights);
  }

  public removeRightFromRules(ruleRights: RulesRight): Observable<any> {
    return this.http.doPut(environment.business_rule_api_url + 'remove-rules-right', ruleRights);
  }

  async getBusinessRuleRights(ruleId: number): Promise<any[]> {
    return await this.http.doGet(environment.business_rule_api_url + ruleId + '/rights-minified').toPromise();
  }

}
