import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import User from 'src/app/shared/model/user/user';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import { AuthenticationService } from 'src/app/service/auth/authentication.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {DsHeaderUserInfo} from '@bmw-ds/components';
import {delay, map, Observable, of, share} from 'rxjs';
import {environment} from 'src/environments/environment';
import {I18nProvider} from '../../../service/translations/i18n.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  loggedInUser: User;
  test = false;
  lookedUpUser: BmwPerson;
  localeID = localStorage.getItem('selectedLanguage');
  userInfo$?: Observable<Partial<DsHeaderUserInfo>>;
  localeOptions = [
    { id: 'de', label: 'de', language: 'German' },
    { id: 'en', label: 'en', language: 'English' }

  ];
  aboutText = '';


  constructor(private userHttpService: UserHttpService,
              private authenticationService: AuthenticationService,
              private lookupService: LookupHttpService, private sweetAlertService: SweetAlertService,
              private translateService: I18nProvider) { }

  ngOnInit(): void {
    this.test = true;
    this.userHttpService.getLoggedInUser(true).subscribe(res => {
      this.loggedInUser = res;
      this.lookupService.getUser(this.loggedInUser.qnumber).subscribe(resp => {
        this.lookedUpUser = resp;
        this.userInfo$ = this.getInfoFromUserInfoEndpoint(this.lookedUpUser);
      });
    });
  }

  getInfoFromUserInfoEndpoint(user: BmwPerson): Observable<Partial<DsHeaderUserInfo>> {
    const userInfo = {
      username: user?.givenName + ' ' + user?.sn,
      strongAuth: true,
      department: user?.departmentNumber,
    };
    return of(userInfo).pipe(
      delay(500),
      share(),
      map((info) => ({ username: info.username, department: info.department, strongAuth: info.strongAuth }))
    );
  }


changeLocale(localeID) {
    if (localeID) {
      this.translateService.setLanguage(localeID);
    }
    if (localeID === 'en'){
      this.aboutText = 'About';
    }else{
      this.aboutText = 'Informationen';
    }
  }

  about(){
    window.location.href = environment.about_url;
  }

  handleLogout() {
    this.authenticationService.logout().subscribe( () => {
      this.authenticationService.clearStorages();
    }, () => {
      this.sweetAlertService.showComError('We could\'nt logout you out, please try again. If this continues happening reach out to your system administrator.' );
    });
  }
}
