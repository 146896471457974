import {Component, OnInit} from '@angular/core';
import Application from 'src/app/shared/model/applications/application';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import ApplicationRightsCategory from 'src/app/shared/model/applications/application-rights-category';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SelectListOption} from '@bmw-ds/components';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import Translation from '../../../shared/model/translation';
import {I18nProvider} from '../../../service/translations/i18n.service';
import ApplicationRightsCategoryDisplayMin from '../../../shared/model/applications/application-rights-category-display-min';
import BusinessRule from "../../../shared/model/business-rules/business-rule";

@Component({
  selector: 'app-application-rights-category',
  templateUrl: './application-rights-category.component.html',
  styleUrls: ['./application-rights-category.component.scss']
})
export class ApplicationRightsCategoryComponent implements OnInit {
  auth = true;
  showNewCategoryBlock = false;
  showDisabled = false;
  newCategory = new ApplicationRightsCategory();
  selectedApplication: Application = new Application();
  showUpdateCategory = new Map<number, boolean>();
  applications: Application[];
  tableData: ApplicationRightsCategoryDisplayMin[] = [];
  applicationSelectionList: SelectListOption[];
  applicationId = new FormControl();
  columns = this.getColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  editType: 'fullRow' = 'fullRow';
  private gridApi!: GridApi;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  defaultColDef: ColDef = {
    flex: 1,
    editable: true,
  };

  rowEvents: GridOptions = {
    onRowValueChanged: (event: RowValueChangedEvent) => this.updateCategoryName(event.data),
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100},
    { id: 200},
    { id: 500},
    { id: 1000},
  ];
  constructor(private applicationHttpService: ApplicationHttpService, private sweetAlertService: SweetAlertService, private i18nProvider: I18nProvider) {
  }

  ngOnInit() {
    this.initApplications();
    this.listenToApplicationChange();
  }

  private initApplications() {
    this.applicationHttpService.getMinifiedMasterApplicationsWithRights().subscribe(applications => {
      this.applications = applications;
      this.applications.sort((a, b) => a.name.localeCompare(b.name));
      this.applicationSelectionList = applications.map(application => {
        return {id: application.id.toString(), label: application.name};
      }).sort((a, b) => a.label.localeCompare(b.label));
      this.tableData = this.setTableData();
    }, (error => {
      this.sweetAlertService.showComError('Error retrieving BusinessCategories: ' + JSON.stringify(error));
    }));
  }

  addCategory() {
    this.selectedApplication.applicationRightsCategories.push(this.newCategory);
    this.applicationHttpService.updateApplication(this.selectedApplication).subscribe(success => {
      this.newCategory = new ApplicationRightsCategory();
      this.selectedApplication = new Application();
      this.applicationId.reset();
      this.showNewCategoryBlock = false;
      this.initApplications();
    }, error => {
      this.sweetAlertService.showComError(error);
    });
  }

  // updateApplication(application: Application, category: ApplicationRightsCategory) {
  //   this.applicationHttpService.updateApplicationCategoryName(category).subscribe(() => {
  //     this.showUpdateCategory.set(category.id, false);
  //     this.initApplications();
  //   }, (error) => {
  //     this.sweetAlertService.showComError('Error updating application rights category: ' + JSON.stringify(error));
  //   });
  //
  // }

  updateCategoryName(rowObject: any) {
    let applicationCategoryName = new ApplicationRightsCategory();
    let data = rowObject;
    applicationCategoryName.id = data.application_categories_id;
    applicationCategoryName.name = data.application_categories_name;
    this.applicationHttpService.updateApplicationCategoryName(applicationCategoryName).subscribe(() => {
      this.showUpdateCategory.set(applicationCategoryName.id, false);
      this.initApplications();
    }, (error) => {
      this.sweetAlertService.showComError('Error updating application rights category: ' + JSON.stringify(error));
    });

  }

  listenToApplicationChange(){
    this.applicationId.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe(applicationId => {
        if (applicationId){
          this.selectedApplication = this.applications.find(application => application.id === +applicationId);
        }
      });
  }
  setTableData(): any[] {
    const dataRows: ApplicationRightsCategoryDisplayMin[] = [];
    if (this.applications) {
      this.applications.forEach(applicationCategory => {
        if (applicationCategory.applicationRightsCategories){
          applicationCategory.applicationRightsCategories.forEach(applicationRightsCategories => {
            let displayApplicationCategories = new ApplicationRightsCategoryDisplayMin();
            displayApplicationCategories.application_rights_category_id = applicationCategory.id;
            displayApplicationCategories.application_rights_category_name = applicationCategory.name;
            displayApplicationCategories.application_categories_id = applicationRightsCategories.id;
            displayApplicationCategories.application_categories_name = applicationRightsCategories.name;
            dataRows.push(displayApplicationCategories);
            displayApplicationCategories = null;
          });
        }
      });
    }
    return dataRows;
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  getColumns(){
    return [
      {field: 'application_rights_category_name', headerName: 'Application', width: 10, filter: true, sortable: true, editable: false, rowGroup: true, hide: true },
      {field: 'application_categories_name', headerName: 'Application Category', width: 200, filter: true, sortable: true},
    ];
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_translations_master_admin_pagination')) {
      return localStorage.getItem('ara_translations_master_admin_pagination');
    }
    return 200;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_translations_master_admin_pagination', size);
    this.paginationSize = size;
  }
}
