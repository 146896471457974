import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateFormat {

  constructor(private datePipe: DatePipe) {
  }

  getDateDisplay(date: string) {
    if (date) {
      return this.datePipe.transform(new Date(date), 'dd/MM/yyyy');
    }
    return '';
  }
}
