import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationService} from "./authentication.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (AuthenticationService.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          Authorization: AuthenticationService.getAccessToken() ? ('Bearer ' + AuthenticationService.getAccessToken()) : ''
        }
      });
    }
    return next.handle(request)
      .pipe(
        catchError(
          (error: any, caught: Observable<HttpEvent<any>>) => {
            if (error.status === 401) {
              this.router.navigate(['login']);
            }
            throw error;
          }
        ),
      );
  }
}
