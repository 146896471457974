import { Injectable } from '@angular/core';
import { AraHttpService } from './ara-http.service';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import User from 'src/app/shared/model/user/user';
import { HttpParams } from '@angular/common/http';
import GrantRole from 'src/app/shared/model/user/grant-role';
import { UserRole } from 'src/app/shared/enums/user-role';
import {map} from 'rxjs';
import Region from '../../shared/model/requests/region';
import UserDisplay from '../../shared/model/user/user-display';
import UserMinimised from '../../shared/model/user/user-minimised';
import UserDisplayRegionRightsManager from '../../shared/model/user/user-display-region-rights-manager';
import {user} from "@bmw-ds/components/spriteTree";

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {
  static TOKEN_RESPONSE = 'TOKEN_RESPONSE';
  private http: AraHttpService;
  userObservable: Observable<User>;
  usersObservable: Observable<UserDisplay[]>;
  approverUsersObservable: Observable<UserDisplay[]>;

  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getUser(qNumber: string): Observable<User> {
    return this.http.doGet(environment.user_api_url + encodeURIComponent(qNumber));
  }

  public getManagedUser(qNumber: string): Observable<User> {
    return this.http.doGet(environment.user_api_url + 'managed-user/' + encodeURIComponent(qNumber));
  }

  public grantRole(regionId: number, qNumber: string, role: UserRole, rightId?: number): Observable<User> {
    const grantRole = new GrantRole(qNumber, regionId, role, rightId, [], "", true);
    return this.http.doPut(environment.user_grant_role_api_url, grantRole);
  }

  public grantRoleRegionRightsManager(regionId: number, qNumber: string, role: UserRole, rightId?: number,
                                      applicationIDs?: number[], grantedBy?: string, requestAccess?: boolean): Observable<User> {
    const grantRole = new GrantRole(qNumber, regionId, role, rightId, applicationIDs, grantedBy, requestAccess);
    return this.http.doPut(environment.user_grant_role_api_url, grantRole);
  }

  public getUsersByRoleAndRegion(userRole?: UserRole, regionId?: number): Observable<User[]> {
    const httpParams = new HttpParams().set('regionId', String(regionId)).set('role', userRole.toString());
    return this.http.doGetParams(environment.user_api_url, httpParams);
  }

  public getRightsManagerByRoleAndRegion(userRole?: UserRole, regionId?: number): Observable<UserDisplayRegionRightsManager[]> {
    const httpParams = new HttpParams().set('regionId', String(regionId)).set('role', userRole.toString());
    return this.http.doGetParams(environment.user_api_url + 'region-rights-managers/', httpParams);
  }

  public getUsersByRole(userRole: UserRole): Observable<User[]> {
    const httpParams = new HttpParams().set('role', userRole.toString());
    return this.http.doGetParams(environment.user_api_url, httpParams);
  }

  public removeRoleFromUser(qNumber: string, userRole: UserRole, regionId?: number) {
    let path = environment.user_remove_role_api_url + encodeURIComponent(qNumber) + '/' + userRole.toString() + '/';
    if (regionId) {
      path += regionId;
    }
    return this.http.doDelete(path);
  }

  public getLoggedInUser(useCached: boolean = true): Observable<User> {
    const tokenResponse = sessionStorage.getItem(UserHttpService.TOKEN_RESPONSE);
    if (tokenResponse && useCached) {
      const user = JSON.parse(tokenResponse).user;
      return of(user);
    }
    else if (!this.userObservable)  {
      this.userObservable = this.http.doGet(environment.user_api_url + 'logged-in/').pipe(
        map((user: User) => {
          this.userObservable = of(user);
          UserHttpService.setApproverToDoCount(user);
          return user;
      }));
    }
    let myDelegates = null;

    try {
      const parsedToken = JSON.parse(tokenResponse);
      if (parsedToken.user && Array.isArray(parsedToken.user.myDelegates) && parsedToken.user.myDelegates.length > 0) {
        myDelegates = parsedToken.user.myDelegates[0];
        localStorage.setItem('isDeputy', myDelegates.email);
      } else {
        localStorage.setItem('isDeputy', myDelegates);
      }
    } catch (error) {
      console.log(error);
    }
    return this.userObservable;
  }

  public getAllUsers(): Observable<UserDisplay[]> {
   if (!this.usersObservable)  {
      this.usersObservable = this.http.doGet(environment.user_api_url + 'get-all-users/').pipe(
        map((users: UserDisplay[]) => {
          this.usersObservable = of(users);
          return users;
        }));
    }
    return this.usersObservable;
  }

  public getAllApproverUsers(): Observable<UserDisplay[]> {
    if (!this.approverUsersObservable)  {
      this.approverUsersObservable = this.http.doGet(environment.user_api_url + 'get-all-approver-users/').pipe(
        map((users: UserDisplay[]) => {
          this.approverUsersObservable = of(users);
          return users;
        }));
    }
    return this.approverUsersObservable;
  }

  public addNewTeamMember(qNumber: string): Observable<UserDisplay> {
    return this.http.doPut(environment.user_api_url + 'add-new-team-member/' + encodeURIComponent(qNumber), '{}');
  }

  public assignDeputy(qNumber: string): Observable<UserMinimised> {
    return this.http.doPut(environment.user_api_url + 'deputy/' + encodeURIComponent(qNumber), '{}');
  }

  public deleteDeputy(approverDeputyId: any): Observable<any> {
    return this.http.doDelete(environment.user_api_url + 'deputy/' + encodeURIComponent(approverDeputyId));
  }

  public updateUser(user: User): Observable<any> {
    return this.http.doPut(environment.user_api_url, user);
  }

  public getCurrentUserRegions(): Observable<Region[]> {

    const regionList: Map<number, Region> = new Map<number, Region>();
    let regions: Region[] = [];

    return new Observable<Region[]>((subscriber) => {
        this.http.doGet(environment.user_api_url + 'logged-in/').subscribe((user: User) => {
            const UserRegions = user.roles.filter(role => role.name === UserRole.ARA_REGION_ADMIN).map(value => value.region);
            UserRegions.forEach(region => {
              if (region?.active && region?.id){
                regionList.set(region?.id, region);
              }
            });
            regions = [...regionList.values()];
            regions.sort((a, b) => a?.name.localeCompare(b?.name));

            subscriber.next(regions);
          });
      });
  }

  private static setApproverToDoCount(user: User) {
    localStorage.setItem('ara_approver_todo_count', user.approverCount.toString());
  }

  public resetUserObservable() {
    this.userObservable = null;
  }
}

