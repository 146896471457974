<ds-header
  class="header"
  [(localeID)]="localeID"
  [localeOptions]="localeOptions"
  (localeIDChange)="changeLocale($event)"
  [userInfo]="(userInfo$ | async) ?? {}"
  (requestLogout)="handleLogout()"
  [menuTemplate]="extraOptionsTemplate"
>
  <ng-template #extraOptionsTemplate>
  <ds-list-item icon="information" [isHoverable]="true" (click)="about()">{{aboutText}}</ds-list-item>
</ng-template>
</ds-header>
