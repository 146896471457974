  <ds-box-header>
    <span class = "col-12 d-flex justify-content-end">
      <ds-icon icon="close" size="md" tone="action" (click)="closeModal()" class="modal-btn"></ds-icon>
    </span>
    <span class = "modal-header-title-md">
      {{request?.type}}
    </span>
  </ds-box-header>
  <ds-box-content class="panel">
    <div class = "row">
      <div class = "col-4">
        <ds-form-field>
          <label ds-label for="type-request">Type:</label>
          <input ds-input id="type-request" type="text" readonly="true"  required="false" value="{{request?.type}}" readonly>
        </ds-form-field>
        <ds-form-field class="mt-3">
          <label ds-label for="category-r">Category:</label>
          <input ds-input id="category-r" required = "false" readonly="true" type="text" value="{{request?.category}}" readonly/>    </ds-form-field>
        <ds-form-field class="mt-3">
          <label ds-label for="rule-l">Rule:</label>
          <input ds-input id="rule-l" required = "false" readonly="true" type="text" value="{{request?.rule}}" readonly/> </ds-form-field>
      </div>
      <div class = "col-4">
        <ds-form-field>
          <label ds-label>Region:</label>
          <input ds-input id="region-request" readonly="true" type="text" required="false" value="{{request?.regionName}}" readonly/> </ds-form-field>
        <ds-form-field class="mt-3">
          <label ds-label>{{'' | i18n: 'start_date'}}:</label>
          <input ds-input id="startDate-r" required = "false" readonly="true" type="text" value="{{request?.startDate | date: 'dd.MM.yyyy'}}" readonly/>  </ds-form-field>
        <ds-form-field class="mt-3">
          <label ds-label for="Locations-r">{{'' | i18n: 'locations'}}: </label>

          <textarea ds-input id="locations-r" readonly="true" required = "false" rows="5"  value="{{getLocationsForRequest(request)}}" readonly></textarea>
        </ds-form-field>
      </div>
      <div class = "col-4">
        <ds-form-field>
          <label ds-label for="status-request">{{'' | i18n: 'status'}}:</label>
          <input ds-input id="status-request" type="text" readonly="true" required="false" value="{{request?.status}}" readonly/>
        </ds-form-field>
        <ds-form-field  class="mt-3">
          <label ds-label>{{'' | i18n: 'end_date'}}:</label>
          <input ds-input id="endDate-r" required = "false" readonly="true" type="text" value="{{request?.endDate | date: 'dd.MM.yyyy'}}" readonly/>
        </ds-form-field>
        <ds-form-field  class="mt-3">
          <label ds-label for="version-r">Version:</label>
          <input ds-input id="version-r" required = "false" readonly="true" type="text" readonly/>
        </ds-form-field>
        <ds-form-field  class="mt-3">
          <label ds-label for="approver-qnumber">{{'' | i18n: 'approver'}}:</label>
          <input ds-input id="approver-qnumber" required = "false" readonly="true"
                 [value]="setApprover()" type="text" readonly/>
        </ds-form-field>
      </div>
    </div>
    <div class="row mt-4">
      <ds-accordion>
        <ds-accordion-item>
          <span class="d-flex">
              <ds-icon icon="message_comment"></ds-icon>
              <label ds-label class="pl-2">{{'' | i18n: 'comments'}} ({{request.comments.length}})</label>
          </span>
          <div content class="comment-list">
            <ds-list-item *ngFor ="let comment of request.comments">
              <div>
                <div class="d-flex">
                  <span class="initial-avatar">{{searchEmployee(comment.createdBy)?.givenName | slice:0:1}} {{searchEmployee(comment.createdBy)?.sn | slice:0:1}}</span>
                  <div class="mt-2 pl-2">
                  <span>
                <b> {{searchEmployee(comment.createdBy)?.givenName}}
                  {{searchEmployee(comment.createdBy)?.sn}}</b> ({{comment.createdBy}})</span>
                    <br>
                    <span style="font-size: 8pt !important;">{{comment.date | date : 'short'}}</span>
                    <br>
                    <br>
                    <span >{{comment.comment}}</span>
                  </div>
                </div>

              </div>
            </ds-list-item>
          </div>
        </ds-accordion-item>
      </ds-accordion>


    </div>
    <div class = "row">
      <div class = "col-12 mt-3 mb-2">
        <table class="table">
          <thead>
          <tr>
            <th [colSpan]="applicationsLength - 1">{{'' | i18n: 'applications'}}</th>
            <th><a href="https://atc.bmwgroup.net/confluence/pages/viewpage.action?pageId=3816957951" target="_blank">Übersicht Applikationsverantwortliche</a></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td *ngFor="let application of applications">
            <span class="right-check" [ngClass]="application.active ? 'approved' : 'rejected' ">{{application.name}}
              <ds-switch class ="ml-3" [(ngModel)]="application.active"  disabled>
            </ds-switch></span>
              <span *ngFor="let admin of application?.rightsManagerHistoryItem" >
            <ds-badge [tone]="getTone(admin)" label="{{admin.employeeId}} -    {{admin.date | date:'short'}}"></ds-badge>
            </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class = "row">
      <div class = "col-12 mt-3 mb-2">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">{{'' | i18n: 'application'}}<br />
                <ds-form-field class = "mt-2">
                  <ds-select
                    id="application"
                    [formControl]="applicationNameFilterValue"
                    [labellingConfig]="{ placeholder: 'Select' }"
                    [options]="applicationNameOptions"
                    [showClearAll]="true"
                  ></ds-select>
                </ds-form-field>
            </th>
            <th scope="col">Application Category<br />
                <ds-form-field class = "mt-2">
                  <ds-select
                    id="category"
                    [formControl]="applicationCategoryValue"
                    [labellingConfig]="{ placeholder: 'Select' }"
                    [options]="applicationCategoryOptions"
                    [showClearAll]="true"
                    [readonly]="!applicationNameFilterValue.value"
                  ></ds-select>
                </ds-form-field>
            </th>
            <th scope="col">{{'' | i18n: 'right'}}<br />
                  <ds-form-field class = "mt-2">
                    <ds-select
                      id="rights"
                      [(ngModel)]="applicationRightValue"
                      [labellingConfig]="{ placeholder: 'Select' }"
                      [options]="applicationRightOptions"
                      [showClearAll]="true"
                      [readonly]="!applicationCategoryValue.value"
                    ></ds-select>
                  </ds-form-field>
            </th>

          </tr>
          </thead>
          <tbody>

          <tr *ngFor="let right of request.applications | rightsDisplayFilter : applicationNameFilterValue.value : applicationCategoryValue.value : applicationRightValue  ">
            <td>{{right?.application}}</td>
            <td>{{right?.applicationCategory}}</td>
            <td>{{right?.right}}</td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </ds-box-content>
