import { Pipe, PipeTransform } from '@angular/core';
import GeneratedRights from 'src/app/components/popup/request-details/request-details.component';

@Pipe({
  name: 'rightsDisplayFilter'
})
export class RightsRequestDisplayPipe implements PipeTransform {

  transform(rights: any[], applicationName: string, applicationCategoryName: string, applicationRightName: string) {
  if (rights) {
      return rights.filter(right => {
        return ((applicationName ? right?.application === applicationName : true) &&
        (applicationCategoryName ? right?.applicationCategory === applicationCategoryName : true) &&
        (applicationRightName ? right?.right === applicationRightName : true));
      });
    }
  return rights;
  }

}
