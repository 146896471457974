import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {DateFormat} from 'src/app/shared/commons/dateFormat';
import {RequestStatusUtilsService} from 'src/app/service/http/request-status-utils.service';
import {RequestBuilderService} from 'src/app/service/helper-services/request-builder.service';
import {DialogService} from 'src/app/service/modal/dialog.service';
import {I18nProvider} from 'src/app/service/translations/i18n.service';
import RequestsNew from '../../../shared/model/requests/requests-new';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {RequestDetailsNewComponent} from '../../../components/popup/request-details-new/request-details-new.component';

@Component({
  selector: 'app-approver-completed-dashboard',
  templateUrl: './approver-completed-dashboard.component.html',
  styleUrls: ['./approver-completed-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApproverCompletedDashboardComponent implements OnInit {
  openRequestsForUser: RequestsNew[];
  selectedRequest: RequestsNew;
  qNumber: number;
  columns = this.getTableColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<RequestsNew>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedRequest = event.data;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedRequest = event.data;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedRequest = null;
    }
  };

  constructor(private activatedRoute: ActivatedRoute, private requestHttpService: RequestHttpService,
              private dateFormat: DateFormat, public router: Router,
              private requestBuilderService: RequestBuilderService, private dialog: DialogService,
              private requestStatusUtilsService: RequestStatusUtilsService, private i18nProvider: I18nProvider) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.qNumber = this.router.getCurrentNavigation().extras.state.qNumber;
      this.openRequestsForUser = this.router.getCurrentNavigation().extras.state.userRequests;
      this.openRequestsForUser.forEach(request => {
        request.endDate = new Date(request.endDate).toLocaleDateString();
        request.startDate = new Date(request.startDate).toLocaleDateString();
        request.status_enum = request.status;
        request.status = this.requestStatusUtilsService.getStatusNameByStatusEnum(request.status);
      });
    }
  }

  ngOnInit(): void {
    if (this.openRequestsForUser) {
    } else {
      this.router.navigate(['/approver-completed-request']);
    }
  }
  showRequestDetails(req: RequestsNew) {
    req.applications.sort((a, b) => a.application.localeCompare(b.application));
    this.dialog.open(RequestDetailsNewComponent, {data: req});
  }

  getTableColumns(){
    return [
      {field: 'regionName', headerName: this.i18nProvider.getDescription('region_name'), filter: true, sortable: true},
      {field: 'category', headerName: this.i18nProvider.getDescription('category'), filter: true, sortable: true},
      {field: 'rule', headerName: this.i18nProvider.getDescription('rules_or_rights'), filter: true, sortable: true, width: 300},
      {field: 'startDate', headerName: this.i18nProvider.getDescription('start_date'), filter: true, sortable: true},
      {field: 'endDate', headerName: this.i18nProvider.getDescription('end_date'), filter: true, sortable: true},
      {
        field: 'status', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true, width: 285,
        cellClass: params => {
          return this.getReqStatusStyle(params.data.status_enum);
        },
      },
    ];
  }
  getReqStatusStyle(status: string) {
    return this.requestStatusUtilsService.getStatusStyleByStatusName(status);
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<RequestsNew>) {
    this.gridApi = params.api;
  }
}
