<div *ngIf="selectedApplication">
  <div class = "table-scroller">
    <table class="table mb-5" >
      <thead>
      <tr>
        <th scope="col">App Category
        </th>
        <th scope="col">App Right
        </th>
        <th scope="col">{{'' | i18n: 'status'}}
        </th>
        <th scope="col">{{'' | i18n: 'start_date'}}
        </th>
        <th scope="col">{{'' | i18n: 'end_date'}}</th>
        <th scope="col">Type</th>
        <th scope="col">Location</th>
        <th scope="col">Done</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let right of selectedApplicationRights">
        <td>{{getRightApplicationCategoryName(right)}}</td>
        <td>{{right.name}}</td>
        <td [ngClass]="right.active ? 'active' : 'inactive' ">{{getRightStatus(right.active)}}</td>
        <td>{{selectedRequest.startDate | date: 'dd/MM/yyyy'}}</td>
        <td>{{selectedRequest.endDate | date: 'dd/MM/yyyy'}}</td>
        <td>{{selectedRequest.rule}}</td>
        <td>{{setLocationsForDisplay(selectedRequest?.locations)}}</td>
        <td [ngClass]="right.active ? 'approved' : 'rejected' "> <ds-switch [(ngModel)]="right.active" disabled></ds-switch></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
