import {Component, OnInit} from '@angular/core';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import User from 'src/app/shared/model/user/user';
import Region from 'src/app/shared/model/requests/region';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {UserRole} from 'src/app/shared/enums/user-role';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import {FormControl} from '@angular/forms';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import UserDisplayRegionRightsManager from '../../../shared/model/user/user-display-region-rights-manager';
import {ApplicationHttpService} from '../../../service/http/application-http.service';
import ApplicationMinified from '../../../shared/model/applications/application-min';

@Component({
  selector: 'app-rights-manager',
  templateUrl: './rights-manager.component.html',
  styleUrls: ['./rights-manager.component.scss']
})
export class RightsManagerComponent implements OnInit {
  modalIsOpen = false;
  showRightManager = false;
  showNewRightManagerDetails = false;
  showSearchResults = false;
  newRegionId: number;
  private userHttpService: UserHttpService;
  private regionHttpService: RegionHttpService;
  private sweetAlertService: SweetAlertService;
  private lookupHttpService: LookupHttpService;
  private applicationHttpService: ApplicationHttpService;
  users: UserDisplayRegionRightsManager[] = [];
  rightsManager: User;
  newQNumber: string;
  selectedRegion: Region;
  selectedRegionApplicationsMin: ApplicationMinified[] = [];
  selectedApplicationId: number[] = [];
  loggedInUserRegions: Region[];
  loggedInUser: string;
  columns = [];
  editMode = false;
  newRightsManager: User;
  rightsManagers: UserDisplayRegionRightsManager[];
  bmwPeopleMap: Map<string, BmwPerson> = new Map<string, BmwPerson>();
  selectedRegionId = new FormControl();
  rowSelection: 'single' | 'multiple' = 'single';
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  gridApi!: GridApi<UserDisplayRegionRightsManager>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.newRightsManager = event.data;
      this.editMode = true;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.newRightsManager = event.data;
      this.editMode = true;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.newRightsManager = null;
      this.editMode = false;
    }
  };
  regionChanged = false;
  isLinked = true;
  constructor(userHttpService: UserHttpService, regionHttpService: RegionHttpService,
              sweetAlertService: SweetAlertService, lookupHttpService: LookupHttpService, private i18nProvider: I18nProvider,
              applicationHttpService: ApplicationHttpService) {
    this.userHttpService = userHttpService;
    this.regionHttpService = regionHttpService;
    this.sweetAlertService = sweetAlertService;
    this.lookupHttpService = lookupHttpService;
    this.applicationHttpService = applicationHttpService;
  }

  ngOnInit() {
    this.userHttpService.getCurrentUserRegions().subscribe(regions => {
      this.loggedInUserRegions = regions;
    });

    this.userHttpService.getLoggedInUser(true).subscribe(user => {
      this.loggedInUser = user.qnumber;
    });
    this.getColumns();

  }

  private getRightsAdminsForSelectedRegion() {
    if (this.selectedRegion) {
      this.userHttpService.getRightsManagerByRoleAndRegion(UserRole.ARA_RIGHTS_ADMIN, this.selectedRegion.id).subscribe(
        successResponse => {
          this.regionChanged = true;
          this.rightsManagers = successResponse;
          this.gridApi.setRowData(this.rightsManagers);
        },
        errorResponse => this.sweetAlertService.showComError('Error retrieving users. ' + JSON.stringify(errorResponse))
      );
    }
  }

  getRegionMinifiedApplications(edit: boolean) {
    if (this.regionChanged) {
      this.editMode = edit;
      this.regionChanged = false;
      this.applicationHttpService.getApplicationsMinPerRegion(this.selectedRegion).subscribe(regionApps => {
        this.selectedRegionApplicationsMin = regionApps;
        this.selectedRegionApplicationsMin.forEach(value => {
          if (this.newRightsManager.linkedApplicationsIDs.includes(value.id)) {
            value.selected = true;
            this.selectedApplicationId.push(value.id);
          }
        });
      });
    }
  }

  showModalForNewRightsManager() {
    this.showSearchResults = false;
    this.selectedApplicationId = [];
    this.newQNumber = "";
    this.getRegionMinifiedApplications(false);
  }

  onModalClose() {
    this.showSearchResults = false;
    this.selectedApplicationId = [];
    this.newQNumber = null;
    this.editMode = false;
    this.modalIsOpen = false;
  }

  showModalForEdit() {
    this.newQNumber = this.newRightsManager.qnumber;
    this.newRightsManager.name = this.newRightsManager.fullName;
    this.showSearchResults = true;
    this.editMode = false;
    this.selectedApplicationId = [];
    this.regionChanged = true;
    this.getRegionMinifiedApplications(false);
  }

  updateRightsManager() {
    this.userHttpService.grantRoleRegionRightsManager(this.selectedRegion.id, this.newQNumber, UserRole.ARA_RIGHTS_ADMIN, null,
      this.selectedApplicationId, this.loggedInUser, this.isLinked)
      .subscribe(() => {
          this.modalIsOpen = false;
          //this.selectedRegion = null;
          this.getRightsAdminsForSelectedRegion();
          // this.users.push(success);
          this.showNewRightManagerDetails = false;
          this.showRightManager = false;
          this.showSearchResults = false;
        },
        () => this.sweetAlertService.showComError('Error granting permission.'));
    this.showRightManager = false;
  }

  changeRegion() {
    this.showSearchResults = false;
    this.getRightsAdminsForSelectedRegion();
  }

  searchNewQnumber() {
    this.userHttpService.getUser(this.newQNumber).subscribe(value => {
      this.newRightsManager = value;
      this.showSearchResults = true;
    });
  }

  delete(){
    this.userHttpService.removeRoleFromUser(this.newRightsManager.qnumber, UserRole.ARA_RIGHTS_ADMIN, this.selectedRegion.id).subscribe(() => {
      this.rightsManagers = this.rightsManagers.filter(rm => rm.id !== this.newRightsManager.id);
      this.gridApi.setRowData(this.rightsManagers);
      this.editMode = false;
      this.showSearchResults = false;
      this.newRightsManager = null;
    });
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<UserDisplayRegionRightsManager>) {
    this.gridApi = params.api;
  }

  getColumns(){
    this.columns = [
      {field: 'fullName', headerName: this.i18nProvider.getDescription('surname_name'), filter: true, sortable: true, width: 300},
      {field: 'qnumber', headerName: this.i18nProvider.getDescription('q_number'), filter: true, sortable: true, width: 200},
      {field: 'departmentCode', headerName: this.i18nProvider.getDescription('department_code'), filter: true, sortable: true, enableRowGroup: true,
        width: 200},
      {field: 'email', headerName: this.i18nProvider.getDescription('email'), filter: true, sortable: true, width: 300},
      {field: 'regionName', headerName: this.i18nProvider.getDescription('region_name'), filter: true, enableRowGroup: true, sortable: true, width: 200},
      {field: 'linkedApplications', headerName: 'Linked Applications', filter: true, sortable: true, width: 400}];

  }

  setRightsManagerApps(event: any, appId: number) {
    if (event.target.checked) {
      this.selectedApplicationId.push(appId);
    } else {
      const index = this.selectedApplicationId.indexOf(appId);
      if (index !== -1) {
        this.selectedApplicationId.splice(index, 1);
      }
    }
  }

  hasLinkedApplications(): boolean {
    if (this.newRightsManager.linkedApplicationsIDs == null) {
      return false;
    }
    return this.newRightsManager.linkedApplicationsIDs.length > 0;
  }

  setApplication(appId: number): boolean {
    if (this.hasLinkedApplications()) {
      if (this.newRightsManager.linkedApplicationsIDs.includes(appId)) {
        // if (!this.selectedApplicationId.includes(appId)) {
        //   this.selectedApplicationId.push(appId);
        // }
        return true;
      }
    }
    return false;
  }
}
