import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const href = window.location.href;
const url = new URL(href);
const authCode = url.searchParams.get('code');

const TOKEN_RESPONSE = 'TOKEN_RESPONSE';

function useLoginResponse(responseText, status) {
  window.sessionStorage.setItem(TOKEN_RESPONSE, responseText);
  try {
    environment.tokenResponse = JSON.parse(responseText);
  } catch (e) {
    environment.tokenResponse = null;
  }
  if (status !== 200 || !environment.tokenResponse || !environment.tokenResponse.accessToken ||
    !environment.tokenResponse.accessToken.access_token) {
    environment.login_msg = 'FAIL';
  } else {
    environment.loggedInUser = environment.tokenResponse.user;

  }
}

function bootstrapApp() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
}

if (authCode || !environment.skip_iwa) {
// auth code login
  if (authCode) {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 || (this.status !== 0 && this.status !== 200)) {
        useLoginResponse(this.responseText, this.status);
        bootstrapApp();
      }
    };

// The call to login the user is made async because if it is made sync then it breaks the top navigation's drop downs. It appears as
// though nothing should load before this call has completed.
    xhttp.open('POST', environment.ara_login_url, false);
    xhttp.setRequestHeader('Content-type', 'application/json');

    const data = {
      authCode: authCode,
      grantType: 'password',
      clientId: environment.clientId,
    };
    try {
      xhttp.send(JSON.stringify(data));
    } catch (e1) {
      console.log(e1);
      try {
        alert(e1.name);
      } catch (e2) {
        console.log(e2);
      }
      environment.login_msg = 'FAIL';
      bootstrapApp();
    }

  } else if (window.sessionStorage.getItem(TOKEN_RESPONSE)) {
    // check if logged in
    useLoginResponse(window.sessionStorage.getItem(TOKEN_RESPONSE), 200);
    bootstrapApp();

  } else {
    bootstrapApp();
  }

} else {

  const xhttp = new XMLHttpRequest();

  if (environment.token && environment.username && environment.uid) {
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 || (this.status !== 0 && this.status !== 200)) {
        // console.log('starting app');
        environment.tokenResponse = JSON.parse(this.responseText);
        if (this.status !== 200 || !environment.tokenResponse || !environment.tokenResponse.accessToken ||
          !environment.tokenResponse.accessToken.access_token) {
          environment.login_msg = 'FAIL';
        } else {
          window.sessionStorage.setItem(TOKEN_RESPONSE, this.responseText);
          environment.loggedInUser = environment.tokenResponse.user;
        }
        bootstrapApp();
      }
    };
// The call to login the user is made async because if it is made sync then it breaks the top navigation's drop downs. It appears as
// though nothing should load before this call has completed.
//     xhttp.open('POST', environment.application_api_url + '/login', false);
    xhttp.open('POST', environment.ara_login_post_url , false);
    xhttp.setRequestHeader('Content-type', 'application/json');

    const tokenResponse = JSON.parse(window.sessionStorage.getItem('TOKEN_RESPONSE'));
    const data = {
      username: environment.username,
      clientId: environment.clientId,
      sessionId: environment.token,
      oidcToken: tokenResponse?.oidcToken
    };
    try {
      xhttp.send(JSON.stringify(data));
      // xhttp.send();
    } catch (e1) {
      console.log(e1);
      try {
        alert(e1.name);
      } catch (e2) {
        console.log(e2);
      }
      environment.login_msg = 'FAIL';
      bootstrapApp();
    }
  } else {
    bootstrapApp();
  }
}


