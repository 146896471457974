import User from '../user/user';
import Right from '../rights/right';
import BusinessRule from '../business-rules/business-rule';
import Region from './region';
import Location from './location';
import Comment from './comment';
import RequestStatus from './request_status';
import LocationMinified from './location-min';
import BusinessRuleMinified from '../business-rules/business-rule-min';
import RightMinified from '../rights/right-min';

export default class Request {
  id:	number;
  requester: User;
  requestedFor: User;
  approver: User;
  approverApproved: boolean;
  specialRightsApprover: User;
  specialRightsApproverApproved: boolean;
  complianceManager: User;
  complianceManagerApproved: boolean;
  rightsManager: User;
  active: boolean;
  rights: Right[] | RightMinified[]  | any[] = [];
  rightDTO: Right;
  businessRules: BusinessRule[] | BusinessRuleMinified[] = [];
  businessRuleDTO: BusinessRule;
  comments: Comment[];
  region: Region;
  locations: Location[] | LocationMinified[] | string[];
  createdBy:	string;
  modifiedBy:	string;
  startDate:	Date;
  endDate:	Date;
  requestStatus: RequestStatus;
  requestedDate:	string;
  grantedDate:	string;
  selected = false;
  newApproverComment: string;
  delete = false;
  change = false;
  type?: string;
  region_name: string;
  start_date_display: string;
  end_date_display: string;
  capturedByApprover: boolean;
}
