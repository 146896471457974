import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import GeneratedRequest from 'src/app/shared/model/requests/generated-request.interface';
import { DialogRef } from 'src/app/service/modal/dialog-ref';
import { DIALOG_DATA } from 'src/app/service/modal/dialog-tokens';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {formatDate} from '@angular/common';
import {LookupHttpService} from '../../../service/http/lookup-http.service';
import BmwPerson from '../../../shared/model/user/bmw-person';
import {RequestHttpService} from '../../../service/http/request-http.service';

export default class  GeneratedRights {
  applicationName?: string;
  applicationCategory?: string;
  applicationRight?: string;
}

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.css']
})
export class RequestDetailsComponent implements OnInit {
  applicationNameOptions: SelectListOption[] = [];
  applicationCategoryOptions: SelectListOption[] = [];
  applicationRightOptions: SelectListOption[] = [];
  bmwPeopleMap: Map<string, BmwPerson> = new Map<string, BmwPerson>();

  rights: GeneratedRights[] = [];

  applicationNameFilterValue = new FormControl();
  applicationCategoryValue = new FormControl();
  applicationRightValue: string;
  applicationId: number;

  constructor(   private dialogRef: DialogRef,
                 @Inject(DIALOG_DATA) public request: GeneratedRequest,
                 public lookupHttpService: LookupHttpService, public requestHttpService: RequestHttpService) { }

  ngOnInit(): void {
    this.requestHttpService.readUserOperation(this.request.id);
    this.request.comments.sort((a, b) => b.date.localeCompare(a.date));
    this.getApplicationNameFilterValues();
    this.getRights(this.request);
    this.getEmployees();
    this.listenToApplicationNameFilterValueChange();
    this.listenToApplicationCategoryFilterValueChange();
  }

  getRights(request: GeneratedRequest){
    this.rights = [];
    const applicationRights: Set<{applicationName: string, applicationCategory: string, applicationRight: string}>
      = new Set<{applicationName: string, applicationCategory: string, applicationRight: string}>();
    request?.applications?.map(application => {
    application.applicationRightsCategories.map(category => {
      category.rights.forEach(right => {
        applicationRights.add({applicationName: application.name , applicationCategory: category.name, applicationRight: right.name });
      });
    });
  });
    this.rights = [...applicationRights.values()];
  }

  getApplicationNameFilterValues(){
    const applicationNames: Set<{id: string, label: string}> = new Set<{id: string, label: string}>();
    this.request?.applications?.map(application => {
      applicationNames.add({id: application.name, label: application.name});
    });
    this.applicationNameOptions = [...applicationNames.values()];
  }

  listenToApplicationNameFilterValueChange(){

    this.applicationNameFilterValue.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(applicationName => {
        const applicationCategories: SelectListOption[] = [];
        this.applicationCategoryValue.reset();
        this.applicationRightValue = '';
        this.applicationCategoryOptions = [];
        this.request?.applications?.map(application => {
          if (application.name === applicationName){
           this.applicationId = application.id;
          }
          application.applicationRightsCategories.map(category => {
            category.rights.forEach(right => {
              if (right.applicationId === this.applicationId )
              {
                applicationCategories.push({id: category.name , label: category.name});
              }
            });
            this.applicationCategoryOptions = [... new Map(applicationCategories.map((ac) => [ac.id, ac])).values()];
          });
        });
      });
  }

  listenToApplicationCategoryFilterValueChange(){
    this.applicationCategoryValue.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(categoryName => {
        this.applicationRightOptions = [];
        const applicationRights: SelectListOption[] = [];
        let categoryId: number;
        this.applicationRightValue = '';
        this.request?.applications?.map(application => {
          application.applicationRightsCategories.map(category => {
            if (category.name === categoryName){
              categoryId = category.id;
              category.rights.forEach(right => {
                if (right.applicationRightsCategoryId === categoryId )
                {
                  applicationRights.push({id: right.name , label: right.name});
                }
              });
              this.applicationRightOptions = [... new Map(applicationRights.map((ar) => [ar.id, ar])).values()];
            }

          });
        });
      });
  }


  getLocationsForRequest(openRequest: GeneratedRequest) {
    return openRequest?.locations?.map(location => {
      return location.name;
    }).join('\n');
  }

  getEmployees() {
    const qnumbers: string[] = this.request.comments.map(comment => comment.createdBy);
    this.lookupHttpService.getAll(qnumbers).subscribe(bmwPersons => {
      bmwPersons.forEach(bmwPerson => {
        this.bmwPeopleMap.set(bmwPerson.uid, bmwPerson);
      });
    });
  }

  searchEmployee(qnumber: string): BmwPerson{
    return this.bmwPeopleMap.get(qnumber);
  }

  closeModal(){
    this.dialogRef.close();
  }

}
