<div class="container-costom">
  <div class="row">
    <div class="mt-5">
      <h1 class="h1-heading">
        <span class="black">Manage special rights manager</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
        <span class = "h2-header">Munchen</span>
        <button class="btn-warning " (click)="showNewRightManager=true;">{{'' | i18n: 'new_rights_manager'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>

<div class="row table_costom">
  <div class="container-costom" >
    <div class = "" *ngIf="showNewRightManager">
      <div class="form_panel">
        <div Style="display:flex" class="col-md-10">
          <div style="display: flex;">
            <select required [(ngModel)]="selectedApplication"  (change)="populateRightsDropdown()" >
              <option selected disabled [ngValue]=null>{{'' | i18n: 'select_application'}}</option>
              <option *ngFor="let application of applications" [ngValue]="application">{{application.name}}</option>
            </select>
            <select required [(ngModel)]="selectedRight">
              <option selected disabled [ngValue]=null>Select Right</option>
              <option *ngFor="let right of applicationRights" [ngValue]="right" >{{right.name}}</option>
            </select>
            <input type="text" placeholder="{{'' | i18n: 'q_number'}}" [(ngModel)]="newQNumber">
            <div class="input-group-append">
              <button  class="btn btn_serch" type="button" (click)="showSearchResults=true;">
                <em class="fa fa-search"></em>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <button type="button" class="btn btn-black float-right" (click)="addNewRightsAdmin()">{{'' | i18n: 'add'}}</button>
        </div>
      </div>
      <div class="col-md-12 search_results" *ngIf="this.showSearchResults">
        John Andersson <span style = "margin-left: 30px">JohnAndersson@bmw.com</span>
      </div>
    </div>
    <table class="table" >
      <tbody>
      <tr *ngFor="let user of this.users">
        <td><em class="fa fa-times" (click)="removeSpecialRightsManager(user)"></em></td>
        <td>{{user.qnumber}}</td>
        <td>John</td>
        <td>Anderson</td>
        <td>John.Anderson@bmw.com</td>
        <td>{{getRightForSpecialAdminRole(user)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

