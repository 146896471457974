import {Component, OnInit} from '@angular/core';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import {NavigationExtras, Router} from '@angular/router';
import RequestsForRightsManagerDto from '../../../shared/model/requests/requests-for-rights-manager-dto';
import {CellClickedEvent, ColDef, FirstDataRenderedEvent, GridOptions, RowDoubleClickedEvent} from 'ag-grid-community';
import {I18nProvider} from '../../../service/translations/i18n.service';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-rights-manager-completed',
  templateUrl: './rights-manager-completed.component.html',
  styleUrls: ['./rights-manager-completed.component.scss']
})
export class RightsManagerCompletedComponent  implements OnInit{

  groupedRequests: RequestsForRightsManagerDto[];
  columns = this.getTableColumns();
  employees: BmwPerson[];
  requests: Map<string, RequestsForRightsManagerDto> = new Map<string, RequestsForRightsManagerDto>();
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.openRightManagerDetails(event.data),
    onCellClicked: (event: CellClickedEvent) => {
    },
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];

  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  constructor(private userHttpService: UserHttpService, private requestService: RequestHttpService,
              private lookupHttpService: LookupHttpService, public router: Router, private i18nProvider: I18nProvider) {
  }

  ngOnInit(): void {
    this.initOpenRequestsForRightsManager();
    this.paginationSize = this.setPaginationSize();
  }

  private initOpenRequestsForRightsManager() {
    this.requestService.getCompletedRequestsForRightsAdmin().subscribe(requests => {
      // const qNumbers: string[] = [...new Set(requests.map(request => request.qnumber))];
      // this.lookupHttpService.getAll(qNumbers).subscribe(employees => {
      //   this.employees = employees;
      // });
      this.getTableData(requests);
    });
  }

  getTableColumns() {
    return  [
      {field: 'name', headerName: this.i18nProvider.getDescription('surname_name'), width: 300, filter: true, sortable: true},
      {field: 'qnumber', headerName: this.i18nProvider.getDescription('q_number'), width: 200, filter: true, sortable: true},
      {field: 'regionName', headerName: this.i18nProvider.getDescription('region_name'), width: 200, filter: true, sortable: true},
      {field: 'defaultLocation', headerName: this.i18nProvider.getDescription('default_location'), width: 200, filter: true, sortable: true},
      {field: 'department', headerName: this.i18nProvider.getDescription('department_code'), width: 200, filter: true, sortable: true},
      {field: 'workflowDate', headerName: this.i18nProvider.getDescription('workflow_date'), width: 200, filter: true, sortable: true, cellRenderer: (data) => {
      return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
    }},
      {field: 'appNames', headerName: this.i18nProvider.getDescription('application_name'), width: 640, filter: true, sortable: true}
    ];
  }

  getTableData(value: RequestsForRightsManagerDto[]) {
    value.forEach(item => {

      const request = this.requests.get(item.qnumber) || item;
      request.appNames = [...new Set([...request.appNames, ...item.appNames])];
      if (!request.regionShortCode.includes(item.regionShortCode)) {
        request.regionShortCode = request.regionShortCode + `, ${item.regionShortCode}`;
        request.regionName = request.regionName + `, ${item.regionName}`;
      }

      if (!this.requests.has(item.qnumber)) {
        this.requests.set(item.qnumber, request);
      }
    });

    this.groupedRequests = Array.from(this.requests.values()).map(request => ({
      ...request,
      // department: `${this.employees.find(emp => emp.uid === request.qnumber)?.departmentNumber}`,
      // name: `${this.employees.find(emp => emp.uid === request.qnumber)?.sn}, ${this.employees.find(emp => emp.uid === request.qnumber)?.givenName}`
    }));
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }
  openRightManagerDetails(openReq: RequestsForRightsManagerDto) {
    const navigationExtras: NavigationExtras = {
      state: {
        qNumber: openReq.qnumber
      },
    };
    this.router.navigate(['/rights-manager-completed-details'], navigationExtras);
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_rights_manager_completed_pagination')) {
      return localStorage.getItem('ara_rights_manager_completed_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_rights_manager_completed_pagination', size);
    this.paginationSize = size;
  }
}
