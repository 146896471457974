import Region from './region';

export default class Location {

  id: number;
  name: string;
  createdBy: string;
  modifiedBy: string;
  region: Region;
  paramOne: string;
  paramTwo: string;
  paramThree: string;
  paramFour: string;
  paramFive: string;
  paramSix: string;
  checked: boolean;
  toggledisabled = true;
  active: boolean;
  startDate: string;
  defaultLocation = false;
  status?: string;
}
