<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class = "light-orange">Approve Compliance</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-5">
      Content here...
    </div>
  </div>
</div>
