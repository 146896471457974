<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class = "light-orange">{{'' | i18n: 'manage_regions'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
        <button type="" class="btn-warning " [ngClass]="{'disabled':showNewRegionDiv}" [ds-modal-trigger-for]="add" [(isModalOpen)]="modalIsOpen">{{'' | i18n: 'add_region'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="container-costom mt-3 mb-3">
  <div class="row">
    <div  class = "col-md-12 p-0" >
      <div class="col-12 d-flex justify-content-end p-0 mb-2" *ngIf="editMode">
        <button class="green_b-tab" *ngIf="selectedRegion.active" (click)="changeStatus(false)">{{'' | i18n: 'deactivate'}}</button>
        <button class="green_b-tab" *ngIf="!selectedRegion.active" (click)="changeStatus(true)">{{'' | i18n: 'activate'}}</button>
        <button class="green_b-tab" [ds-modal-trigger-for]="update" [(isModalOpen)]="updateModalIsOpen">{{'' | i18n: 'edit'}}</button>
      </div>
      <ag-grid-angular
        style="width: 100%; height: 725px"
        class="ag-theme-alpine"
        rowHeight="36"
        [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
        [columnDefs]="columns"
        [pagination]="true"
        [suppressDragLeaveHidesColumns]="true"
        [paginationPageSize]="15"
        [rowData]="regions"
        [rowSelection]="rowSelection"
        [rowGroupPanelShow]="rowGroupPanelShow"
        (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
        (cellClicked)="rowEvents.onRowClicked($event)"
        (paginationChanged)="rowEvents.onPaginationChanged($event)"
        [columnDefs]="columns"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
<ds-modal #update size="sm">
  <ds-box-header>{{'' | i18n: 'edit'}} {{'' | i18n: 'regions'}}</ds-box-header>
  <ds-box-content *ngIf="selectedRegion">
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_name'}}</label>
      <input ds-input type="text" [(ngModel)]="selectedRegion.name" required/>
    </ds-form-field>
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_code'}}</label>
      <input ds-input type="text" [(ngModel)]="selectedRegion.shortCode" required/>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="updateRegion(selectedRegion)" [disabled]="!selectedRegion?.name">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>

<ds-modal #add size="sm">
  <ds-box-header>{{'' | i18n: 'add'}} {{'' | i18n: 'regions'}}</ds-box-header>
  <ds-box-content >
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_name'}}</label>
      <input ds-input type="text" [(ngModel)]="newRegion.name" required/>
    </ds-form-field>
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_code'}}</label>
      <input ds-input type="text" [(ngModel)]="newRegion.shortCode" required/>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="addRegion()">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>
