<div class = "row">
  <div class = "col-12 mb-2 mt-1">
    <h1 class="small-heading">
      <span class="ap-title">{{'' | i18n: 'user_profile'}} : </span> {{qNumber}}
    </h1>
  </div>
</div>

<div class = "col-12 mt-3 mb-2">
  <ag-grid-angular
    style="width: 100%; height: 600px"
    class="ag-theme-alpine"
    rowHeight="45"
    [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
    [columnDefs]="columns"
    [pagination]="true"
    [suppressDragLeaveHidesColumns]="true"
    [paginationPageSize]="paginationSize"
    [rowData]="openRequestsForUserFull"
    (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
    [rowGroupPanelShow]="rowGroupPanelShow"
    [defaultColDef]="defaultColDef"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (filterChanged)="onFilterChanged($event)"
  >
  </ag-grid-angular>
</div>

<!--Comments and approve section -->
<div class = "col-12 pl-0 mt-4 d-flex justify-content-end ">
  <button ds-button   routerLink="/approver-current-request">{{'' | i18n: 'back'}}</button>
  <button ds-button  (click)="approveButtonClicked()">{{'' | i18n: 'approve'}}</button>
</div>
