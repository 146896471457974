import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import GeneratedRequest from '../../../shared/model/requests/generated-request.interface';
import Application from '../../../shared/model/applications/application';
import Right from '../../../shared/model/rights/right';
import {Router} from '@angular/router';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {
  CellClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridOptions, IRowNode, IsRowSelectable,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {formatDate} from '@angular/common';
@Component({
  selector: 'app-view-rights',
  templateUrl: './view-rights.component.html',
  styleUrls: ['./view-rights.component.css']
})
export class ViewRightsComponent implements OnInit, OnChanges {
  @Input() selectedRequest: GeneratedRequest;
  @Input() selectedApplication: Application;
  selectedApplicationRights: Right[];
  applicationMap: Map<number, Application> = new Map<number, Application>();
  columns = [];
  rowSelection: 'single' | 'multiple' = 'single';
  editMode = false;
  rowData: any[] = [
    {this: 'selectedApplicationRights'},
  ];
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };

  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.getRightApplication(event.data),
    onCellClicked: (event: CellClickedEvent) => {
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedApplication = null;
      this.editMode = false;
    }
  };

  public defaultColDef: ColDef = {
    resizable: true,
    filter: true, sortable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
    const nodesToSelect: IRowNode[] = [];
    params.api.forEachNode((node) => {
      if (node.data && node.data.active) {
        node.setSelected(true, false, true, 'api');
      }
    });
  }
  constructor(public router: Router, private i18nProvider: I18nProvider) {
  }

  ngOnInit(): void {
    this.getSelectedApplicationRights();
  }

  private populateApplicationsMap(request: GeneratedRequest) {
    request?.applications.forEach((application) => {
      this.applicationMap.set(application.id, application);
    });
  }

  getRightApplicationCategoryName(right: Right): string {
    const application = this.getRightApplication(right);
    return application?.applicationRightsCategories?.find(aRC => aRC.id === right.applicationRightsCategoryId)?.name;
  }

  getRightApplication(right: Right): Application {
    if (this.applicationMap) {
      return this.applicationMap.get(right.applicationId);
    }
    return null;
  }

  getSelectedApplicationRights(){
    this.selectedApplicationRights = this.selectedRequest?.rights.filter( right => {
      return right.applicationId === this.selectedApplication?.id;
    });

    this.selectedApplicationRights.forEach(appRight => {
      appRight.appCategoryDisplay = this.getRightApplicationCategoryName(appRight);
      appRight.activeDisplay = this.getRightStatus(appRight.active);
      appRight.req_end_date = formatDate(this.selectedRequest.endDate, 'yyyy/MM/dd', 'en-GB');
      appRight.req_start_date = formatDate(this.selectedRequest.startDate, 'yyyy/MM/dd', 'en-GB');
      appRight.rule_name = this.selectedRequest.rule;
      appRight.locations = this.setLocationsForDisplay(this.selectedRequest.locations);
    });
    this.getColumns();
  }

  getRightStatus(active: boolean): string{
    return active ? this.i18nProvider.getDescription('active') : this.i18nProvider.getDescription('inactive');
  }

  isRowSelectable: IsRowSelectable = (params: IRowNode<any>) => {
    if (params.data && params.data.active) {
      params.setSelected(true, false, true, 'api');
    }
    return false;
  };

  getRightStatusStyle(active: boolean): string{
    return active ? 'active' : 'inactive';
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (this.selectedRequest?.id){
      this.populateApplicationsMap(this.selectedRequest);
      this.getSelectedApplicationRights();
    }
  }
  getColumns() {
    this.columns = [
      {field: 'appCategoryDisplay', headerName: 'AppCategory'},
      {field: 'name', headerName: 'App Right'},
      {
        field: 'activeDisplay', headerName: this.i18nProvider.getDescription('active'), filter: true, sortable: true, width: 210,
        cellClass: params => {
          return this.getRightStatusStyle(params.data.active);
        },
      },
      {field: 'req_start_date', headerName: this.i18nProvider.getDescription('start_date') , cellRenderer: (data) => {
          return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
        }},
      {field: 'req_end_date',  headerName: this.i18nProvider.getDescription('end_date'), cellRenderer: (data) => {
          return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
        }},
      {field:  'rule_name',   headerName: this.i18nProvider.getDescription('type')},
      {field: 'locations', headerName: this.i18nProvider.getDescription('location')},
      {field: 'active', headerName: 'Done', headerCheckboxSelection: false, showDisabledCheckboxes: true, checkboxSelection: true, cellClass: 'text-hide'},
    ];
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setLocationsForDisplay(locations: any []): string {
    const locationToDisplay: string[] = [];
    locations.forEach(value => {
      locationToDisplay.push(value.name);
    });
    return locationToDisplay.join(', ');
  }
}

