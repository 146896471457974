<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5 p-0">
      <h1 class="h1-heading">
        <span class="blue">{{'' | i18n: 'manage_rights_manager'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class = "col-12 d-flex justify-content-between align-items-end p-0">
      <div class = "d-flex flex-column">
        <label class = "select-region">{{'' | i18n: 'choose_region'}}:</label>
        <select [(ngModel)]="selectedRegion" (change)="changeRegion()">
          <option value="-1" selected disabled>{{'' | i18n: 'choose_region'}}</option>
          <option *ngFor="let region of loggedInUserRegions" [ngValue]="region">{{region?.name}}</option>
        </select>
      </div>
      <div *ngIf="selectedRegion">
        <button class="btn-blue-costom " [ds-modal-trigger-for]="tpl" (click)="showModalForNewRightsManager()" [(isModalOpen)]="modalIsOpen">{{'' | i18n: 'new_rights_manager'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="container-costom">
      <table class="table" *ngIf="showNewRightManagerDetails">
        <tbody>
        <tr>
          <td><i class="fa fa-pencil"></i> {{rightsManager.qnumber}} </td>
          <td>{{newRightsManager.name}}</td>
          <td>{{newRightsManager.surname}}</td>
          <td>{{newRightsManager.email}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end mt-2 p-0" *ngIf="editMode">
      <button class="green_b-tab p-0 mr-0" [ds-modal-trigger-for]="tpl" (click)="showModalForEdit()" [(isModalOpen)]="modalIsOpen" >{{'' | i18n: 'applications'}}</button>&nbsp;
      <button class="red_b-tab p-0 mr-0" (click)="delete()" >{{'' | i18n: 'delete'}}</button>
    </div>
    <div class = "col-12 mt-3 mb-2 p-0" *ngIf="selectedRegion">
      <ag-grid-angular
        style="width: 100%; height: 1200px"
        class="ag-theme-alpine"
        rowHeight="35"
        [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
        [columnDefs]="columns"
        [pagination]="true"
        [suppressDragLeaveHidesColumns]="true"
        [paginationPageSize]="200"
        [rowSelection]="rowSelection"
        [rowData]="rightsManagers"
        [rowGroupPanelShow]="rowGroupPanelShow"
        (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
        (cellClicked)="rowEvents.onRowClicked($event)"
        (paginationChanged)="rowEvents.onPaginationChanged($event)"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>

<ds-modal #tpl size="sm" (close)="onModalClose()">
  <ds-box-header>{{'' | i18n: 'manage_rights_manager'}}</ds-box-header>
  <ds-box-content>
    <div style="display: flex;">
      <input type="text" [(ngModel)]="newQNumber" placeholder="{{'' | i18n: 'q_number'}}">
      <div class="input-group-append" *ngIf="!editMode">
        <button  class="btn btn_serch" type="button" (click)="searchNewQnumber()">
          <em class="fa fa-search"></em>
        </button>
      </div>
      <div class="col-md-12 search_results" *ngIf="showSearchResults">
        <div *ngIf="!editMode">
          {{newRightsManager.name}} {{newRightsManager.surname}}<span style="margin-left: 30px">{{newRightsManager.email}}</span>
        </div>
        <div *ngIf="editMode">
          {{newRightsManager.fullName}} <span style="margin-left: 30px">{{newRightsManager.email}}</span>
        </div>
      </div>
    </div>
    <br/>
    <label *ngIf="showSearchResults">{{'' | i18n: 'choose_application'}}:</label>
    <div class = "" *ngFor="let app of selectedRegionApplicationsMin">
      <ds-form-field *ngIf="showSearchResults">
        <ds-checkbox-group variant="stacked">
          <input ds-checkbox type="checkbox" [checked]="app.selected" (change)="setRightsManagerApps($event, app.id)" [id]="app.id"/> <label>{{app.name}}</label>
        </ds-checkbox-group>
      </ds-form-field>
    </div>

    <div>
      <ds-form-field>
        <label>{{'' | i18n: 'request_access'}}:</label>
        <ds-radio-group>
          <input ds-input id="all" type="radio" [value]="true" [(ngModel)]="isLinked" name=" link" required/>
          <label ds-label for="all">{{'' | i18n: 'all_requests'}}</label>
          <input ds-input id="linked" type="radio" [value]="false" [(ngModel)]="isLinked" name="link" required/>
          <label ds-label for="linked">{{'' | i18n: 'only_linked_applications'}}</label>
        </ds-radio-group>
      </ds-form-field>
    </div>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button (click)="onModalClose()" #dsModalDismissElement  variant="outline">{{'' | i18n: 'cancel'}}</button>
    <button ds-button *ngIf="showSearchResults" (click)="updateRightsManager()" [disabled]="!newQNumber">{{'' | i18n: 'add'}}</button>

  </ds-box-footer>
</ds-modal>
