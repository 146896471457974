import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AllowedLoginsService} from '../../../service/http/allowed-logins.service';
import Department from '../../../shared/model/department';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
import {I18nProvider} from '../../../service/translations/i18n.service';
import RequestsNew from '../../../shared/model/requests/requests-new';
import {
  CellClickedEvent, FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {SelectListOption} from '@bmw-ds/components';
import UserDisplay from '../../../shared/model/user/user-display';
import {UserHttpService} from '../../../service/http/user-http.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {
  users: UserDisplay[];
  columns = this.getColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  constructor(private userHttpService: UserHttpService, private i18nProvider: I18nProvider) { }

  ngOnInit(): void {
    this.paginationSize = this.setPaginationSize();
    this.getAllUsers();
  }

  getAllUsers(){
    this.userHttpService.getAllUsers().subscribe(value => {
        this.users = value;
      });
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  getColumns(){
    return [
        {field: 'fullName', headerName: this.i18nProvider.getDescription('surname_name'), width: 300, filter: true, sortable: true},
        {field: 'qnumber', headerName: this.i18nProvider.getDescription('q_number'), width: 200, filter: true, sortable: true},
        {field: 'departmentCode', headerName: this.i18nProvider.getDescription('department_code'), width: 200, filter: true, sortable: true, enableRowGroup: true},
        {field: 'isManager', headerName: this.i18nProvider.getDescription('manager_account'), width: 200, filter: true, sortable: true, enableRowGroup: true},
        {field: 'assignedApprover', headerName: this.i18nProvider.getDescription('approver'), width: 300, filter: true, sortable: true, enableRowGroup: true},
        {field: 'deputyFor', headerName: this.i18nProvider.getDescription('deputy_for'), width: 200, filter: true, sortable: true, enableRowGroup: true},
        {field: 'deputies', headerName: this.i18nProvider.getDescription('represented_by'), width: 200, filter: true, sortable: true,  enableRowGroup: true},
      ];
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_users_master_admin_pagination')) {
      return localStorage.getItem('ara_users_master_admin_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_users_master_admin_pagination', size);
    this.paginationSize = size;
  }
}
