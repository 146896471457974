import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compliance-current-request',
  templateUrl: './compliance-current-request.component.html',
  styleUrls: ['./compliance-current-request.component.scss']
})
export class ComplianceCurrentRequestComponent implements OnInit {
  auth = true;
  constructor() { }

  ngOnInit(): void {
  }

}
