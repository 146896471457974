import {Injectable} from '@angular/core';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import GeneratedRequest from 'src/app/shared/model/requests/generated-request.interface';
import User from 'src/app/shared/model/user/user';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {SelectListOption} from '@bmw-ds/components';
import {RequestStatusUtilsService} from 'src/app/service/http/request-status-utils.service';
import { ApplicationsService } from './applications.service';
import {Observable} from 'rxjs';
import {I18nProvider} from 'src/app/service/translations/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class RequestBuilderService {
  public qNumber: string;
  public currentUser: User;
  public requests;
  constructor(private businessCategoryHttpService: BusinessCategoryHttpService, private applicationService: ApplicationsService,
              private requestStatusUtilsService: RequestStatusUtilsService, private i18nProvider: I18nProvider) { }


  async prepareRequest(requestList: GeneratedRequest[]) {
    return new Promise((resolve, reject) => {
      if (requestList) {
        this.addCategory(requestList)
          .then((requestsWithCategories: GeneratedRequest[]) => {
            this.addType(requestsWithCategories)
              .then((requestsWithTypes: GeneratedRequest[]) => {
                this.addStatus(requestsWithTypes).then(requestsWithStatus => {
                  this.applicationService.getRequestsWithApplication(requestsWithStatus).subscribe(requestsWithApplications => {
                    this.addRightsToRequests(requestsWithApplications).subscribe(requests => {
                      resolve(requests);
                    });
                });
              })
              .catch(err => reject(err));
          })
          .catch(err => reject(err));
      });
    }
  });
  }
// --TODO ! -- Other Items don't get returned. the promise gets resolved before calling all the categories from the api
  async addCategory(requestList: GeneratedRequest[]) {

    // used a for loop because map doesn't wait for an api call to be resolved
    const totalRequests: number = requestList.length;
    return new Promise(resolve => {
      requestList.forEach((request: GeneratedRequest, i) => {

        if (request?.businessRuleDTO) {
          this.businessCategoryHttpService.getBusinessCategory(request?.businessRuleDTO?.businessCategoryId)
            .subscribe((category: BusinessCategory) => {
              requestList[i].category = category.name;
              if (i + 1 === totalRequests) {
                resolve(requestList);
              }
            });
        }

        else {
          if (i + 1 === totalRequests) {
            resolve(requestList);
          }
        }

      });
    });

  }

  async addType(requestList: GeneratedRequest[]) {

    return requestList.map((request: GeneratedRequest) => {
      if (request?.rightDTO) {
        request.type = this.i18nProvider.getDescription('single_right');
        request.rule = request?.rightDTO.name;

      } else {
        request.type = this.i18nProvider.getDescription('business_rule');
        request.rule = request?.businessRuleDTO.name;
      }

      return request;

    });
  }

  async addStatus(requestList: GeneratedRequest[]) {
    return requestList.map((request) => {
      request.status = this.requestStatusUtilsService.getStatusName(request);
      return request;
    });
  }

   addRightsToRequests(requestList: GeneratedRequest[]): Observable<GeneratedRequest[]> {
    return new Observable(subscriber => {
      requestList.forEach( request => {
        if (request.rightDTO) {
          request.rights.push(request.rightDTO);
        }
        if (request.businessRuleDTO) {
          request.rights.push(...request.businessRuleDTO.rights);
        }
      });
      subscriber.next(requestList);
    });
  }

  // --TODO ! -- Other Items don't get return

  async getCategoryFilterList(requests: any[]) {

    const categoryFilter: SelectListOption[] = requests.map((element: any) => {
      return {
        id: element?.category || '', label: element?.category || ''
      };
    }).filter(object => {
      return object.id !== '';
    });


    return [...new Map(categoryFilter.map((m) => [m.id, m])).values()];

  }

  async getTypeFilterList(requests: any[]) {

    const typeFilter: SelectListOption[] = requests.map(item => {

      return { id: item?.type || '', label: item?.type || '' };
    }).filter(object => {
      return object.id !== '';
    });

    return [...new Map(typeFilter.map((m) => [m.id, m])).values()];

  }

  async getRuleFilterList(requests: any[]) {

    const ruleFilter: SelectListOption[] = requests.map(item => {
      return {
        id: item?.businessRuleDTO?.name || item.rightDTO?.name || item?.rule, label: item?.businessRuleDTO?.name || item.rightDTO?.name || item?.rule
      };
    }).filter(object => {
      return object.id !== '';
    });

    return [...new Map(ruleFilter.map((m) => [m.id, m])).values()];
  }

  async getStatusFilterList(requests: any[]) {

    const ruleFilter: SelectListOption[] = requests.map(item => {
      return {
        id: item?.status || '', label: item?.status || ''
      };
    });

    return [...new Map(ruleFilter.map((m) => [m.id, m])).values()];
  }

  async sanitizeRequest(FullyGeneratedRequest: GeneratedRequest) {
    const request: any | GeneratedRequest = FullyGeneratedRequest;
    delete request.category;
    delete request.type;
    delete request.rule;
    delete request.status;
    delete request.application;
    delete request.user;
    if ( !request?.category || !request?.type || !request?.rule || !request?.status)
    {return request; }
  }


}
