<div class="container-costom p-0">

<div class = "row">
  <div class = "col-12 mb-2 mt-1">
    <h1 class="small-heading">
      <span class="ap-title">{{'' | i18n: 'user_profile'}} : </span> {{qNumber}}
    </h1>
  </div>
</div>


  <div class = "col-12 p-0">
    <div class="col-12 d-flex justify-content-end p-0 mb-2" *ngIf="selectedRequest">
      <button class="green_b-tab mr-0" (click)="showRequestDetails(selectedRequest)">{{'' | i18n: 'details'}}</button>
    </div>
    <ag-grid-angular
      style="width: 100%; height: 425px"
      class="ag-theme-alpine"
      rowHeight="56"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="10"
      [rowData]="openRequestsForUser"
      [rowSelection]="rowSelection"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      (cellClicked)="rowEvents.onRowClicked($event)"
      (paginationChanged)="rowEvents.onPaginationChanged($event)"
      [columnDefs]="columns"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
