<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class = "blue">{{'' | i18n: 'approve_violations'}}</span><br />
      </h1>
    </div>
  </div>
  <div class = "row" >
    <div class = "col-lg-6 mt-3">
      <h5>{{'' | i18n: 'violation_request'}}:</h5>
      <table class="table mb-2">
        <thead>
        <tr>
          <th scope="col">{{'' | i18n: 'request_number'}}</th>
          <th scope="col">{{'' | i18n: 'requester'}}</th>
          <th scope="col">{{'' | i18n: 'q_number'}}</th>
          <th scope="col">{{'' | i18n: 'location'}}</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let openApproval of openRequestsForApproval">
          <th scope="row">{{openApproval.id}}</th>
          <th>{{bmwPeopleMap.get(openApproval.requestedFor.qnumber)?.givenName}}</th>
          <td>{{openApproval.requestedFor.qnumber}}</td>
          <td>{{openApproval.region.name}}</td>
          <td><button type="button" class="btn btn-default float-right" [routerLink]="['/violations-approver-info', openApproval.id]">{{'' | i18n: 'more_info'}}</button></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class = "col-lg-6 mt-3">
      <h5>{{'' | i18n: 'completed'}}:</h5>
      <table class="table mb-2">
        <thead>
        <tr>
          <th scope="col">{{'' | i18n: 'request_number'}}</th>
          <th scope="col">{{'' | i18n: 'requester'}}</th>
          <th scope="col">{{'' | i18n: 'q_number'}}</th>
          <th scope="col">{{'' | i18n: 'location'}}</th>
          <th class = "status-center" scope="col">{{'' | i18n: 'status'}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let completedRequest of compRequests">
          <th>{{completedRequest.id}}</th>
          <td>{{bmwPeopleMap.get(completedRequest.requestedFor.qnumber)?.givenName}}</td>
          <td>{{completedRequest.requestedFor.qnumber}}</td>
          <td>{{completedRequest.region.name}}</td>
          <td>
            <button type="button" class="btn btn-default float-right" [routerLink]="['/more-info-archive', completedRequest.id]">{{'' | i18n: 'more_info'}}</button>
            <button type="button" class="btn btn-granted float-right" [routerLink]="['/more-info-archive', completedRequest.id]" *ngIf="isGranted(completedRequest)">{{'' | i18n: 'granted'}}</button>
            <button type="button" class="btn btn-declined float-right" [routerLink]="['/more-info-archive', completedRequest.id]" *ngIf="isDeclined(completedRequest)">{{'' | i18n: 'declined'}}</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
