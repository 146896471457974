<div class = "row" *ngIf="selectedRequest">
  <div class = "mt-5">
    <ds-tabs>
      <ds-tabs-label for="a2">{{'' | i18n: action}}</ds-tabs-label>
      <ds-tabs-content class = "row" id="a2">
        <div class = "col-sm-11 col-md-8">
          <div class = "row">
            <div class = "col-3">
              <label ds-label for="region">Region:</label>
              <div id="region">{{selectedRequest?.regionName}}</div>
              <label ds-label for="businessCategory">Business Category:</label>
              <div *ngIf="isBusinessRuleRequest()" id="businessCategory">{{selectedRequest.rule}}</div>
            </div>
            <div class = "col-3 mt-2">
              <div class = "row">
                <label ds-label for="location" style ="margin-left: -12px;">{{'' | i18n: 'locations'}}:</label>
                <div class = "multi_select_location" id="location">
                  <div class = "col-12" *ngFor="let location of selectedRequest.locations_display">
                    {{location.name}} <span class ="multi_select_location_checkbox">
                        <ds-form-field>
                          <ds-checkbox-group>
                            <input ds-checkbox type="checkbox" id="{{location.name}}" [(ngModel)]="location.active" disabled = true>
                          </ds-checkbox-group>
                        </ds-form-field>
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class = "col-6 mt-2">
              <span style="display: flex">
                <div style="width: 48%">
                <label ds-label for="removeRulesStartdate">{{'' | i18n: 'start_date'}}: </label>
                  <div id="removeRulesStartDate">{{selectedRequest.startDate | date: 'dd/MM/yyyy'}}</div>
              </div>
              <ds-form-field style="width: 48%;margin-left: 21px;">
                <label ds-label for="endDate">{{'' | i18n: 'end_date'}}:</label>
                <ds-datepicker [(viewDate)]="requestEndDate" [(config)]="dateConfig" (viewDateChange)="endDateSelected=true">
                  <input ds-date id="endDate" [(ngModel)]="endDate" required="true"/>
                </ds-datepicker>
                <label class="required_identifier" *ngIf="!endDateSelected">{{'' | i18n: 'end_date_msg'}}</label>
              </ds-form-field>
              </span>
              <ds-form-field class = "mt-4">
                <label ds-label for="comment">{{'' | i18n: 'write_a_comment'}}:</label>
                <textarea ds-input id="comment" required="false" [(ngModel)]="comment" (change)="commentEntered=true"></textarea>
              </ds-form-field>
              <label class="required_identifier" *ngIf="!commentEntered">{{'' | i18n: 'write_a_comment'}}</label>
            </div>
          </div>
        </div>
        <div class = "col-sm-1 cmt-20">
          <button class = "green_b_standard" (click)="saveRequest()">{{'' | i18n: 'save'}}</button><br />
          <button class = "cancel_b_standard" (click)="onHideComponent()">{{'' | i18n: 'cancel'}}</button>
        </div>
      </ds-tabs-content>
    </ds-tabs>
  </div>
</div>
