<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class = "light-orange">{{'' | i18n: 'manage_region_applications'}}</span>
      </h1>
    </div>
  </div>
  <div class="row" style = "margin-top: 30px">
    <div class = "col-md-6 ">
      <div class="form-group">
        <label class = "select-region">{{'' | i18n: 'choose_region'}}:</label>
        <div class = "">
          <select [(ngModel)]="selectedRegion" (change)="changeRegion()">
            <option value="-1" selected disabled>{{'' | i18n: 'choose_region'}}</option>
            <option *ngFor="let region of regions" [ngValue]="region">{{region?.name}}</option>
          </select>
        </div>
      </div>
    </div>

</div>

<div class="row " *ngIf="selectedRegion" style="width: 100%;margin-left: 1px;">
  <div class="col-12 d-flex justify-content-end" *ngIf="editMode">
    <button class="green_b-tab mr-0" (click)="linkApplications()" *ngIf="!selectedApplication.enabledForRegion">{{'' | i18n: 'activate'}}</button>
    <button class="green_b-tab mr-0" (click)="unlinkApplications()" *ngIf="selectedApplication.enabledForRegion">{{'' | i18n: 'deactivate'}}</button>
  </div>
  <div class = "col-12 mt-3 mb-2">
    <ag-grid-angular
      style="width: 100%; height: 1200px"
      class="ag-theme-alpine"
      rowHeight="35"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="100"
      [rowData]="allApplications"
      [rowSelection]="rowSelection"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      (cellClicked)="rowEvents.onRowClicked($event)"
      (paginationChanged)="rowEvents.onPaginationChanged($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
</div>
