<ds-box class="confirmation-modal">
  <ds-box-header isDismissable="true" (dismissEvent)="closeModal()"></ds-box-header>
  <ds-box-content>
      <div class="col-12 d-flex flex-column align-items-center">
        <span class="modal-header-title-md">{{'' | i18n: data.action}}</span>
        <br>
        <span>{{'' | i18n : data.message}}</span>
      </div>
  </ds-box-content>

  <ds-box-footer>
    <div class="col-12 d-flex justify-content-center">
      <button class = "cancel_b_standard col-2" (click)="closeModal()">{{'' | i18n: 'no'}}</button>
      <button class = "green_b_standard col-2" (click)="closeModal(true)">{{'' | i18n: 'yes'}}</button>
    </div>
  </ds-box-footer>

</ds-box>
