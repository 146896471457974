import {Component, OnInit} from '@angular/core';
import User from 'src/app/shared/model/user/user';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {UserRole} from 'src/app/shared/enums/user-role';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import {I18nProvider} from 'src/app/service/translations/i18n.service';
import RegionPropsMinified from '../../../shared/model/requests/region-props-min';
import {
  CellClickedEvent, CellDoubleClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
import {user} from '@bmw-ds/components/spriteTree';
import {ToastService} from '../../../service/feedback/toast.service';
import Region from '../../../shared/model/requests/region';
interface CustomGridColum {
  field: string;
  headerName: any;
  filter?: boolean;
  enableRowGroup?: boolean;
  sortable: boolean;
  width: number;
  cellRenderer?: any;
}
@Component({
  selector: 'app-regions-admins',
  templateUrl: './regions-admins.component.html',
  styleUrls: ['./regions-admins.component.scss']
})
export class RegionsAdminsComponent implements OnInit {
  auth = true;
  showRegionAdmin = false;
  showDisabled = false;
  showSerchResults = false;
  users: User[] = [];
  user: User;
  regions: RegionPropsMinified[];
  selectedRegionId: number;
  newQNumber: string;
  showUpdateUser = new Map<string, boolean>();
  newRegionId: number;
  qNumber: string;
  regionAdmins: BmwPerson[];
  admins: BmwPerson[] = [];
  employees: BmwPerson[] = [];
  selectedAdmin: BmwPerson;
  columns = this.getColumns();
  updateModalIsOpen = false;
  modalIsOpen = false;
  editMode = false;
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<BmwPerson>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  userWithRegions: any[] = [];
  noEditColumns = ['fullName', 'uid', 'departmentNumber', 'mail'];
  newAdminCreated = false;
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedAdmin = event.data;
      this.editMode = true;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedAdmin = event.data;
      this.editMode = true;
    },
    onPaginationChanged: () => {
      this.selectedAdmin = null;
      this.editMode = false;
    },
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateRegionAdminStatus(event)
  };

  constructor(public userHttpService: UserHttpService, public regionHttpService: RegionHttpService,
              public sweetAlertService: SweetAlertService, public lookupHttpService: LookupHttpService,
              public i18nProvider: I18nProvider, private dialog: DialogService, private toastService: ToastService,) {}

  ngOnInit() {
    this.initUsers();

    this.regionHttpService.getRegionsPropsMinified(true).subscribe(regions => {
      this.regions = regions.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  private initUsers() {
    this.userHttpService.getUsersByRole(UserRole.ARA_REGION_ADMIN).subscribe(
      successResponse => {
        this.buildUserRolesForDisplay(successResponse);
      },
      errorResponse => this.sweetAlertService.showComError('Error retrieving users. ' + JSON.stringify(errorResponse))
    );
  }

  buildUserRolesForDisplay(users: User[]) {
    this.userWithRegions = users.map(user => {
      const usersWithRegions = {
        fullName: `${user.name} ${user.surname}`,
        uid: user.qnumber,
        departmentNumber: user.departmentCode,
        mail: user.email,
        regions: []
      };
      const uniqueRegions = new Set();
      user.roles.forEach(role => {
        if (role.name === UserRole.ARA_REGION_ADMIN && role.region) {
          const regionObject = { id: role.region.id, assigned: true, shotCode: role.region.shortCode };
          const regionKey = JSON.stringify(regionObject);

          if (!uniqueRegions.has(regionKey)) {
            uniqueRegions.add(regionKey);
            usersWithRegions.regions.push(regionObject);
          }
        }
      });
      return usersWithRegions;
    });
    this.gridApi.setRowData(this.userWithRegions);
    this.setRegionColumns();
  }

  updateRegionAdminStatus(rowObject: any) {

    if (!this.noEditColumns.includes(rowObject.column.colId)) {

      const regionID = rowObject.column.colId;
      const regions = rowObject.data.regions;
      const existingRegionAdmin = regions.find(getRegion => getRegion.id === Number(regionID));

      if (!existingRegionAdmin) {
        const regionObject = { id: Number(regionID), assigned: true, shotCode: rowObject.colDef.headerName};
        rowObject.data.regions.push(regionObject);

        this.userHttpService.grantRole(rowObject.column.colId, rowObject.data.uid, UserRole.ARA_REGION_ADMIN)
          .subscribe(success => {
            this.toastService.success('Region ('+ rowObject.colDef.headerName +') admin granted for: ' + rowObject.data.fullName);
          },
        () => this.sweetAlertService.showComError('Error granting permission.'));
      } else if (existingRegionAdmin) {
        this.userHttpService.removeRoleFromUser(rowObject.data.uid, UserRole.ARA_REGION_ADMIN, rowObject.column.colId)
          .subscribe(success => {
              this.toastService.success('Region ('+ rowObject.colDef.headerName +') admin removed for: ' + rowObject.data.fullName);
            },
            () => this.sweetAlertService.showComError('Error removing permission.'));

        rowObject.data.regions = rowObject.data.regions.filter(region => {
          return !(region.id === Number(regionID) && region.assigned === true && region.shotCode === rowObject.colDef.headerName);
        });
      }

      let refreshParams = {force: true, rowNodes: [rowObject.node]};
      rowObject.api.refreshCells(refreshParams);

    }
  }

  grantUser(region: Region) {
    this.userHttpService.grantRole(region.id, this.user.qnumber, UserRole.ARA_REGION_ADMIN)
      .subscribe(success => {
          this.toastService.success('Region ('+ region.shortCode +') admin granted for: ' + this.user.name + ' ' + this.user.surname);
          this.newAdminCreated = true;
        },
        () => this.sweetAlertService.showComError('Error granting permission.'));
  }

  searchUser() {
    if (!this.newQNumber) {
      return;
    }
    this.userHttpService.getUser(this.newQNumber.toUpperCase()).subscribe(user => {
      this.user = user;
    },
      () => this.sweetAlertService.showComError('ARA user "' + this.newQNumber + '" not found!'));
  }

  refreshTable() {
    if (this.newAdminCreated) {
      this.initUsers();
    }
  }

  resetUser() {
    this.user = null;
    this.newQNumber = null;
    this.newAdminCreated = false;
  }

  addUser() {
    if (!this.newRegionId) {
      this.sweetAlertService.showComError(this.i18nProvider.getDescription('select_region_msg'));
    } else if (!this.newQNumber) {
      this.sweetAlertService.showComError('Please enter a Q-number.');
    } else {
      this.userHttpService.grantRole(this.newRegionId, this.newQNumber, UserRole.ARA_REGION_ADMIN)
        .subscribe(() => {
            this.modalIsOpen = false;
            this.initUsers();
          },
          () => this.sweetAlertService.showComError('Error granting permission.'));
      this.showRegionAdmin = false;
    }
  }


  deleteAdmin() {
    const data: Modal = {
      action : 'delete',
      message: 'delete_item'
    };
    this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
      if (results){
        this.userHttpService.removeRoleFromUser(this.selectedAdmin.uid, UserRole.ARA_REGION_ADMIN,
          this.selectedAdmin.regionId)
          .subscribe(() => {
            // this.regionAdmins.filter(admin => admin.uid !== this.selectedAdmin.uid);
            // this.selectedAdmin = null;
            // this.gridApi.setRowData(this.regionAdmins);
            this.initUsers();
          }, error => {
            this.sweetAlertService.showComError('Error removing Region Admin: ' + JSON.stringify(error));
          });
      } });
  }


  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<BmwPerson>) {
    this.gridApi = params.api;
  }


  getColumns(): CustomGridColum[] {
    const columns: CustomGridColum[] = [
      {field: 'fullName', headerName: this.i18nProvider.getDescription('surname_name'), filter: true, sortable: true,
        width: 300},
      {field: 'uid', headerName: this.i18nProvider.getDescription('q_number'), filter: true, sortable: true,
        width: 200},
      {field: 'departmentNumber', headerName: this.i18nProvider.getDescription('department_code'), filter: true,
        enableRowGroup: true, sortable: true,
        width: 200},
      {field: 'mail', headerName: this.i18nProvider.getDescription('email'), filter: true, sortable: true, width: 400},
      ];
    return columns;
  }

  private setRegionColumns() {
    const colDefs = this.gridApi.getColumnDefs();
    this.regions.forEach(region => {

      colDefs.push({field: region.id.toString(), headerName: region.shortCode, filter: true, sortable: true, width: 85,
        cellRenderer: (params) => { return this.setStatusCheckbox_(params); }});
    });
    this.gridApi.setColumnDefs(colDefs);
  }

  setStatusCheckbox_(params) {
    const regionID = params.colDef.field;
    const regions = params.data.regions;
    const region = regions.find(getRegion => getRegion.id === Number(regionID));
    if (region && region.assigned) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }
}
