<div class="row">
  <div class="col-12 mt-5">
    <h1 class="small-heading">
      <span class="ap-title">{{'' | i18n: 'deputy'}}</span>
    </h1>
  </div>
</div>
<div class = "row main-tab-b" *ngIf="isApprover()">
  <div class = "col-12 tab-b mt-3 p-0 mb-2">
    <button class = "green_b-tab" ds-button  (click)="newDeputyClicked()" [disabled]="newDeputyButtonDisabled">{{'' | i18n: 'new_deputy'}}</button>
  </div>
</div>
<div class = "row">
  <div class = "table-scroller">
    <table ds-table [(config)]="config" class="table mb-5">
      <thead ds-table-head>
      <tr>
        <th width="15%">{{'' | i18n: 'surname_name'}}
        </th>
        <th width="15%">{{'' | i18n: 'email'}}</th>
        <th width="15%">{{'' | i18n: 'department_code'}}</th>
        <th width="15%">User ID
        </th>
        <th width="30%" style="text-align: right">
              {{'' | i18n: 'delete'}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let deputy of currentUser.standInUsers">
        <td>{{deputy?.surname}}, {{deputy?.name}}</td>
        <td>{{deputy?.email}}</td>
        <td>{{deputy?.departmentCode}}</td>
        <td>{{deputy?.qnumber}}</td>
        <td style="text-align: right;">
          <img class = "ml-3 pointer" src="../../../../assets/images/icons/delete.svg" width="22" (click)="deleteDeputy(deputy?.approverDeputyId)"/>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col-12 mt-5">
    <h1 class="small-heading">
      <span class="ap-title">{{'' | i18n: 'my_assigned_delegates'}}</span>
    </h1>
  </div>
</div>
<div class = "row">
  <div class = "table-scroller">
    <table ds-table [(config)]="config" class="table mb-5">
      <thead ds-table-head>
      <tr>
        <th width="15%">{{'' | i18n: 'surname_name'}}</th>
        <th width="15%">{{'' | i18n: 'email'}}</th>
        <th width="15%">{{'' | i18n: 'department_code'}}</th>
        <th width="15%">User ID</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let delegate of currentUser.myDelegates">
        <td>{{delegate?.surname}}, {{delegate?.name}}</td>
        <td>{{delegate?.email}}</td>
        <td>{{delegate?.departmentCode}}</td>
        <td>{{delegate?.qnumber}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<!--new deputy modal -->
<ds-modal #new_deputy [ds-modal-trigger-for]="new_deputy" [(isModalOpen)]="newDeputyModalOpen" size="sm"  [dimensionsConfig]="{ width: '600px' }">
  <ds-box-header>
    <span class = "modal-header-title">
      {{'' | i18n: 'new_deputy'}}
    </span>
  </ds-box-header>
  <ds-box-content style="padding: 50px">
    <div class = "row">
      <div class = "col-6">
        <ds-form-field>
          <label ds-label class = "pull-left">User ID: </label>
          <input ds-input id="userID" type="text" required="false" [(ngModel)]="deputyQNumber"/>
        </ds-form-field>
      </div>
    </div>

  </ds-box-content>
  <ds-box-footer class = "mb-3 b-position">
    <button class = "cancel_b_standard" (click)="closeNewDeputyModal()">{{'' | i18n: 'cancel'}}</button>
    <button class = "green_b_standard" (click)="newDeputySaveClicked()">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>
<!--edit deputy modal -->
<ds-modal #edit_deputy size="sm"  [dimensionsConfig]="{ width: '600px' }">
  <ds-box-header>
    <span class = "modal-header-title">
      Edit deputy
    </span>
  </ds-box-header>
  <ds-box-content style="padding: 50px">
    <div class = "row">
      <div class = "col-6">
        <ds-form-field>
          <label ds-label class = "pull-left">User ID: </label>
          <input ds-input id="editUserID" type="text" required="false"/>
        </ds-form-field>
      </div>
    </div>
    <div class = "row">
      <div class = "col-6 mt-4 costom_pr-3">
        <ds-form-field>
          <label ds-label class = "pull-left">{{'' | i18n: 'start_date'}}:</label>
          <input ds-input id="new_deputy_StartDate" type="date" required="false"/></ds-form-field>
      </div>
      <div class = "col-6 mt-4 costom_pr-3">
        <ds-form-field>
          <label ds-label class = "pull-left">{{'' | i18n: 'end_date'}}:</label>
          <input ds-input id="new_endDAte" type="date" required="false"/></ds-form-field>
      </div>
    </div>
  </ds-box-content>
  <ds-box-footer class = "mb-3 b-position">
    <button class = "cancel_b_standard">{{'' | i18n: 'cancel'}}</button>
    <button class = "green_b_standard">SAVE</button>
  </ds-box-footer>
</ds-modal>
