import {Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChange} from '@angular/core';
import GeneratedRequest from '../../../shared/model/requests/generated-request.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestHttpService} from '../../../service/http/request-http.service';
import {DateFormat} from '../../../shared/commons/dateFormat';
import {ApplicationHttpService} from '../../../service/http/application-http.service';
import {RightsManagerHttpService} from '../../../service/http/rights-manager-http.service';
import {RightHttpService} from '../../../service/http/right-http.service';
import {LookupHttpService} from '../../../service/http/lookup-http.service';
import {UserHttpService} from '../../../service/http/user-http.service';
import {SweetAlertService} from '../../../service/feedback/SweetAlertService.service';
import {RequestBuilderService} from '../../../service/helper-services/request-builder.service';
import {ApplicationsService} from '../../../service/helper-services/applications.service';
import {DialogService} from '../../../service/modal/dialog.service';
import {RequestStatusUtilsService} from '../../../service/http/request-status-utils.service';
import Request from '../../../shared/model/requests/request';
import {DsBadgeTone, SelectListOption} from '@bmw-ds/components';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import Application from '../../../shared/model/applications/application';
import {FormControl, Validators} from '@angular/forms';
import Right from '../../../shared/model/rights/right';
import BmwPerson from '../../../shared/model/user/bmw-person';
import Location from '../../../shared/model/requests/location';
import {RequestStatuses} from '../../../shared/enums/request-statuses';
import RightsManagerHistoryItem from '../../../shared/model/user/rights-manager-history-item';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import {
  ColDef, FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';

@Component({
  selector: 'app-request-info',
  templateUrl: './request-info.component.html',
  styleUrls: ['./request-info.component.css']
})
export class RequestInfoComponent implements OnInit, OnChanges {
  @Input() selectedRequest: GeneratedRequest;
  applicationsOptions: SelectListOption[] = [];
  selectedApplicationId = new FormControl();
  selectedApplication: Application;
  comment = new FormControl('', [Validators.required]);
  applicationMap: Map<number, Application> = new Map<number, Application>();
  @Input() requester: BmwPerson;
  qNumber: string;
  gridApi!: GridApi <Right>;

  columns = [];
  rowSelection: 'single' | 'multiple' = 'single';
  editMode = false;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };

  public defaultColDef: ColDef = {
    resizable: true,
    filter: true, sortable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  getRightStatusStyle(active: boolean): string{
    return active ? 'active' : 'inactive';
  }

  getColumns() {
    this.columns = [
      {field: 'name', headerName: this.i18nProvider.getDescription('location'), filter: true, sortable: true},
      {field: 'paramOne', headerName: 'IPF', filter: true, sortable: true},
      {field: 'paramTwo', headerName: 'Plant', filter: true, sortable: true},
      {field: 'paramThree', headerName: 'BuNo', filter: true, sortable: true},
      {field: 'paramFour', headerName: 'BP-ID', filter: true, sortable: true},
      {field: 'paramFive', headerName: 'BP-ID', filter: true, sortable: true},
      {field: 'paramSix', headerName: 'VKO', filter: true, sortable: true},
    ];
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }
  onGridReady(params: GridReadyEvent<Right>) {
    this.gridApi = params.api;
  }

  constructor(private activatedRoute: ActivatedRoute, private requestHttpService: RequestHttpService,
              private dateFormat: DateFormat, private applicationHttpService: ApplicationHttpService,
              private rightsManagerHttpService: RightsManagerHttpService, private rightServices: RightHttpService,
              public router: Router,
              private lookupHttpService: LookupHttpService, private userHttpService: UserHttpService,
              private sweetAlertService: SweetAlertService, private requestBuilderService: RequestBuilderService,
              private el: ElementRef, private renderer: Renderer2, private applications: ApplicationsService,
              private dialog: DialogService, private requestStatusUtilsService: RequestStatusUtilsService, private i18nProvider: I18nProvider) {
  }

  ngOnInit(): void {
  }

  private populateApplicationsMap(request: GeneratedRequest) {
    request?.applications.forEach((application) => {
      this.applicationMap.set(application.id, application);
    });
    this.populateApplicationsForRequest();
  }

  getStatusStyle(openRequestReq: Request) {
    return this.requestStatusUtilsService.getStatusStyle(openRequestReq);
  }

  populateApplicationsForRequest() {
    this.applicationsOptions = [];
    this.selectedApplicationId.reset();
    this.selectedRequest.applications.forEach(application => {
      let applicationOption: SelectListOption;
      applicationOption = { id: '' + application.id, label: application.name };
      this.applicationsOptions.push(applicationOption);
    });
  }

  onApplicationSelected(){
    this.selectedApplicationId.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(applicationId => {
        if (applicationId){
          this.selectedApplication = this.selectedRequest.applications.find(application => application.id === +applicationId);
          this.getColumns();
        }
      });
  }

  getRightApplicationCategoryName(right: Right): string {
    const application = this.getRightApplication(right);
    return application?.applicationRightsCategories?.find(aRC => aRC.id === right.applicationRightsCategoryId)?.name;
  }

  getRightApplication(right: Right): Application {
    if (this.applicationMap) {
      return this.applicationMap.get(right.applicationId);
    }
    return null;
  }

  onHideComponent(hideComponent: boolean ){
    if (hideComponent){
      this.selectedRequest = null;
      this.selectedApplicationId.reset();
      this.selectedApplication = null;
    }
  }

  getSelectedRequest(request: GeneratedRequest ) {
    this.selectedRequest = request;
  }

  showActions(): boolean{
    return this.router.url.includes('rights-manager-request-details');
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (this.selectedRequest?.id){
      this.onApplicationSelected();
      this.populateApplicationsMap(this.selectedRequest);
    }
  }
  canGrantRights(): boolean{
    return this.selectedApplication && this.showActions()
      && this.selectedRequest.requestStatus.name === RequestStatuses.PENDING_RIGHTS_MANAGER_APPROVAL;
  }
  canUpdateRights(): boolean{
    return this.selectedApplication && this.showActions()
      && this.selectedRequest.requestStatus.name === RequestStatuses.CHANGES_MADE;
  }
  canRemoveRights(): boolean{
    return this.selectedApplication && this.showActions()
      && this.selectedRequest.requestStatus.name === RequestStatuses.IN_REMOVAL;
  }

  getTone(rightManager: RightsManagerHistoryItem): DsBadgeTone{
    if (rightManager.status.name === RequestStatuses.GRANTED){
      return 'positive';
    }
    if (rightManager.status.name === RequestStatuses.REMOVED){
      return 'critical';
    }
    else {
      return 'caution';
    }

  }

  setLocationsForDisplay(locations: Location[]): string {
    const locationToDisplay: string[] = [];
    locations.forEach(value => {
      locationToDisplay.push(value.name);
    });
    return locationToDisplay.join(', ');
  }

  displayGrantRights(): boolean{
    const completedApplications = this.selectedRequest?.applications.filter(application => application.active);
    return this.showActions()
      && this.selectedRequest.requestStatus.name === RequestStatuses.PENDING_RIGHTS_MANAGER_APPROVAL
      && this.selectedRequest?.applications.length === completedApplications.length;
  }

  displayUpdateRights(): boolean{
    const completedApplications = this.selectedRequest?.applications.filter(application => application.active);
    return this.showActions()
      && this.selectedRequest.requestStatus.name === RequestStatuses.CHANGES_MADE
      && this.selectedRequest?.applications.length === completedApplications.length;
  }

  displayRemoveRights(): boolean{
    const removedApplications = this.selectedRequest?.applications.filter(application => !application.active);
    return this.showActions()
      && this.selectedRequest.requestStatus.name === RequestStatuses.IN_REMOVAL
      && this.selectedRequest?.applications.length === removedApplications.length;
  }

  submitRequest() {
    const data: Modal = {
      action : 'confirm',
      message: 'submit_request_message'
    };
    this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
      if (results){
        this.selectedRequest.requestStatus = this.requestStatusUtilsService.getUpdateStatus(this.selectedRequest);
        if (this.selectedRequest.requestStatus.name === RequestStatuses.GRANTED){
          this.selectedRequest.active = true;
        }
        if (this.selectedRequest.requestStatus.name === RequestStatuses.REMOVED){
          this.selectedRequest.active = false;
        }
        this.selectedRequest.active = true;
        this.requestHttpService.submitRequest(this.selectedRequest).subscribe(() => {
            this.router.navigate(['rights-manager-current']);
          }, () => {
            this.router.navigate(['rights-manager-current']);
          });
        }
    });
  }

}

