import { Component, Inject, OnInit } from '@angular/core';
import {DsBadgeTone, SelectListOption} from '@bmw-ds/components';
import { DateFormat } from '../../../shared/commons/dateFormat';
import { DialogRef } from 'src/app/service/modal/dialog-ref';
import { DIALOG_DATA } from 'src/app/service/modal/dialog-tokens';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import RequestsNew from '../../../shared/model/requests/requests-new';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {RequestStatusUtilsService} from '../../../service/http/request-status-utils.service';
import BmwPerson from '../../../shared/model/user/bmw-person';
import {LookupHttpService} from '../../../service/http/lookup-http.service';
import {ApplicationHttpService} from '../../../service/http/application-http.service';
import Application from '../../../shared/model/applications/application';
import RightsManagerHistoryItem from '../../../shared/model/user/rights-manager-history-item';
import {RequestStatuses} from '../../../shared/enums/request-statuses';
import {RequestHttpService} from '../../../service/http/request-http.service';

export default class  GeneratedRights {
  applicationName?: string;
  applicationCategory?: string;
  applicationRight?: string;
}

@Component({
  selector: 'app-request-details-new',
  templateUrl: './request-details-new.component.html',
  styleUrls: ['./request-details-new.component.css']
})
export class RequestDetailsNewComponent implements OnInit {
  applicationNameOptions: SelectListOption[] = [];
  applicationCategoryOptions: SelectListOption[] = [];
  applicationRightOptions: SelectListOption[] = [];

  rights: GeneratedRights[] = [];

  applicationNameFilterValue = new FormControl();
  applicationCategoryValue = new FormControl();
  applicationRightValue: string;
  applicationId: number;
  bmwPeopleMap: Map<string, BmwPerson> = new Map<string, BmwPerson>();
  applications: Application[];
  applicationsLength: number;

  constructor(   private dialogRef: DialogRef,
                 @Inject(DIALOG_DATA) public request: RequestsNew,
                 private dateFormat: DateFormat, private i18nProvider: I18nProvider,
                 public lookupHttpService: LookupHttpService,
                 private requestStatusUtilsService: RequestStatusUtilsService,
                 private applicationHttpService: ApplicationHttpService, public requestHttpService: RequestHttpService) { }

  ngOnInit(): void {
    this.requestHttpService.readUserOperation(this.request.id);
    this.request.comments.sort((a, b) => b.date.localeCompare(a.date));
    this.getApplicationNameFilterValues();
    this.getRights(this.request);
    this.getEmployees();
    this.listenToApplicationNameFilterValueChange();
    this.listenToApplicationCategoryFilterValueChange();
    this.sortComments();
    const appIds: number[] = this.getApplicationsIds(this.request);
    this.applicationHttpService.getMultipleApplications(appIds).subscribe(applications => {
      this.applications = applications;
      this.applicationsLength = this.applications.length;
    });
  }

  getRights(request: RequestsNew){
    this.rights = [];
    const applicationRights: Set<{applicationName: string, applicationCategory: string, applicationRight: string}>
      = new Set<{applicationName: string, applicationCategory: string, applicationRight: string}>();

    request.applications.forEach(application => {
      applicationRights.add({applicationName: application.application ,
        applicationCategory: application.applicationCategory, applicationRight: application.right });
    });
    this.rights = [...applicationRights.values()];
  }

  getApplicationNameFilterValues(){
    this.resetSelectOptions();
    const applicationNames: Set<{id: string, label: string}> = new Set<{id: string, label: string}>();
    let isFound: boolean;
    this.request?.applications?.map(application => {
      applicationNames.forEach(value => {
        isFound = application.application === value.id;
      });

      if (!isFound) {
        applicationNames.add({id: application.application, label: application.application});
      }
    });
    this.applicationNameOptions = [...applicationNames.values()];
  }

  listenToApplicationNameFilterValueChange(){

    this.applicationNameFilterValue.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(applicationName => {
        this.request.applications.sort((a, b) => a.applicationCategory.localeCompare(b.applicationCategory));
        const applicationCategories: Set<{id: string, label: string}> = new Set<{id: string, label: string}>();
        let isFound: boolean;
        let isSameApplication: boolean;
        this.request?.applications?.map(application => {
          isSameApplication = applicationName === application.application;
          if (isSameApplication) {
            applicationCategories.forEach(value => {
              isFound = application.applicationCategory === value.label;
            });
          }

          if (!isFound && isSameApplication) {
            applicationCategories.add({id: application.applicationCategory, label: application.applicationCategory});
          }
        });
        this.applicationCategoryOptions = [...applicationCategories.values()];
      });
 }

  listenToApplicationCategoryFilterValueChange(){
    this.applicationCategoryValue.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(categoryName => {
        this.request.applications.sort((a, b) => a.right.localeCompare(b.right));
        const applicationRights: Set<{id: string, label: string}> = new Set<{id: string, label: string}>();
        let isFound: boolean;
        let isSameCategory: boolean;
        this.request?.applications?.map(application => {
          isSameCategory = categoryName === application.applicationCategory;
          if (isSameCategory) {
            applicationRights.forEach(value => {
              isFound = application.right === value.label;
            });
          }

          if (!isFound && isSameCategory) {
            applicationRights.add({id: application.right, label: application.right});
          }
        });
        this.applicationRightOptions = [...applicationRights.values()];
      });
  }


  getLocationsForRequest(openRequest: RequestsNew) {
    return openRequest?.locations?.map(location => {
      return location;
    }).join('\n');
  }

  getEmployees() {
    const qnumbers: string[] = this.request.comments.map(comment => comment.createdBy);
    if (this.request.approver && !qnumbers.includes(this.request.approver)) {
      qnumbers.push(this.request.approver);
    }
    this.lookupHttpService.getAll(qnumbers).subscribe(bmwPersons => {
      bmwPersons.forEach(bmwPerson => {
        this.bmwPeopleMap.set(bmwPerson.uid, bmwPerson);
      });
    });
  }

  searchEmployee(qnumber: string): BmwPerson{
    return this.bmwPeopleMap.get(qnumber);
  }

  getTypeLabel(label: string): string {
    return this.i18nProvider.getDescription(label);
  }

  getStatusLabel(status: string) {
    return this.requestStatusUtilsService.getStatusNameByStatusEnum(status);
  }

  closeModal(){
    this.dialogRef.close();
  }

  resetSelectOptions() {
    this.applicationCategoryOptions = [];
    this.applicationRightOptions = [];
  }

  private sortComments() {
    this.request.comments.sort((a, b) => b.date.localeCompare(a.date));
  }

  setApprover() {
    return this.request.approverName + ': ' + this.request.approverDepartment;
  }

  getApplicationsIds(request: RequestsNew): number[] {
    const applicationsIds: Set<number> = new Set<number>();
      if (request?.applications) {
        request?.applications.forEach(appl => {
          applicationsIds.add(appl?.id);
        });
      }
    return [...applicationsIds.values()];
  }

  getTone(rightManager: RightsManagerHistoryItem): DsBadgeTone{
    if (rightManager.status.name === RequestStatuses.GRANTED){
      return 'positive';
    }
    if (rightManager.status.name === RequestStatuses.REMOVED){
      return 'critical';
    }
    else {
      return 'caution';
    }

  }
}
