import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegionAdminHttpService {
  private _http: AraHttpService;


  constructor(http: AraHttpService) {
    this._http = http;
  }

  public linkApplicationToRegion(applicationId: number, regionId: number): Observable<any> {
    const httpParams = new HttpParams().set('applicationId', applicationId.toString()).set('regionId', regionId.toString());
    return this._http.doPutParams(environment.region_admin_link_application_api_url, {}, httpParams);
  }

  public removeLinkApplicationToRegion(applicationId: number, regionId: number): Observable<any> {
    const httpParams = new HttpParams().set('applicationId', applicationId.toString()).set('regionId', regionId.toString());
    return this._http.doDelete(environment.region_admin_link_application_api_url, httpParams);
  }
}
