<ds-box class="confirmation-modal">
  <ds-box-header isDismissable="true" (dismissEvent)="closeModal(true)"></ds-box-header>
  <ds-box-content>
    <div class="col-12 d-flex flex-column align-items-center">
      <span class="modal-header-title-md">{{'' | i18n: data.action}}</span>
      <br>
      <span>{{'' | i18n : data.message}}</span>
    </div>
  </ds-box-content>

  <ds-box-footer>
    <div class="col-12 d-flex justify-content-center">
      <button ds-button class="s-btn col-4" (click)="closeModal(true)">Close</button>
    </div>
  </ds-box-footer>

</ds-box>
