export default class Right {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  businessCategoryIds: number[];
  businessRuleIds: number[];
  applicationId: number;
  applicationRightsCategoryId: number;
  disallowedByIds: number[];
  selected: boolean;
  masterId: number;
  active: boolean;
  applicationName: string;
  appCategoryDisplay: string;
  activeDisplay: string;
  // tslint:disable-next-line:variable-name
  req_end_date: string;
  // tslint:disable-next-line:variable-name
  req_start_date: string;
  // tslint:disable-next-line:variable-name
  rule_name: string;
  locations: string;
  selectedApplicationActive: boolean;
  categoryName: string;
  categoryId: number;
  linked: boolean;
  hide: boolean;
}
