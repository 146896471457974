import {Component, Inject, OnInit} from '@angular/core';
import {DialogRef} from '../../../service/modal/dialog-ref';
import {DIALOG_DATA} from '../../../service/modal/dialog-tokens';
import Modal from '../../../shared/model/modal';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  constructor(private dialogRef: DialogRef,  @Inject(DIALOG_DATA) public data: Modal) { }

  ngOnInit(): void {
  }

  closeModal(confirmed = false){
    this.dialogRef.close(confirmed);
  }
}
