import {Injectable} from '@angular/core';
import Request from 'src/app/shared/model/requests/request';
import {RequestStatuses} from 'src/app/shared/enums/request-statuses';
import {I18nProvider} from '../translations/i18n.service';
import RequestStatus from '../../shared/model/requests/request_status';

@Injectable({
  providedIn: 'root'
})
export class RequestStatusUtilsService {
  private  readonly IN_PROGRESS_BY_APPROVER = 'in_progress_approver';
  private  readonly IN_PROGRESS_BY_RIGHTS_MANAGER = 'in_progress_rights_manager';
  private  readonly NEW = 'new';
  private  readonly CHANGES_MADE = 'changes_made';
  private  readonly IN_REMOVAL = 'in_removal';
  private  readonly EXPIRING_SOON = 'expire_soon';
  private  readonly REMOVED = 'removed';
  private  readonly DELETED = 'deleted';
  private  readonly DEFAULT = 'default';
  private  readonly STYLE_NEW = 'new';
  private  readonly STYLE_CHANGES_MADE = 'changes_made';
  private  readonly GRANTED = 'GRANTED';
  private  readonly DECLINED = 'declined';
  private  readonly STYLE_IN_PROGRESS_BY = 'in_progress_by';
  private  readonly STYLE_IN_REMOVAL = 'in_removal';

  constructor(private i18nProvider: I18nProvider) {
  }

  getStatusName(openRequestReq: Request): string {
    switch (openRequestReq?.requestStatus?.name) {
      case RequestStatuses.DRAFT : {
        return openRequestReq?.change ? this.i18nProvider.getDescription(this.CHANGES_MADE) : this.i18nProvider.getDescription(this.NEW);
      }
      case RequestStatuses.PENDING : {
        return this.i18nProvider.getDescription(this.IN_PROGRESS_BY_APPROVER);
      }
      case RequestStatuses.PENDING_RIGHTS_MANAGER_APPROVAL : {
        return  this.i18nProvider.getDescription(this.IN_PROGRESS_BY_RIGHTS_MANAGER);
      }
      case RequestStatuses.DELETED : {
        return this.i18nProvider.getDescription(this.DELETED);
      }
      case RequestStatuses.CHANGES_MADE : {
        return this.i18nProvider.getDescription(this.CHANGES_MADE);
      }
      case RequestStatuses.EXPIRING_SOON : {
        return this.i18nProvider.getDescription(this.EXPIRING_SOON);
      }
      case RequestStatuses.REMOVED : {
        return this.i18nProvider.getDescription(this.REMOVED);
      }
      default:  {
        return this.i18nProvider.getDescription(openRequestReq.requestStatus.name.toLowerCase());
      }
    }
  }

  getStatusStyle(openRequestReq: Request) {
    switch (openRequestReq?.requestStatus?.name) {
      case RequestStatuses.DRAFT : {
        return openRequestReq?.change ? this.STYLE_CHANGES_MADE : this.STYLE_NEW;
      }
      case RequestStatuses.GRANTED : {
        return openRequestReq?.change ? this.GRANTED : this.GRANTED;
      }
      case RequestStatuses.DECLINED : {
        return openRequestReq?.change ? this.DECLINED : this.DECLINED;
      }
      case RequestStatuses.DELETED : {
       return this.STYLE_IN_PROGRESS_BY;
      }
      case RequestStatuses.IN_REMOVAL : {
        return this.STYLE_IN_REMOVAL;
      }
      case RequestStatuses.EXPIRING_SOON : {
        return this.STYLE_IN_REMOVAL;
      }
      case RequestStatuses.CHANGES_MADE : {
        return this.CHANGES_MADE;
      }
      case RequestStatuses.REMOVED : {
        return this.REMOVED;
      }
      default:  {
        return openRequestReq?.delete ? this.STYLE_IN_REMOVAL : this.STYLE_IN_PROGRESS_BY;
      }
    }
  }

  getStatusStyleByStatusName(status: string) {
    switch (status) {
      case RequestStatuses.DRAFT.toString() : {
        return this.STYLE_NEW;
      }
      case RequestStatuses.GRANTED.toString() : {
        return this.GRANTED;
      }
      case RequestStatuses.DECLINED.toString() : {
        return this.DECLINED;
      }
      case RequestStatuses.DELETED.toString() : {
        return this.STYLE_IN_PROGRESS_BY;
      }
      case RequestStatuses.PENDING_RIGHTS_MANAGER_APPROVAL.toString() : {
        return this.STYLE_IN_PROGRESS_BY;
      }
      case RequestStatuses.PENDING.toString() : {
        return this.STYLE_IN_PROGRESS_BY;
      }
      case RequestStatuses.IN_REMOVAL.toString() : {
        return this.STYLE_IN_REMOVAL;
      }
      case RequestStatuses.EXPIRING_SOON.toString() : {
        return this.STYLE_IN_REMOVAL;
      }
      case RequestStatuses.CHANGES_MADE.toString() : {
        return this.CHANGES_MADE;
      }
      case RequestStatuses.REMOVED.toString() : {
        return this.REMOVED;
      }
      default:  {
        return this.STYLE_IN_PROGRESS_BY;
      }
    }
  }

  getStatusNameByStatusEnum(status: string): string {
    switch (status) {
      case RequestStatuses.DRAFT.toString() : {
        return this.i18nProvider.getDescription(this.NEW);
      }
      case RequestStatuses.PENDING.toString() : {
        return this.i18nProvider.getDescription(this.IN_PROGRESS_BY_APPROVER);
      }
      case RequestStatuses.PENDING_RIGHTS_MANAGER_APPROVAL.toString() : {
        return  this.i18nProvider.getDescription(this.IN_PROGRESS_BY_RIGHTS_MANAGER);
      }
      case RequestStatuses.DELETED.toString() : {
        return this.i18nProvider.getDescription(this.DELETED);
      }
      case RequestStatuses.CHANGES_MADE.toString() : {
        return this.i18nProvider.getDescription(this.CHANGES_MADE);
      }
      case RequestStatuses.EXPIRING_SOON.toString() : {
        return this.i18nProvider.getDescription(this.EXPIRING_SOON);
      }
      case RequestStatuses.REMOVED.toString() : {
        return this.i18nProvider.getDescription(this.REMOVED);
      }
      default:  {
        return this.i18nProvider.getDescription(status.toLowerCase());
      }
    }
  }

  getUpdateStatus(openRequestReq: Request): RequestStatus {
    const status: RequestStatus = new RequestStatus();
    if (openRequestReq?.requestStatus?.name === RequestStatuses.PENDING_RIGHTS_MANAGER_APPROVAL) {
        status.name = RequestStatuses.GRANTED;
        return status;
    }
    else
    if (openRequestReq?.requestStatus?.name === RequestStatuses.CHANGES_MADE) {
      status.name = RequestStatuses.GRANTED;
      return status;
    }
    else
      if (openRequestReq?.requestStatus?.name === RequestStatuses.IN_REMOVAL) {
        status.name = RequestStatuses.REMOVED;
        return status;
    } else {
        status.name = openRequestReq.requestStatus.name;
        return status;
    }
  }
}
