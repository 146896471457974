import {Pipe, PipeTransform} from '@angular/core';
import {Router} from '@angular/router';
import {I18nProvider} from './i18n.service';

@Pipe({
  name: 'i18n'
})
export class I18nPipe implements PipeTransform {

  language: string;

  constructor(private i18nProvider: I18nProvider, private router: Router) {
    this.language = this.i18nProvider.language;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.i18nProvider.langUpdated.subscribe(
      (lang) => {
        this.language = lang;
        this.router.navigated = false;
        this.router.navigateByUrl(this.router.url);
      }
    );
  }

  transform(value: any, key: string): any {
    const langItems = this.i18nProvider.languageData.filter(langItem => {
      return (langItem.item === key.trim() && langItem.lang === this.language.trim());
    });
    if (langItems[0]) {
      return langItems[0].text;
    }
    return '? i18n : ' + key + ' ?';
  }
}
