<ng-http-loader></ng-http-loader>
<app-header></app-header>

<div class="d-flex">
  <div class="ds-nav-bar-wrapper position-sticky">
    <app-sidebar ></app-sidebar>
    </div>

  <div class="wrapper d-flex flex-column align-items-stretch ">
     <div class=" container-fluid  py-4x">
       <ds-box-content class="layout">
         <router-outlet  (activate)="activate($event)"></router-outlet>
       </ds-box-content>
     </div>
     <app-footer ></app-footer>

  </div>

</div>
