import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {
  CellClickedEvent,
  ColDef, FirstDataRenderedEvent, GridApi,
  GridOptions, GridReadyEvent, RowDoubleClickedEvent, RowValueChangedEvent,
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {SelectListOption} from '@bmw-ds/components';
import UserDisplay from '../../../shared/model/user/user-display';
import {UserHttpService} from '../../../service/http/user-http.service';
import Translation from '../../../shared/model/translation';
import {AllowedLoginsService} from '../../../service/http/allowed-logins.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TranslationsComponent implements OnInit {
  translations: Array<any> = [];
  columns = this.getColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  editType: 'fullRow' = 'fullRow';
  private gridApi!: GridApi;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  defaultColDef: ColDef = {
    flex: 1,
    editable: true,
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100},
    { id: 200},
    { id: 500},
    { id: 1000},
  ];

  constructor(private httpService: AllowedLoginsService, private i18nProvider: I18nProvider) { }

  ngOnInit(): void {
    this.paginationSize = this.setPaginationSize();
    this.translations = this.i18nProvider.languageData;
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  rowEvents: GridOptions = {
    onRowValueChanged: (event: RowValueChangedEvent) => this.updateTranslation(event.data),
  };

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  getColumns(){
    return [
        {field: 'lang', headerName: 'Language', width: 10, filter: true, sortable: true, editable: false },
        {field: 'item', headerName: 'Key', width: 100, filter: true, sortable: true, editable: false },
        {field: 'text', headerName: 'Label Text', width: 200, filter: true, sortable: true},
      ];
  }


  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_translations_master_admin_pagination')) {
      return localStorage.getItem('ara_translations_master_admin_pagination');
    }
    return 200;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_translations_master_admin_pagination', size);
    this.paginationSize = size;
  }

  updateTranslation(translationObject: any) {
    let translation: Translation;
    translation = translationObject;
    this.i18nProvider.updateTranslation(translation);
  }
}
