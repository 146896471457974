import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import GeneratedRequest from 'src/app/shared/model/requests/generated-request.interface';
import Request from 'src/app/shared/model/requests/request';
import Right from 'src/app/shared/model/rights/right';
import { ApplicationHttpService } from 'src/app/service/http/application-http.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  constructor(private applicationHttpService: ApplicationHttpService) { }

  getRequestsWithApplication(requests: GeneratedRequest[]): Observable<GeneratedRequest[]> {
    const appIds: number[] = this.getApplicationsIds(requests);
    return new Observable <GeneratedRequest[]>(subscriber => {
        this.applicationHttpService.getMultipleApplications(appIds).subscribe(applications => {
          requests.map(request => {
            if (request?.businessRuleDTO?.rights) {
              const requestRights: Right[] = request?.businessRuleDTO?.rights;
              const requestApplicationsIds: Set<number> = new Set<number>();
              requestRights.map( right => {
              requestApplicationsIds.add(right.applicationId);
            });
              request.applications = applications.filter(application => {
                return [...requestApplicationsIds.values()].includes(application.id);
              });
            }
            else
              if (request?.rightDTO?.applicationId) {
                request.applications = applications.filter(application => {
                  return application.id === request.rightDTO.applicationId;
                });
              }
          });
          subscriber.next(requests);
        });
    });
  }

  getApplicationsIds(requests: Request[]): number[] {
    const applicationsIds: Set<number> = new Set<number>();
    requests.map(request => {
      if (request?.businessRuleDTO?.rights) {
        request?.businessRuleDTO?.rights.forEach(right => {
          applicationsIds.add(right?.applicationId);
        });
      }
      if (request?.rightDTO) {
        applicationsIds.add(request?.rightDTO?.applicationId);
      }
    });
    return [...applicationsIds.values()];
  }


}
