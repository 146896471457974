import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import Right from 'src/app/shared/model/rights/right';

@Injectable({
  providedIn: 'root'
})
export class RightHttpService {
  private http: AraHttpService;


  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getRight(rightId: number): Observable<Right> {
    return this.http.doGet(environment.right_api_url + `${rightId}`);
  }

  public getRights(): Observable<Right[]> {
    return this.http.doGet(environment.right_api_url);
  }

  public updateRight(right: Right): Observable<Right> {
    return this.http.doPut(environment.right_api_url, right);
  }

  public createRight(right: Right): Observable<Right> {
    return this.http.doPost(environment.right_api_url, right);
  }

  public deleteRight(rightId: number): Observable<string>{
    return this.http.doDelete(environment.right_api_url  + rightId + '/');
  }

  public updateRights(rights: Right[]): Observable<void> {
    return this.http.doPut(environment.right_api_url + 'update', rights);
  }

  public updateRightState(right: Right): Observable<void> {
    return this.http.doPut(environment.right_api_url + 'update-right-state', right);
  }
}
