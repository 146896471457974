import Right from '../rights/right';
import RightMinified from '../rights/right-min';

export default class BusinessRule {
  id: number;
  name: string;
  businessCategoryId: number;
  userIds: number[];
  rights: Right[] = [];
  selected = false;
  canDelete = false;
  delete = false;
  startDate: string;
  endDate: string;
  masterId: number;
  active = false;
}
