import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AllowedLoginsService} from '../../../service/http/allowed-logins.service';
import Department from '../../../shared/model/department';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
import {I18nProvider} from '../../../service/translations/i18n.service';
import RequestsNew from '../../../shared/model/requests/requests-new';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DepartmentsComponent implements OnInit {
  departments: Department[];
  selectedDepartment: Department;
  newDepartmentModalOpen = false;
  updateModalOpen = false;
  departmentCode: string;
  columns = this.getColumns();
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<Department>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedDepartment = event.data;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedDepartment = event.data;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedDepartment = null;
    }
  };
  constructor(private allowedLoginsService: AllowedLoginsService, private i18nProvider: I18nProvider, private dialog: DialogService) { }

  ngOnInit(): void {
    this.getAllDepartments();
  }

  getAllDepartments(){
    this.allowedLoginsService.getAllDepartments().subscribe(departments => {
      this.departments = departments;
      this.departments.forEach(department => {
        department.status = this.i18nProvider.getDescription('inactive');
        if (!departments.active){
          department.status = this.i18nProvider.getDescription('active');
        }
      });
    });
  }

  openSaveModal() {
    this.newDepartmentModalOpen = true;
  }

  closeSaveModal() {
    this.newDepartmentModalOpen = false;
    this.updateModalOpen = false;
  }

  getStatus(active: boolean): string{
    return active ? this.i18nProvider.getDescription('active') : this.i18nProvider.getDescription('inactive');
  }

  saveDepartment() {
      const department = new Department();
      department.departmentCode = this.departmentCode.trim();
      department.active = true;
      this.allowedLoginsService.saveDepartment(department).subscribe(() => {
        this.departments.filter(dep => dep.id !== department.id);
        this.departments.push(department);
        this.gridApi.setRowData(this.departments);
        this.closeSaveModal();
      });

  }

  deleteDepartment(id: number){
    const data: Modal = {
      action : 'delete',
      message: 'delete_department'
    };
    this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
      if (results) {
        this.allowedLoginsService.deleteDepartment(id).subscribe(() => {
          this.departments.filter(dep => dep.id !== id);
          this.gridApi.setRowData(this.departments);
          this.closeSaveModal();
        });
      }
      });
  }
  updateStatus(active: boolean) {
    this.selectedDepartment.active = active;
    this.selectedDepartment.status = this.i18nProvider.getDescription('active');
    if (!this.selectedDepartment.active) {
      this.selectedDepartment.status = this.i18nProvider.getDescription('inactive');
    }
    this.updateDepartment(this.selectedDepartment);
  }

  updateDepartment(department: Department) {
    department.departmentCode = department.departmentCode.trim();
    this.allowedLoginsService.updateDepartment(department).subscribe(() => {
      this.departments.filter(dep => dep.id !== department.id);
      this.departments.push(department);
      this.gridApi.setRowData(this.departments);
      this.closeSaveModal();
    });
  }
  getColumns(){
    return [
      {field: 'departmentCode', headerName: this.i18nProvider.getDescription('department_code'), filter: true, sortable: true, width: 1155},
      {field: 'status', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true,
        cellClass: params => {
          return params.data.active ? 'active' : 'inactive';
        }
      }];
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<Department>) {
    this.gridApi = params.api;
  }
}
