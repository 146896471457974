import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {map, Observable, of} from 'rxjs';
import Region from 'src/app/shared/model/requests/region';
import Location from 'src/app/shared/model/requests/location';
import LocationMinified from '../../shared/model/requests/location-min';

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService {
  private http: AraHttpService;
  locationsObservable: Observable<LocationMinified[]>;


  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getLocations(activeOnly: boolean): Observable<Location[]> {
    const url = activeOnly ? environment.location_api_url + 'active/' : environment.location_api_url;
    return this.http.doGet(url);
  }

  public getMinifiedActiveLocations(): Observable<LocationMinified[]> {
    if (!this.locationsObservable) {
      this.locationsObservable = this.http.doGet(environment.location_api_url + 'minified/active/').pipe(
        map((requests: LocationMinified[]) => {
          this.locationsObservable = of(requests);
          return requests;
        }));
    }
    return this.locationsObservable;
  }

  public getLocationsForRegion(region: Region, activeOnly: boolean): Observable<Location[]> {
    return this.getLocationsForRegionById(region.id, activeOnly);
  }

  public getLocationsForRegionById(regionId: number, activeOnly: boolean): Observable<Location[]> {
    const url = activeOnly ? environment.location_api_url + 'region/active/' + regionId :
      environment.location_api_url + 'region/' + regionId;
    return this.http.doGet(url);
  }

  public updateLocation(location: Location): Observable<Location> {
    return this.http.doPut(environment.location_api_url, location);
  }

  public createLocation(location: Location): Observable<Location> {
    return this.http.doPost(environment.location_api_url, location);
  }

  public deleteLocation(locationId: number): Observable<string> {
    return this.http.doDelete(environment.location_api_url + locationId + '/');
  }
}
