import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs';
import Translation from '../../shared/model/translation';

@Injectable()
export class I18nProvider {

  langUpdated: EventEmitter<string> = new EventEmitter();
  language: string;
  languageData: Array<any> = [];

  constructor(private http: HttpClient) {
    this.language = localStorage.getItem('selectedLanguage');
    if (!this.language || this.language.trim().length === 0) {
      this.setLanguage('de');
      localStorage.setItem('selectedLanguage', 'de');
    } else {
      this.setLanguage(this.language.toLowerCase());
    }
  }

  setLanguage(language: string) {
    this.language = language.toLowerCase();
    this.langUpdated.emit(this.language);
    localStorage.setItem('selectedLanguage', language);
  }

  load() {
    return new Promise((resolve) => {
      this.http.get(environment.i18n_api_url)
        .pipe(
          map((res: any) => {
            return res;
          }))
        .subscribe(value => {
          this.languageData = value;
          resolve(true);
        });
    });
  }
  getDescription(key: string): any {
    const langItems = this.languageData.find(langItem => {
      return (langItem.item === key.trim() && langItem.lang === this.language.trim());
    });
    if (langItems) {
      return langItems.text;
    }
    return '? i18n : ' + key + ' ?';
  }


  updateTranslation(translation: Translation) {
    return this.http.put(environment.i18n_api_url + 'update-translation', translation)
      .subscribe(
        error => console.error('HTTP error:', error)
      );
  }

}

export function i18nProviderFactory(provider: I18nProvider) {
  return () => provider.load();
}
