<div class="container-costom p-0">
  <div class = "mt-5">
    <ds-tabs>
      <ds-tabs-label for="rights">{{'' | i18n: 'new_business_rule'}}</ds-tabs-label>
      <ds-tabs-content id="rights">
        <form class="col-12 d-flex  flex-column p-0"  ds-form [formGroup]="newRequestForm">
          <div class="col-12 d-flex p-0" style="max-height: 180px">
            <div class="col-3">
              <ds-form-field>
                <label ds-label for="rightRegion">Region:</label>
                <ds-select  formControlName="regionId" id="rightRegion" required="false" [options]="regionsOptions"></ds-select>
                <label class="required_identifier"  *ngIf="newRequestForm.controls['regionId'].touched && newRequestForm.controls['regionId'].hasError('required')">{{'' | i18n: 'select_region_msg'}}</label>
              </ds-form-field>
              <ds-form-field >
                <label ds-label for="rightAppS">{{'' | i18n: 'business_category'}}:</label>
                <ds-select id="rightAppS" required="false"  formControlName="businessCategoryId"
                           [options]="businessCategoryOptions"></ds-select>
                <label class="required_identifier"  *ngIf="newRequestForm.controls['businessCategoryId'].touched && newRequestForm.controls['businessCategoryId'].hasError('required')">{{'' | i18n: 'select_business_cat_msg'}}</label>
              </ds-form-field>
            </div>
            <div class="col-3">
              <div class = "row">
                <label ds-label for="rightLocation" >{{'' | i18n: 'locations'}}:</label>
                <ds-select id="rightLocation"
                           formControlName="locations"
                           [options]="locationsOption"
                           (valueChange)="getSelectedLocations($event)">

                </ds-select>
                <label class="required_identifier"  *ngIf="newRequestForm.controls['locations'].touched && newRequestForm.controls['locations'].hasError('required')">{{'' | i18n: 'select_one_location_msg'}}</label>
              </div>
            </div>
            <div class="col-4">
              <div class="col-12 d-flex flex-row">
                <ds-form-field>
                  <label ds-label >{{'' | i18n: 'start_date'}} & {{'' | i18n: 'end_date'}} </label>
                  <ds-datepicker [config]="dateConfig">
                    <input ds-date  formControlName="startDate" required/>
                    <input ds-date  formControlName="endDate" required/>
                  </ds-datepicker>
                  <label class="required_identifier"  *ngIf="newRequestForm.controls['startDate'].touched && newRequestForm.controls['startDate'].hasError('required')">{{'' | i18n: 'start_date_msg'}}</label>
                  <label class="required_identifier"  *ngIf="!isDateValid3Days">Bitte Zeitraum überprüfen <3 Tage</label>
                  <label class="required_identifier"  *ngIf="!isDateValid90Days">Startdatum darf nicht mehr als 90 Tage in der Zukunft liegen</label>
                  <label class="required_identifier"  *ngIf="isDateValidEXT">Enddatum darf nicht mehr als 1 Jahr in der Zukunft liegen</label>
                  <label class="required_identifier"  *ngIf="isDateValidEMP">Enddatum darf nicht mehr als 3 Jahre in der Zukunft liegen</label>
                </ds-form-field>
              </div>
              <div class="col-12 p-0 d-flex flex-row">
                <ds-form-field class="col-12">
                  <label ds-label for="rightComment">{{'' | i18n: 'write_a_comment'}}:</label>
                  <textarea ds-input id="rightComment" required="false"  formControlName="comment"></textarea>
                  <label class="required_identifier"  *ngIf="newRequestForm.controls['comment'].touched && newRequestForm.controls['comment'].hasError('required')">{{'' | i18n: 'write_a_comment'}}</label>
                </ds-form-field>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-start flex-column">
              <button class="green_b_standard mt-0"  [disabled]="!areDatesValid()" (click)="addMoreLocation()">{{'' | i18n: 'save'}}</button>
              <button class="cancel_b_standard" (click)="onHideComponent()">{{'' | i18n: 'cancel'}}</button>
            </div>
          </div>
          <div class="col-12 p-0">
            <label class="required_identifier"  *ngIf="newRequestForm.controls['rules'].touched && newRequestForm.controls['rules'].hasError('required')">{{'' | i18n: 'please_select_business_rule'}}</label>
            <ds-tree [isMultiselect]="true" (valueChange)="valueChange($event)" *ngIf="businessCategoryRules">
              <ds-tree-item [id]="rule.id.toString()" [label]="rule.name" *ngFor="let rule of filterRules(businessCategoryRules)">
                <ds-tree-item class="child-checkbox"  [id]="rule.id.toString()"  [label]="application.name" *ngFor="let application of rule.applications">
                  <ds-tree-item [id]="rule.id.toString()" [label]="category.name" *ngFor="let category of getApplicationCategories(application.applicationRightsCategories, rule)" >
                    <ds-tree-item [id]="rule.id.toString()" [label]="right.name" *ngFor="let right of getApplicationCategoriesRights(category.id, rule.rights)">
                    </ds-tree-item>
                  </ds-tree-item>
                </ds-tree-item>
              </ds-tree-item>
            </ds-tree>
          </div>
        </form>
      </ds-tabs-content>
    </ds-tabs>
  </div>


</div>
